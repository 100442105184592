import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AsyncSelect from "react-select/async";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX, faPlus } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../Modal';
import client from '../../../lib/feathers';

// SORTABLE:
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  TouchSensor,
  MouseSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableRow } from '../SortableItem';
export default function ItineraryListingList({ currentItinerary }) {
  const { lid, iid } = useParams();

  const role = useSelector((state) => state.auth.user.role);
  
  const [listings, setListings] = useState([]);
  const [itineraryListings, setItineraryListings] = useState([]);

  const [selectedListing, setSelectedListing] = useState();

  const [listingToRemove, setListingToRemove] = useState();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // This needs to re-wired to connect to the database properly.
  useEffect(() => {
    if (role && currentItinerary) {
      setItineraryListings(currentItinerary.listingListListings)
      const loadListings = async () => {
        const service = client.service(`client/listing`);
        const query = {
          $limit: 25,
          $sort: {
            name: 1,
          },
        };
        const response = await service.find({ query });
        const options = response.data.map((listing) => (
          {
            ...listing,
            value: listing.id,
            label: `${listing.name}`
          }
        ))
        setListings(options)
      }
      loadListings();
      // loadItineraryListings();
    }
  }, [currentItinerary, role])

  const loadListings = async (keywords) => {
      const service = client.service(`client/listing`);
      const query = {
        $limit: 25,
        $sort: {
          name: 1,
        },
        name: { $iLike: `%${keywords}%` },
      };
      const response = await service.find({ query });
      const options = response.data.map((listing) => (
        {
          ...listing,
          value: listing.id,
          label: `${listing.name}`
        }
      ))
      return options
  }

  const handleAddItineraryListing = async (e) => {
    e.preventDefault();

    if(selectedListing) {
      const service = client.service(`${role}/listing-list-listing`);
      const data = {
        listingId: selectedListing.value,
        listingListId: iid,
        order: itineraryListings.length - 1
      }
      const response = await service.create(data);
      setItineraryListings([
        ...itineraryListings, 
        { 
          ...response,
          listing: { 
            name: selectedListing.name, 
            id: selectedListing.id,
          },

          } 
      ]);
      
      setSelectedListing(null);
    }
  }

  // DELETE LISTING HANDLERS
  const handleDeleteItineraryListing = async () => {
    if (listingToRemove) {
      const service = client.service(`${role}/listing-list-listing`);
      await service.remove(listingToRemove.id);
      
      const updatedItineraryListings = [...itineraryListings].filter((listing) => listing.id !== listingToRemove.id);
      setItineraryListings(updatedItineraryListings);
      setShowDeleteModal(false);
      setListingToRemove(null);
    }
  }
  
  const handleOpenDeleteModal = (listingToRemove) => {
    setShowDeleteModal(true);
    setListingToRemove(listingToRemove);
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setListingToRemove(null);
  }
  
  // SORTABLE HANDLERS
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 2,
    },
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 100,
      tolerance: 5,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const updateOrder = (listingList) => {
    listingList.forEach(async (item, index) => {
      try {
        const service = client.service(`${role}/listing-list-listing`);
        await service.patch(item.id, { order: index });
      } catch (e) {
        console.error("error", e);
      }
    });
  };

  const handleOnDragEnd = async (event) => {
    if(!event.over){
      return;
    }
    const { active, over } = event;
    const startingContainer = active.data.current.sortable.containerId;
    const endingContainer = over.data.current.sortable.containerId;
    if (active.id === over.id) {
      return;
    }
    else {
      const ilIds = itineraryListings.map((a) => a.id);
      const activeIndex = ilIds.indexOf(active.id);
      const overIndex = ilIds.indexOf(over.id);
      const newArray = arrayMove(itineraryListings, activeIndex, overIndex);
      if (startingContainer !== endingContainer) {
        const [changedItem] = newArray.splice(overIndex, 1);
        changedItem.type = endingContainer;
        newArray.splice(overIndex, 0, changedItem);
      }
      setItineraryListings(newArray);
      updateOrder(newArray);
    }
  };

  return (
    <>
      {showDeleteModal
        && (
          <Modal>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                Confirm Removal
              </h3>
              <div className="mt-2">
                <p className="text-base text-gray-500">
                  Are you sure you want to remove {listingToRemove ? `${listingToRemove.listing?.name}` : ' this listing'}?
                </p>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button type="button" onClick={handleDeleteItineraryListing} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-icon text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 hover:bg-red-700 sm:col-start-2 sm:text-sm">Confirm</button>
              <button type="button" onClick={() => handleCloseDeleteModal()} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:col-start-1 sm:text-sm">Cancel</button>
            </div>
          </Modal>
        )}
      <DndContext
        onDragEnd={handleOnDragEnd}
        collisionDetection={closestCenter}
        sensors={sensors}
      >
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg my-5">
        {/* GRID */}
          <div className="md:grid md:grid-cols-3 md:gap-6">
            {/* COL 1 */}
            <div className="md:col-span-1">        
              <h3 className="text-lg font-medium leading-6 text-gray-900 inline-block align-middle mr-2">
                Itinerary Builder
              </h3>
              <p className="mt-1 text-sm text-gray-500">Add stops for {currentItinerary ? ` ${currentItinerary.name}.` : ' this itinerary.'}<br></br>
              Drag and drop to reorder.<br></br>
              Click on a location's name to view more details.</p>
            </div>
            {/* COL 2 */}
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="col-span-6">
                <label htmlFor="listingId" className="block text-sm font-medium text-gray-700">
                  Search locations to add to this itinerary
                </label>
                {listings && (
                  <>
                    <form onSubmit={handleAddItineraryListing} className="flex w-full items-center">
                      <AsyncSelect
                        cacheOptions
                        defaultOptions={listings}
                        loadOptions={loadListings}
                        setValue
                        onChange={setSelectedListing}
                        className="w-full whitespace-pre-wrap"
                      />
                      <button type="submit" className="items-center px-4 py-2 my-1 mx-2 border border-primary rounded-md shadow-sm text-sm font-medium text-primary bg-transparent hover:bg-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-primary">
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    </form>
                  </>
                )}
              </div>
            </div>
            <div className="md:px-5 md:col-span-3">
              <SortableContext
              id="itineraryListings"
              strategy={rectSortingStrategy}
              items={itineraryListings}
              >
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-3 pr-1 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                      Order
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Name
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                      <span className="sr-only">Delete</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                {itineraryListings && (
                  itineraryListings.map((item, index) => (
                    <SortableRow key={item.id + index} id={item.id} index={index}>
                      <td className="whitespace-nowrap divide-y divide-gray-200">
                        <span className="whitespace-nowrap px-3 pl-4 pr-1 text-sm text-gray-500 lg:table-cell">
                          {index + 1}
                        </span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                        <span>
                          <a href ={`${process.env.REACT_APP_CLIENT_URL}/listing/${item.listing.id}`} target="_blank">{item.listing.name || 'Listing Name'}</a>
                        </span>
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                        <button className="items-center px-4 py-2 my-1 mx-2 border border-primary rounded-md shadow-sm text-sm font-medium text-primary bg-transparent hover:bg-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-primary" onClick={() => handleOpenDeleteModal(item)}>
                          <FontAwesomeIcon icon={faX} />
                        </button>
                      </td>
                    </SortableRow>
                  ))
                )}
                {itineraryListings.length === 0 && (
                  <tr>
                    <td className="p-3 text-sm text-gray-400">
                      No listings added.
                    </td>
                  </tr>
                )}
                </tbody>
              </table>
            </SortableContext>
          </div>
        </div>
      </div>
    </DndContext>
  </>
  )
}
