import { useEffect, useState, Fragment } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setUser } from "../lib/authSlice";

import client from '../lib/feathers';
import ListingForm from "./industry/listings/[lid]/edit";
import ListingDetail from "./industry/listings/listingDetails";
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon, UserCircleIcon } from '@heroicons/react/24/outline'
import IndustryListings from "./industry/listings";
// import FavIcon from "./../../../public/assets/favicon.png";

function Industry() {
  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigation = [
    { name: 'Listings', href: '/industry/', current: true },
    // { name: 'Events', href: '#', current: false },
    // { name: 'Promotions', href: '/industry/promotions', current: false },
    // { name: 'Calendar', href: '#', current: false },
    // { name: 'Team', href: '#', current: false },
    // { name: 'Reports', href: '#', current: false },
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  async function onLogout() {
    try {
      await client.logout();
      dispatch(setUser(undefined));
      navigate('/');
    } catch (e) {
      console.log('cant logout', e);
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const auth = await client.authenticate();
        if (auth.user.role !== 'industry') {
          navigate(`/${auth.user.role}/`);
        } else {
          dispatch(setUser(auth.user));
        }
      } catch (e) {
        navigate('/');
      }
    };
    checkAuth();
  }, []);

  return (
    <div className="App">
      <div className="min-h-full">
        <Disclosure as="nav" className="bg-icon">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="h-8 w-8 bg-white rounded-full"
                        // ask sim about this
                        src={`${process.env.PUBLIC_URL}/assets/favicon.png`}
                        alt="Your Company"
                      />
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? 'bg-white text-icon'
                                : 'text-white hover:bg-white hover:bg-opacity-75',
                              'rounded-md px-3 py-2 text-sm font-medium'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      <div className="ml-3">
                        <div className="text-base font-medium text-white">{user?.firstName} {user?.lastName}&nbsp;</div>
                      </div>
                      {/* <button
                        type="button"
                        className="rounded-full bg-white p-1 text-icon hover:bg-white hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-icon"
                      >
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button> */}

                      {/* user dropdown */}
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="flex max-w-xs items-center rounded-full bg-icon hover:bg-white hover:bg-opacity-75 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-icon">
                            <span className="sr-only">Open user menu</span>
                            <UserCircleIcon className="h-8 w-8 rounded-full bg-white text-icon" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                              <h3 onClick={() => onLogout()} className="block rounded-md px-3 py-2 text-base font-medium bg-icon text-white hover:bg-opacity-75">Sign Out</h3>
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>

                  {/* Mobile menu button */}
                  <div className="-mr-2 flex md:hidden">
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-icon hover:bg-icon hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-icon">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current
                          ? 'bg-icon text-white outline outline-white'
                          : 'text-white hover:bg-white hover:bg-opacity-75',
                        'block rounded-md px-3 py-2 text-base font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="border-t border-icon pb-3 pt-4">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <UserCircleIcon className="h-8 w-8 rounded-full bg-white text-icon" />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-white">{user?.firstName} {user?.lastName}</div>
                      <div className="text-sm font-medium text-white">{user?.email}</div>
                    </div>
                    <button
                      type="button"
                      className="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-icon hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-icon"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-3 space-y-1 px-2">
                    <Disclosure.Button
                      onClick={() => onLogout()}
                      className="block rounded-md px-3 py-2 text-base font-medium text-white outline outline-white"
                    >
                      Sign Out
                    </Disclosure.Button>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-5">
          <Routes>
            <Route path="/" element={<IndustryListings />} />
            <Route path="/listings/:lid/edit" element={<ListingForm />} />
            <Route path="/listings/:lid/*" element={<ListingDetail />} />
          </Routes>
        </main>
      </div >
    </div >
  )
}

export default Industry;