import React, { useEffect, useState } from 'react';
import HourSelect from './HoursSelect';

export default function HoursControl(props) {
  const { onChange, value = {} } = props;

  let opens = value.opens;
  let closes = value.closes;

  const onOpensChanged = (e) => {
    opens = e.target.value;
    hourHolder();
  };
  const onClosesChanged = (e) => {
    closes = e.target.value;
    hourHolder();
  };

  const hourHolder = () => {
    const hours = {
      opens: opens,
      closes: closes
    };
    onChange(hours);
  }

  return (
    <div className="lg:flex gap-5 pb-4">
      <div className="">
        <label className="block text-sm font-medium text-gray-700">Open</label>
        <HourSelect value={opens} onChange={onOpensChanged} />
      </div>

      <div className="">
        <label className="block text-sm font-medium text-gray-700">Close</label>
        <HourSelect value={closes} onChange={onClosesChanged} />
      </div>
      {/* <button type='button' onClick={onHandleClick} >(-)</button> */}
    </div>
  );
}

