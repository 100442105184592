import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup";
import Select from 'react-select';
import client from '../../../lib/feathers';

const schema = yup.object({
  mergeLibation: yup.object().required("Please select a wine to merge into"),
}).required();

export default function MergeLibationModal(props) {
  const { onCancelClick, libation } = props;
  const { lid } = useParams();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);

  const [libations, setLibations] = useState([]);
  const [isSearchAll, setIsSearchAll] = useState(false);
  const [selectedLibation, setSelectedLibation] = useState();

  const [showWarning, setShowWarning] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    const loadLibations = async () => {
      const service = client.service(`${user.role}/libation`);
      const query = {
        ...(!isSearchAll && { listingId: lid }),
        id: { $nin: [libation.id] },
        $limit: 999,
      };
      const response = await service.find({ query });
      const options = response.data.map((aLibation) => (
        {
          ...aLibation,
          label: `${aLibation.name} \n${aLibation.vintage} ${aLibation.varietal?.name || ''} ${isSearchAll ? `\n[${aLibation.listing?.name || 'User-Submitted'}]` : ''}`,
          value: aLibation.id
        }
      ));
      setLibations(options);
    };

    loadLibations();
  }, [isSearchAll])

  const navigateToWine = (mergeLibation) => {
    setShowWarning(false);
    onCancelClick();
    navigate(`/${user.role}/listings/${lid}/wines/${mergeLibation.id}/edit`);
  }

  const handleMergeLibation = async (data) => {
    const { mergeLibation } = data;

    const service = await client.service('admin/libation-merge');
    await service.update(libation.id, { id: mergeLibation.id });

    navigateToWine(mergeLibation);
  };

  const handleSelectedLibation = async (lib) => {
    const service = client.service(`admin/libation`);
    const response = await service.get(lib.id);

    setSelectedLibation(response);
    setShowWarning(false);
  };

  // ! Commented out. Not sure if we want this later
  // const Checkbox = ({ children, ...props }) => (
  //   <label htmlFor="searchAll" className="flex items-center text-sm font-medium text-gray-700">
  //     <input id="searchAll" name="searchAll" type="checkbox" {...props} />
  //     {children}
  //   </label>
  // );

  return (
    <>
      <div className="mt-3 text-center sm:mt-5">
        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
          Merge Wine
        </h3>
        <div className="mt-2">
          <form onSubmit={handleSubmit(handleMergeLibation)} className="w-full text-left">
            <div className="mt-5 md:mt-0 md:col-span-2 text-left">
              <div className="col-span-6">
                <label htmlFor="selectedLibation" className="block text-sm font-medium text-gray-700">
                  Select a wine to merge into
                </label>
                {libations && (
                  <>
                    <Controller
                      id="mergeLibation"
                      name="mergeLibation"
                      control={control}
                      render={({ field: { onChange, value, ref } }) => (
                        <Select
                          options={libations}
                          onChange={(val) => { onChange(val); handleSelectedLibation(val) }}
                          className="w-full whitespace-pre-wrap"
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.mergeLibation ? 'red' : '#CCCCCC',
                            }),
                          }}
                        />
                      )}
                    />
                    {errors.mergeLibation && <span className="text-red-400">{errors.mergeLibation.message}</span>}
                    {/* // ! comment out check box. save for later use */}
                    {/* <div className="mt-1 text-sm">
                      <label htmlFor="searchAll" className="flex items-center text-sm font-medium text-gray-700">
                        <Checkbox
                          className="mr-4 rounded"
                          checked={isSearchAll}
                          onChange={() => setIsSearchAll((state) => !state)}
                        >
                          Search All Wines
                        </Checkbox>
                      </label>
                    </div> */}
                  </>
                )}
              </div>
            </div>
            <br />
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th>
                    {/* Changes */}
                  </th>
                  <th>
                    Original
                  </th>
                  <th>
                    New
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                <tr>
                  <td className="text-gray-500">
                    Name:
                  </td>
                  <td>
                    {libation.name ? libation.name : '-'}
                  </td>
                  <td>
                    {selectedLibation?.name ? selectedLibation?.name : '-'}
                  </td>
                </tr>
                <tr>
                  <td className="text-gray-500">
                    Vintage:
                  </td>
                  <td>
                    {libation.vintage ? libation.vintage : '-'}
                  </td>
                  <td>
                    {selectedLibation?.vintage ? selectedLibation?.vintage : '-'}
                  </td>
                </tr>
                <tr>
                  <td className="text-gray-500">
                    Varietal:
                  </td>
                  <td>
                    {libation.varietal?.name ? libation.varietal?.name : '-'}
                  </td>
                  <td>
                    {selectedLibation?.varietal?.name ? selectedLibation?.varietal?.name : '-'}
                  </td>
                </tr>
                <tr>
                  <td className="text-gray-500">
                    Type:
                  </td>
                  <td>
                    {libation.libationType?.name ? libation.libationType?.name : '-'}
                  </td>
                  <td>
                    {selectedLibation?.libationType?.name ? selectedLibation?.libationType?.name : '-'}
                  </td>
                </tr>
                {(isSearchAll || (selectedLibation && libation.listing?.id !== selectedLibation?.listing?.id)) && (
                  <tr>
                    <td className="text-gray-500">
                      Listing:
                    </td>
                    <td>
                      {libation.listing?.name ? libation.listing?.name : '-'}
                    </td>
                    <td>
                      {selectedLibation?.listing?.name ? selectedLibation?.listing?.name : '-'}
                    </td>
                  </tr>
                )}
                <tr className="h-2">
                  {/* padding row */}
                </tr>
                <tr className="border-t">
                  <td className="text-gray-500">
                    User Sips:
                  </td>
                  <td>
                    {libation.counts?.userSips ? libation.counts?.userSips : 0}
                  </td>
                  <td>
                    {selectedLibation?.counts?.userSips >= 0 ? `${selectedLibation?.counts?.userSips} (+${libation.counts?.userSips})` : '-'}
                  </td>
                </tr>
                <tr>
                  <td className="text-gray-500">
                    Flights:
                  </td>
                  <td>
                    {libation.counts?.flights ? libation.counts?.flights : 0}
                  </td>
                  <td>
                    {selectedLibation?.counts?.flights >=0 ? `${selectedLibation?.counts?.flights} (+${libation.counts?.flights})` : '-'}
                  </td>
                </tr>
              </tbody>
            </table>
            {!showWarning
              && (
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button type="button" onClick={() => setShowWarning(true)} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-icon text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 hover:bg-red-700 sm:col-start-2 sm:text-sm">Merge</button>
                  <button type="button" onClick={onCancelClick} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:col-start-1 sm:text-sm">Cancel</button>
                </div>
              )}
            {showWarning
              && (
                <>
                  <div className="mt-2 text-left sm:mt-5">
                    <div className="mt-2">
                      <p className="text-sm text-red-500 font-bold">
                        Are you sure you want to merge these wines?
                      </p>
                      <p className="text-sm text-red-400">
                        Merging will remove this wine and move its associated data to the selected replacement.
                      </p>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    <button type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-icon text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 hover:bg-red-700 sm:col-start-2 sm:text-sm">Confirm</button>
                    <button type="button" onClick={() => { setShowWarning(false); onCancelClick(); }} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:col-start-1 sm:text-sm">Cancel</button>
                  </div>
                </>
              )}
          </form>
        </div>
      </div >
    </>
  )
};
