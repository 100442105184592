import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Select from "react-select";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX, faPlus } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../Modal';
import client from '../../../lib/feathers';

// SORTABLE:
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  TouchSensor,
  MouseSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableRow } from '../SortableItem';

export default function FlightLibationList({ currentFlight }) {
  const { lid, fid } = useParams();

  const role = useSelector((state) => state.auth.user.role);
  
  const [libations, setLibations] = useState([]);
  const [flightLibations, setFlightLibations] = useState([]);

  const [selectedLibation, setSelectedLibation] = useState();

  const [libationToRemove, setLibationToRemove] = useState();

  const [isSearchAll, setIsSearchAll] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (role) {
      const loadFlightLibations = async () => {
        const service = client.service(`${role}/flight-libation`);
        const query = {
          flightId: fid,
          $sort: {
            order: 1,
          },
        };
        const response = await service.find({ query });
        setFlightLibations(response.data);
      }
  
      const loadLibations = async () => {
        const service = client.service(`${role}/libation`);
        const query = {
          ...(!isSearchAll && {listingId: lid }),
          $limit: 999,
        };
        const response = await service.find({ query });
        const options = response.data.map((libation) => (
          { 
            ...libation, 
            label: `${libation.name} \n${libation.vintage} ${libation.varietal?.name || ''} ${isSearchAll ? `\n[${libation.listing?.name || 'User-Submitted'}]` : ''}`, 
            value: libation.id }
        ));
        setLibations(options);
      };
  
      loadLibations();
      loadFlightLibations();
    }
  }, [role, isSearchAll])

  const handleAddFlightLibation = async (e) => {
    e.preventDefault();

    if(selectedLibation) {
      const service = client.service(`${role}/flight-libation`);
      const data = {
        libationId: selectedLibation.id,
        flightId: fid,
        order: flightLibations.length - 1,
      }
      const response = await service.create(data);
      
      setFlightLibations([
        ...flightLibations, 
        { 
          ...response, 
          libation: { 
            name: selectedLibation.name, 
            vintage: selectedLibation.vintage, 
            varietal: {
              name: selectedLibation.varietal?.name
            },
            libationType: {
              name: selectedLibation.libationType?.name,
            },
            listing: {
              name: selectedLibation.listing?.name,
              id: selectedLibation.listing?.id,
            }
          } 
        }
      ]);
      
      setSelectedLibation(null);
    }
  }

  // DELETE LIBATION HANDLERS
  const handleDeleteFlightLibation = async () => {
    if (libationToRemove) {
      const service = client.service(`${role}/flight-libation`);
      await service.remove(libationToRemove.id);
      
      const updatedFlightLibations = [...flightLibations].filter((libation) => libation.id !== libationToRemove.id);
      setFlightLibations(updatedFlightLibations);
      setShowDeleteModal(false);
      setLibationToRemove(null);
    }
  }
  
  const handleOpenDeleteModal = (libationToRemove) => {
    setShowDeleteModal(true);
    setLibationToRemove(libationToRemove);
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setLibationToRemove(null);
  }
  
  // SORTABLE HANDLERS
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 2,
    },
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 100,
      tolerance: 5,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const updateOrder = (flightLibation) => {
    flightLibation.forEach(async (item, index) => {
      try {
        const service = client.service(`${role}/flight-libation`);
        await service.patch(item.id, { order: index });
      } catch (e) {
        console.log("error", e);
      }
    });
  };

  const handleOnDragEnd = async (event) => {
    if(!event.over){
      return;
    }
    const { active, over } = event;
    const startingContainer = active.data.current.sortable.containerId;
    const endingContainer = over.data.current.sortable.containerId;
    if (active.id === over.id) {
      return;
    }
    else {
      const flIds = flightLibations.map((a) => a.id);
      const activeIndex = flIds.indexOf(active.id);
      const overIndex = flIds.indexOf(over.id);
      const newArray = arrayMove(flightLibations, activeIndex, overIndex);
      if (startingContainer !== endingContainer) {
        const [changedItem] = newArray.splice(overIndex, 1);
        changedItem.type = endingContainer;
        newArray.splice(overIndex, 0, changedItem);
      }
      setFlightLibations(newArray);
      updateOrder(newArray);
    }
  };

  const Checkbox = ({ children, ...props }) => (
    <label className="flex items-center text-sm font-medium text-gray-700">
      <input type="checkbox" {...props} />
      {children}
    </label>
  );

  return (
    <>
      {showDeleteModal
        && (
          <Modal>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                Confirm Removal
              </h3>
              <div className="mt-2">
                <p className="text-base text-gray-500">
                  Are you sure you want to remove {libationToRemove ? `${libationToRemove.libation?.name}` : ' this libation'}?
                </p>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button type="button" onClick={handleDeleteFlightLibation} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-icon text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 hover:bg-red-700 sm:col-start-2 sm:text-sm">Confirm</button>
              <button type="button" onClick={() => handleCloseDeleteModal()} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:col-start-1 sm:text-sm">Cancel</button>
            </div>
          </Modal>
        )}
      <DndContext
        onDragEnd={handleOnDragEnd}
        collisionDetection={closestCenter}
        sensors={sensors}
      >
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg my-5">
        {/* GRID */}
          <div className="md:grid md:grid-cols-3 md:gap-6">
            {/* COL 1 */}
            <div className="md:col-span-1">        
              <h3 className="text-lg font-medium leading-6 text-gray-900 inline-block align-middle mr-2">
                Flight Builder
              </h3>
              <div className="mt-1 text-sm text-gray-500">
                <p>Add and organize the wines for {currentFlight ? ` ${currentFlight.name}.` : ' this flight.'}</p>
                <p>Drag and drop to reorder.</p>
              </div>
            </div>
            {/* COL 2 */}
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="col-span-6">
                <label htmlFor="libationId" className="block text-sm font-medium text-gray-700">
                  Search to add wines to this flight
                </label>
                {libations && (
                  <>
                    <form onSubmit={handleAddFlightLibation} className="flex w-full items-center">
                      <Select
                        options={libations}
                        value={selectedLibation}
                        onChange={setSelectedLibation}
                        className="w-full whitespace-pre-wrap"
                      />
                      <button type="submit" className="items-center px-4 py-2 my-1 mx-2 border border-primary rounded-md shadow-sm text-sm font-medium text-primary bg-transparent hover:bg-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-primary">
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    </form>
                    <div className="mt-1 text-sm">
                      <Checkbox
                        checked={isSearchAll}
                        onChange={() => setIsSearchAll((state) => !state)}
                        className="mr-4 rounded"
                      >
                        Search All Wines
                      </Checkbox>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="md:px-5 md:col-span-3">
              <SortableContext
              id="flightLibations"
              strategy={rectSortingStrategy}
              items={flightLibations}
              >
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-3 pr-1 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                      Order
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Name
                    </th>
                    <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                      Type
                    </th>
                    <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell">
                      Vintage
                    </th>
                    <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                      Varietal
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                      <span className="sr-only">Manage</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                {flightLibations && (
                  flightLibations.map((item, index) => (
                    <SortableRow key={item.id + index} id={item.id} index={index}>
                      <td className="whitespace-nowrap divide-y divide-gray-200">
                        <span className="whitespace-nowrap px-3 pl-4 pr-1 text-sm text-gray-500 lg:table-cell">
                          {index + 1}
                        </span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                        <span>
                          {item.libation?.name || 'Wine Name'}
                        </span>
                        <br />
                        <span>
                          {item.libation.listing?.id !== lid ? item.libation.listing?.name : ''}
                        </span>
                      </td>
                      <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                        {item.libation?.libationType?.name || ''}
                      </td>
                      <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 md:table-cell">
                        {item.libation?.vintage || 'Vintage'}
                      </td>
                      <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                        {item.libation?.varietal?.name || 'Varietal'}
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                        <button className="items-center px-4 py-2 my-1 mx-2 border border-primary rounded-md shadow-sm text-sm font-medium text-primary bg-transparent hover:bg-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-primary" onClick={() => handleOpenDeleteModal(item)}>
                          <FontAwesomeIcon icon={faX} />
                        </button>
                      </td>
                    </SortableRow>
                  ))
                )}
                {flightLibations.length === 0 && (
                  <tr>
                    <td className="p-3 text-sm text-gray-400">
                      No libations added.
                    </td>
                  </tr>
                )}
                </tbody>
              </table>
            </SortableContext>
          </div>
        </div>
      </div>
    </DndContext>
  </>
  )
}