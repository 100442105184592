import React, { useEffect, useState } from 'react';
import { formatDistance } from "date-fns";
import AsyncSelect from 'react-select/async';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup";

import Modal from '../../../components/Modal';
import Page from '../../../components/Page';
import Breadcrumb from '../../../components/Breadcrumb';
import client from '../../../lib/feathers';

const schema = yup.object({
  listingId: yup.string().required("Please enter a listing for this wine"),
}).required();

function LibationListingForm({ libId, handleCancel, handleAddListing }) {

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const loadListings = async (input, callback) => {
    const service = client.service('admin/listing');

    const q = {
      $sort: {
        name: -1,
      },
      name: { $iLike: `%${input}%` },
    };

    const result = await service.find({
      query: q,
    });

    callback(result.data.map((l) => ({
      value: l.id,
      label: l.name,
    })));
  };

  return (
    <div className="mb-2">
      <form onSubmit={handleSubmit(handleAddListing)} className="flex justify-between py-3">
        <input type="hidden" name="id" value={libId} {...register('id')} />
        <Controller
          name="listingId"                                    
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <AsyncSelect
              ref={ref}
              loadOptions={loadListings}
              onChange={(val) => onChange(val.value)}
              className="w-full pl-5"
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: errors.listingId ? 'red' : '#CCCCCC',
                }),
              }}
            />
          )}
        />
        <div className="flex gap-2 px-2 whitespace-nowrap text-right text-sm font-medium">
          <button
            type="button"
            onClick={handleCancel}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            Cancel
          </button>
          <button type="submit" className=" items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-icon hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
            Add Listing
          </button>
        </div>
      </form>
      <div className="pl-5 pb-2">
        {errors.listingId && <span className="text-red-400">* {errors.listingId.message}.</span>}
      </div>
    </div>
  );
}


export default function NoListingLibations() {
  const [results, setResults] = useState();
  const [openForms, setOpenForms] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);

  const loadData = async () => {
    const service = client.service('/admin/libation');
    const query = {
      listingId: null,
      $sort: {
        createdAt: -1,
      },
    };
    const data = await service.find({ query });

    setResults(data.data);
  }

  useEffect(() => {
    loadData();
  }, []);

  const handleEditClick = (libId) => {
    const newids = { ...openForms };
    newids[libId] = true;
    setOpenForms(newids);
  };

  const handleCancelClick = (libId) => {
    const newids = { ...openForms };
    delete newids[libId];
    setOpenForms(newids);
  };

  const handleAddListing = async (data) => {
    try {
      const service = client.service('/admin/libation');
      await service.patch(data.id, { ...data, listingName: null });
      
      handleCancelClick(data.id);
      loadData();
      setShowSuccess(true);
    } catch (error) {
      console.error("Error updating libation with listing", error);
    }
  };

  return (
    <Page title="Reports">
      <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
        <nav className="flex" aria-label="Breadcrumb">
          <Breadcrumb elements={[{ name: 'Reports', href: `/admin/reports` }, { name: 'Libations w/o Listings' }]} />
        </nav>
      </div>

      <div className="py-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
        {!results && `Loading...`}
        {results && (
          <div className="flex flex-col w-full min-h-full">
            <h1 className="text-2xl pb-5">Libations Without Listing</h1>
            <div className="grow -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          <b>Libation</b>
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          <b>Listing</b>
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          <b>When</b>
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          {/* <b>Edit</b> */}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {results.map((u) => (
                        <React.Fragment key={u.id}>
                          <tr className="hover:bg-gray-100 cursor-pointer">
                            <td className="px-6 py-4 whitespace-nowrap">
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-green-800">
                                {u.name}
                              </span>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="flex items-center">
                                <div className="ml-4">
                                  <div className="text-sm font-medium text-gray-900">
                                    {u.listingName}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="flex items-center">
                                <div className="ml-4">
                                  <div className="text-sm font-medium text-gray-900">
                                    {formatDistance(u.createdAt, new Date(), { addSuffix: true })}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-2 whitespace-nowrap text-right text-sm font-medium">
                              {!openForms[u.id] && (
                                <button
                                  type="button"
                                  onClick={() => handleEditClick(u.id)}
                                  className="items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-icon hover:bg-red-700 justify-self-end">Edit</button>
                              )}
                            </td>
                          </tr>
                          {openForms[u.id] && (
                            <tr className="text-sm font-medium text-gray-500 flex-1 border-none">
                              <td colSpan="4">
                                <LibationListingForm
                                  libId={u.id}
                                  handleCancel={() => handleCancelClick(u.id)}
                                  handleAddListing={handleAddListing}
                                  loadData={loadData}
                                />
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="4">
                          <div className="h-9 bg-gray-50" />
                        </td> 
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {showSuccess && (
        <Modal>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
              Success!
            </h3>
            <div className="mt-2">
              <p className="text-base text-gray-500">
                This wine has been added to the listing and moved to its respective wine list.
              </p>
            </div>
          </div>
          <div className="mt-5">
            <button
              type="button"
              onClick={() => setShowSuccess(false)}
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:col-start-1 sm:text-sm"
            >
              Okay
            </button>
          </div>
        </Modal>
        )
      }
    </Page >
  );
}
