import React from 'react';
// import Content from "./Content";


export default function Page(props) {
  const {children} = props;
  return (
    <>
      {children}
    </>
  )
}
