import React, { useEffect, useState, useCallback } from 'react';
import client from '../../../lib/feathers';
import Breadcrumb from '../../../components/Breadcrumb';
import Page from '../../../components/Page';
import PrimaryButton from '../../../components/PrimaryButton';
import { Link, NavLink, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import BusinessInfoPanel from '../../../components/listingDetail/Overview/BusinesInfoPanel';
import LocationInfoPanel from '../../../components/listingDetail/Location/LocationInfoPanel';
import PromotionsPanel from '../../../components/listingDetail/Promotions/PromotionsPanel';
import FlightsInfoPanel from '../../../components/listingDetail/Flights/FlightsInfoPanel';
import UploadPhotoPanel from '../../../components/listingDetail/Gallery/UploadPhotoPanel';
import LibationsInfoPanel from '../../../components/listingDetail/Wines/LibationsInfoPanel';
import IndustryConnectionRequestPanel from '../../../components/listingDetail/Users/IndustryConnectionRequestPanel';
import { useDispatch, useSelector } from 'react-redux';
import { setListing } from '../../../lib/listingsSlice';
import ListingDetailNavigation from '../../../components/listingDetail/ListingDetailNavigation';
import LibationForm from "../../../components/listingDetail/Wines/LibationForm";
import FlightForm from "../../../components/listingDetail/Flights/FlightForm";
import ReportsPanel from '../../../components/listingDetail/Reports/ReportsPanel';
import ItineraryInfoPanel from '../../../components/listingDetail/Itineraries/ItineraryInfoPanel';
import ItineraryForm from '../../../components/listingDetail/Itineraries/ItineraryForm';

export default function ListingDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lid } = useParams();
  const listing = useSelector((s) => s.listings.selected);

  const getListing = async (id) => {
    try {
      const service = client.service(`industry/listing`);
      const result = await service.get(id);

      dispatch(setListing(result));
    } catch (e) {
      console.log('setlisting error', e);
    }
  };

  const onDeleteListingClick = async () => {
    const resp = confirm('Are you sure you want to delete this listing?'); // eslint-disable-line no-restricted-globals
    if (resp) {
      const service = client.service(`industry/listing`);
      await service.remove(listing.id);
      navigate('/listings');
    }
  };

  useEffect(() => {
    if (lid) {
      getListing(lid);
    }
  }, [lid]);

  const tabs = [
    { name: 'Overview', section: '', notifications: null, access: 'all' },
    { name: `Location`, section: 'location', notifications: null, access: 'all' },
    { name: `Promotions`, section: 'promotions', notifications: null, access: 'all' },
    { name: `Gallery`, section: 'gallery', notifications: null, access: 'all' },
    { name: `Users`, section: 'users', notifications: listing?.notifications?.userRequests, access: 'all' },
    { name: `Wines`, section: 'wines', notifications: listing?.notifications?.userLibations, access: 'all' },
    { name: `Flights`, section: 'flights', notifications: null, access: 'all' },
    { name: `Reports`, section: 'reports', notifications: null, access: 'all' },
    { name: `Itineraries`, section: 'itineraries', notifications: null, access: ['Tour Company'] }
  ]

  return (
    <Page>
      {listing
        ? (
          <div className="space-y-6">
            <div className="pb-5 border-b border-gray-200">
              <div className=" sm:flex sm:items-center sm:justify-between">
                <Breadcrumb elements={[{ name: `${listing?.name || 'Listing Detail'}` }]} />
                {/* <div className="mt-3 sm:mt-0 sm:ml-4">
                  <PrimaryButton onClick={() => navigate(`/industry/listings/${listing.id}/edit`)}>
                    Edit Listing
                  </PrimaryButton>
                </div> */}
              </div>
              <ListingDetailNavigation listing={listing} tabs={tabs} basePath={'/industry/listings'} />
            </div>

            <Routes>
              <Route path="/" element={<BusinessInfoPanel />} />
              <Route path="/location" element={<LocationInfoPanel />} />
              <Route path="/gallery" element={<UploadPhotoPanel />} />
              <Route path="/promotions" element={<PromotionsPanel />} />
              <Route path="/users" element={<IndustryConnectionRequestPanel />} />
              <Route path="/wines" element={<LibationsInfoPanel />} />
              <Route path="/wines/:wid/edit" element={<LibationForm />} />
              <Route path="/flights" element={<FlightsInfoPanel />} />
              <Route path="/flights/:fid/edit" element={<FlightForm />} />
              <Route path="/reports" element={<ReportsPanel />} />
              <Route path="/itineraries" element={<ItineraryInfoPanel />} />
              <Route path="/itineraries/:iid/edit" element={<ItineraryForm />} />
            </Routes>

          </div>
        )
        : <div>Loading Listing</div>
      }
    </Page >
  );
}
