import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
// import { v4 as uuidv4 } from 'uuid';
// import axios from 'axios';
// import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import client from '../../../lib/feathers';
import Breadcrumb from '../../../components/Breadcrumb';
import Page from '../../../components/Page';

export default function UserDetail() {
  // const navigate = useNavigate();
  const { uid } = useParams();
  const [user, setUser] = useState('');
  const [userListings, setUserListings] = useState([]);
  const [editingRole, setEditingRole] = useState(false);
  const [newRole, setNewRole] = useState();
  const roleOptions = [
    { value: 'user', label: 'User' },
    { value: 'industry', label: 'Industry' },
    { value: 'admin', label: 'Admin' },
  ];
  const listingRoleOptions = [
    { value: 'manager', label: 'Manager' },
    { value: 'staff', label: 'Staff' },
  ];
  const [newListing, setNewListing] = useState();
  const [newRelationship, setNewRelationship] = useState();
  const [newEmail, setNewEmail] = useState('');
  const [formKey, setFormKey] = useState(0);
  //  const [filesToUpload, setFilesToUpload] = useState();

  const setNewRoleFromUser = (u) => {
    setNewRole({ value: u.role, label: u.role });
  };

  const getUser = async (id) => {
    try {
      const service = client.service('admin/user');
      const result = await service.get(id);

      setUser(result);
      setNewRoleFromUser(result);
    } catch (e) {
      console.log('error', e);
    }
  };

  const updateUserRole = async (role) => {
    const service = client.service('admin/user');
    await service.patch(user.id, { role: role.value });

    await getUser(user.id);
    setNewRole(role);
    setEditingRole(false);
  };

  const loadListings = async (input, callback) => {
    const service = client.service('admin/listing');

    const q = {
      $sort: {
        name: -1,
      },
      name: { $iLike: `%${input}%` },
    };

    const result = await service.find({
      query: q,
    });

    callback(result.data.map((l) => ({
      value: l.id,
      label: l.name,
    })));
  };

  const loadUserListings = async () => {
    const service = client.service('/admin/listing-user');
    const uls = await service.find({ query: { userId: uid } });

    setUserListings(uls.data);
  };

  const saveNewRelationShip = async () => {

    const data = {
      listingId: newListing,
      userId: user.id,
      email: newEmail,
      access: newRelationship,
    };
    const service = client.service('/admin/listing-user');
    const listUser = await service.create(data);

    loadUserListings();
    setNewListing(null);
    setNewEmail('');
    setNewRelationship(null);
    setFormKey((prevKey) => prevKey + 1);
  };

  const onDeleteConnectionClick = async (id) => {
    const resp = confirm('Are you sure you want to delete this connection?'); // eslint-disable-line no-restricted-globals
    if (resp) {
      const service = client.service(`admin/listing-user`);
      await service.remove(id);
    }
    loadUserListings();
  };

  useEffect(() => {
    if (uid) {
      getUser(uid);
    }
  }, [uid]);
  
  useEffect(() => {
    if (user && user.role === "industry") loadUserListings();
  }, [user]);

  return (

    <Page title="User">
      {user
        ? (
          <div className="space-y-6">
            <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
              <Breadcrumb elements={[{ name: 'Users', href: '/admin/users' }, { name: 'User Detail' }]} />
              <div className="mt-3 sm:mt-0 sm:ml-4">
                {/* <button onClick={() => navigate(`/admin/users/${user.id}/edit`)} type="button" className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Edit
                </button> */}
              </div>
            </div>
            <div className="bg-white shadow overflow-hidden px-4 py-5 sm:rounded-lg sm:p-6">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Personal Information
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  {/* Personal details and application. */}
                </p>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Name
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {user.firstName}
                      &nbsp;
                      {user.lastName}
                    </dd>
                  </div>
                </dl>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      UserName
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 md:flex">
                      {user.username}
                      <img src={user.qrcode} width={200} />
                    </dd>
                  </div>
                </dl>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      E-Mail
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {user.email}
                    </dd>
                  </div>
                </dl>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Locale
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {user.locale}
                    </dd>
                  </div>
                </dl>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Role
                    </dt>
                    {!editingRole && (
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {user.role}
                        <a
                          onClick={() => setEditingRole(true)}
                          className="border px-1 py-2 ml-2"
                        >
                          Edit
                        </a>
                      </dd>
                    )}
                    {editingRole && (
                      <div className="flex">
                        <Select
                          options={roleOptions}
                          value={newRole}
                          onChange={(r) => setNewRole(r)}
                          className="mr-1"
                        />
                        <button
                          type="button"
                          className="font-semibold border py-1 px-2 bg-primary text-white"
                          onClick={() => updateUserRole(newRole)}
                        >
                          Save
                        </button>
                        <a
                          onClick={() => {
                            setNewRoleFromUser(user);
                            setEditingRole(false);
                          }}
                          className="border py-1 px-2"
                        >
                          Cancel
                        </a>
                      </div>
                    )}
                  </div>
                </dl>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Created At
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {user.createdAt}
                    </dd>
                  </div>
                </dl>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Updated At
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {user.updatedAt}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            <div className="bg-white shadow overflow-hidden px-4 py-5 sm:rounded-lg sm:p-6">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  User Information
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  {/* Personal details and application. */}
                </p>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Lists
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {user.listingLists?.length || 0}
                    </dd>
                  </div>
                </dl>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Check-Ins
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {user.checkins?.length || 0}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>

            {user.role === 'industry' && (
              <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <div className="md:flex">
                  <div className="px-4 py-5 sm:px-6 flex-1">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Industry Connections
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      Winery Connections and Relationship.
                    </p>
                  </div>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-4 sm:py-5 md:flex">
                      <div className="text-sm font-medium text-gray-500 flex-1">
                        <AsyncSelect
                          key={`listing-${formKey}`}
                          // cacheOptions
                          loadOptions={loadListings}
                          placeholder="Select Listing"
                          // defaultOptions
                          onInputChange={(e) => {
                            console.log('async select change', e);
                          }}
                          onChange={(r) => setNewListing(r.value)}
                        />
                      </div>
                      <div>
                        <Select
                          key={`role-${formKey}`}
                          options={listingRoleOptions}
                          placeholder="Select Role"
                          // value={newRole}
                          onChange={(r) => setNewRelationship(r.value)}
                          className="mr-1"
                        />
                      </div>
                      <div className="flex-1">
                        <input type="text" name="newEmail" value={newEmail} onChange={(e) => setNewEmail(e.target.value)} placeholder="name@domain.com" className=" focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>
                      <div>
                        <button onClick={saveNewRelationShip} type="button" className=" items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-icon hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                          Add Relationship
                        </button>
                      </div>
                    </div>
                  </dl>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                  <dl className="sm:divide-y sm:divide-gray-200">
                    {userListings && userListings.length > 0 && userListings.map((ul, i) => {
                      return (
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6" key={i}>
                          <dt className="text-sm font-medium text-gray-500">
                            {ul.listing.name}
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                            {ul.access}
                          </dd>
                          <dd className="break-all mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                            {ul.email}
                          </dd>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                            <button type="button" onClick={() => onDeleteConnectionClick(ul.id)} className="items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">Delete</button>
                          </dd>
                        </div>
                      );
                    })}

                  </dl>
                </div>
              </div>
            )}
          </div>
        )
        : <div> Loading User</div>}
    </Page>
  );
}
