import React from 'react';

export default function HourSelect(props) {
  const { prompt = 'Select', value, onChange } = props;

  return (
    <select name="prompt" value={value} onChange={onChange}>
      <option value="">
        {prompt}
      </option>
      <option value="open_24_hours">Open 24 hours</option>
      <option value="0 0">
        12:00 am (midnight)
      </option>
      <option value="0 30">
        12:30 am
      </option>
      <option value="1 0">
        1:00 am
      </option>
      <option value="1 30">
        1:30 am
      </option>
      <option value="2 0">
        2:00 am
      </option>
      <option value="2 30">
        2:30 am
      </option>
      <option value="3 0">
        3:00 am
      </option>
      <option value="3 30">
        3:30 am
      </option>
      <option value="4 0">
        4:00 am
      </option>
      <option value="4 30">
        4:30 am
      </option>
      <option value="5 0">
        5:00 am
      </option>
      <option value="5 30">
        5:30 am
      </option>
      <option value="6 0">
        6:00 am
      </option>
      <option value="6 30">
        6:30 am
      </option>
      <option value="7 0">
        7:00 am
      </option>
      <option value="7 30">
        7:30 am
      </option>
      <option value="8 0">
        8:00 am
      </option>
      <option value="8 30">
        8:30 am
      </option>
      <option value="9 0">
        9:00 am
      </option>
      <option value="9 30">
        9:30 am
      </option>
      <option value="10 0">
        10:00 am
      </option>
      <option value="10 30">
        10:30 am
      </option>
      <option value="11 0">
        11:00 am
      </option>
      <option value="11 30">
        11:30 am
      </option>
      <option value="12 0">
        12:00 pm (noon)
      </option>
      <option value="12 30">
        12:30 pm
      </option>
      <option value="13 0">
        1:00 pm
      </option>
      <option value="13 30">
        1:30 pm
      </option>
      <option value="14 0">
        2:00 pm
      </option>
      <option value="14 30">
        2:30 pm
      </option>
      <option value="15 0">
        3:00 pm
      </option>
      <option value="15 30">
        3:30 pm
      </option>
      <option value="16 0">
        4:00 pm
      </option>
      <option value="16 30">
        4:30 pm
      </option>
      <option value="17 0">
        5:00 pm
      </option>
      <option value="17 30">
        5:30 pm
      </option>
      <option value="18 0">
        6:00 pm
      </option>
      <option value="18 30">
        6:30 pm
      </option>
      <option value="19 0">
        7:00 pm
      </option>
      <option value="19 30">
        7:30 pm
      </option>
      <option value="20 0">
        8:00 pm
      </option>
      <option value="20 30">
        8:30 pm
      </option>
      <option value="21 0">
        9:00 pm
      </option>
      <option value="21 30">
        9:30 pm
      </option>
      <option value="22 0">
        10:00 pm
      </option>
      <option value="22 30">
        10:30 pm
      </option>
      <option value="23 0">
        11:00 pm
      </option>
      <option value="23 30">
        11:30 pm
      </option>

    </select>
  );
}
