import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  results: [],
  keywords: '',
  categories: [],
  selected: undefined,
};

export const listingsSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setListings: (state, action) => {
      state.results = action.payload;
    },
    setListing: (state, action) => {
      state.selected = action.payload;
    },
    setKeywords: (state, action) => {
      state.keywords = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
    setListings,
    setKeywords,
    setCategories,
    setListing,
} = listingsSlice.actions;

export default listingsSlice.reducer;