import React, { useEffect, useState } from 'react';
import { formatDistance } from "date-fns";
import Page from '../../../components/Page';
import Breadcrumb from '../../../components/Breadcrumb';
import client from '../../../lib/feathers';

export default function NoResultsSearch() {
  const [results, setResults] = useState();
  const loadData = async () => {
    const service = client.service('/admin/report-no-result-search');
    const data = await service.find();

    setResults(data);
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Page title="Reports">
      <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">

        <nav className="flex" aria-label="Breadcrumb">
          <Breadcrumb elements={[{ name: 'Reports', href: `/admin/reports` }, { name: 'No Result Search' }]} />
        </nav>

      </div>

      <div className="py-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
        {!results && `Loading...`}
        {results && (
          <div className="flex flex-col w-full">
            <h1 className="text-2xl pb-5">Searches with No Results</h1>
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          <b>Keywords</b>
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          <b>User</b>
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          <b>When</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {results.map((u) => (
                        <tr className="hover:bg-gray-100 cursor-pointer" key={u.id}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-green-800">
                              {/* {l.category.name} */}
                              {u.keywords}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">
                                  {u.name}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">
                                  {formatDistance(u.createdAt, new Date(), { addSuffix: true })}
                                </div>
                              </div>
                            </div>
                          </td>

                          {/* <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <Link to={`/admin/users/${u.id}/edit`} className="text-red-600 hover:text-red-900">Edit</Link>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>
        )}
      </div>


    </Page >
  );
}
