import React from 'react';

const cx = (...classes) => classes.filter(Boolean).join(' ');

export default function PrimaryButton(props) {
  const {
    onClick,
    children,
    type = 'button',
    disabled = false,
    className
  } = props;

  return (
    <button
      type={type === 'submit' ? 'submit' : 'button'}
      className={cx(
        "inline-flex mx-2 items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-900",
        className,
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
