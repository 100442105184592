import React, { useEffect, useState, createRef } from "react";
import Select from "react-select";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as icon from "@fortawesome/free-solid-svg-icons";
import { Formik, Field, Form } from "formik";
import toast, { Toaster } from "react-hot-toast";
import { MapContainer, TileLayer, Popup, Marker } from "react-leaflet";
import Breadcrumb from "../../../../components/Breadcrumb";
import Page from "../../../../components/Page";
import client from "../../../../lib/feathers";
import HoursControl from "../../../../components/HoursControl";
import PrimaryButton from "../../../../components/PrimaryButton";
import DraggableMarker from "../../../../components/DraggableMarker";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import featureLevels from "../../../../lib/featureLevels";
import FeatureDisabled from "../../../../components/FeatureDisabled";

export default function ListingForm() {
  const { lid } = useParams();

  const user = useSelector((state) => state.auth.user);

  const [listing, setListing] = useState();
  const [categories, setCategories] = useState([]);
  const [contact, setContact] = useState({ name: "" });
  const [accommodations, setAccommodations] = useState({});
  const [address, setAddress] = useState({});
  const [hours, setHours] = useState({});
  const [allHours, setAllHours] = useState({});
  const [location, setLocation] = useState({});
  const [homeMarker, setHomeMarker] = useState();
  // const [setAll, setSetAll] = useState(false);
  const [categoryListings, setCategoryListings] = useState([]);
  const [previewShowing, setPreviewShowing] = useState(false);
  // const [accommodationListings, setAccommodationListings] = useState([]);
  const dayLabels = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const states = ["CA", "OR", "WA"];
  const daysArray = [6, 0, 1, 2, 3, 4, 5];
  const [services, setServices] = useState([]);
  const [safety, setSafety] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [availability, setAvailability] = useState([]);
  const [logoAttachment, setLogoAttachment] = useState({});
  const [logoLoading, setLogoLoading] = useState(false);
  const [profileAttachment, setProfileAttachment] = useState({});
  const [coverLoading, setCoverLoading] = useState(false);

  const hiddenCoverFileInput = createRef();

  const handleCoverClick = (event) => {
    hiddenCoverFileInput.current.click();
  };

  const handleCoverPicChange = async (event) => {
    const fileUploaded = event.target.files[0];
    const id = uuidv4();
    const uploadService = client.service(`${user.role}/attachment-upload`);
    const data = {
      "file-name": `listing-images/${id}`,
      "file-type": fileUploaded.type,
      id: lid,
    };
    const urls = await uploadService.create(data);
    const signedUrl = urls.signedRequest;
    const options = {
      headers: {
        "Content-Type": fileUploaded.type,
      },
      onUploadProgress: () => {
        setCoverLoading(true);
      },
    };
    await axios.put(signedUrl, fileUploaded, options);

    const atd = {
      id,
      path: urls.path,
      type: "photo",
      mime: fileUploaded.type,
      listingId: listing.id,
    };

    const attachmentService = client.service(`${user.role}/attachment`);
    const profileAttachment = await attachmentService.create(atd);
    setProfileAttachment(profileAttachment);
    const listingService = client.service(`${user.role}/listing`);
    await listingService.patch(listing.id, { profileAttachmentId: profileAttachment.id});
    setCoverLoading(false);
  };

  const hiddenLogoFileInput = createRef();

  const handleImageClick = (event) => {
    hiddenLogoFileInput.current.click();
  };

  const handleProfilePicChange = async (event) => {
    const fileUploaded = event.target.files[0];
    const id = uuidv4();
    const uploadService = client.service(`${user.role}/attachment-upload`);
    const data = {
      "file-name": `listing-images/${id}`,
      "file-type": fileUploaded.type,
      id: listing.id,
    };
    const s3File = await uploadService.create(data);
    const signedUrl = s3File.signedRequest;
    const options = {
      headers: {
        "Content-Type": fileUploaded.type,
      },
      onUploadProgress: () => {
        setLogoLoading(true);
      },
    };

    await axios.put(signedUrl, fileUploaded, options);

    const atd = {
      id,
      path: s3File.path,
      type: "photo",
      mime: fileUploaded.type,
      listingId: listing.id,
    };
    const attachmentService = client.service(`${user.role}/attachment`);
    const logoAttachment = await attachmentService.create(atd);
    setLogoAttachment(logoAttachment);
    const listingService = client.service(`${user.role}/listing`);
    await listingService.patch(listing.id, { logoAttachmentId: logoAttachment.id});
    setLogoLoading(false);
  };

  const onDeleteLogo = async () => {
    if (confirm("Are you sure you want to delete this logo?")) {
      const ListingRemoveAttachmentService = client.service(`${user.role}/listing-remove-attachment`);
      await ListingRemoveAttachmentService.update(listing.id, {key: 'logo'});
      setLogoAttachment({});
    } else {
      return;
    }
  };

  const onDeleteCoverPic = async () => {
    if (confirm("Are you sure you want to delete this cover photo?")) {
      const ListingRemoveAttachmentService = client.service(`${user.role}/listing-remove-attachment`);
      await ListingRemoveAttachmentService.update(listing.id, {key: 'profile'});
      setProfileAttachment({});
    } else {
      return;
    }
  };

  const onContactChange = (e) => {
    const ns = { ...contact };
    ns[e.target.name] = e.target.value;
    setContact(ns);
  };

  const removeHoursFromDay = (dayIndex, hourIndex) => {
    // remove the hourIndex from the hours on the day of the dayIndex
    const newHours = hours.days[dayIndex].hours;
    newHours.splice(hourIndex, 1);
    hours.days[dayIndex].hours = newHours;

    setHours({ ...hours });
  };

  const addNewHoursToDay = (day) => {
    if (!hours.days) {
      hours.days = {};
    }
    if (!hours.days[day]) {
      hours.days[day] = { hours: [] };
    }

    hours.days[day].hours.push({});

    setHours({ ...hours });
  };

  const onCategoryListingsChange = (e) => {
    const cl = e.map((option) => {
      if (option.id) {
        return option;
      } else {
        // lookup from categories by id == option.value
        categories.find((c) => c.id == option.value);
        return categories.find((c) => c.id == option.value);
      }
    });
    setCategoryListings(cl);
  };

  const onLocationChange = (location) => {
    setHomeMarker({
      lat: location.latitude,
      lon: location.longitude,
    });
    setLocation(location);
  };

  const onAccommodationsChange = (section) => {
    // const ns = { ...accommodations };
    // if (!ns[section.type]) {
    //   ns[section.type] = {};
    // }
    // ns[section.type][e.target.name] = e.target.checked;

    // setAccommodations(ns);
    if (accommodations.some((e) => e.id === section.id)) {
      const na = [...accommodations].filter((a) => a.id !== section.id);
      setAccommodations(na);
    } else {
      const na = [...accommodations, section];
      setAccommodations(na);
    }
  };

  const onAddressChange = (e) => {
    const ns = { ...address };

    ns[e.target.name] =
      e.target.value.length !== 0 ? e.target.value : undefined;

    setAddress(ns);
  };

  const onHoursChange = (day, hour, data) => {
    const ns = { ...hours };
    ns.days[day].hours[hour] = data;
    setHours(ns);
  };

  const onSetAllHours = () => {
    if (window.confirm("Are you sure you want to to confirm the changes?")) {
      if (!hours.days) {
        hours.days = {};
      }
      daysArray.map((day) => {
        if (!hours.days[day]) {
          hours.days[day] = { hours: [] };
        }
        hours.days[day].hours = [allHours];
      });
      setHours({ ...hours });
    }
  };

  const onSetSelectedStatus = (e) => {
    if (hours.status.value) {
      hours.status.value = e.target.value;
    }
    // if(hours.status.value === 'normal') {
    //   hours.status.message = 'We are Operating Normal Hours';
    // }
    setHours({ ...hours });
  };

  const setClosedMessage = (e) => {
    if (!hours.status.message) {
      hours.status.message = "";
    } else {
      hours.status.message = e.target.value;
    }
  };

  const getListing = async (id) => {
    try {
      const service = client.service("industry/listing");

      const result = await service.get(id);
      const d = result;
      setContact({ ...d.contact });
      setAddress({ ...d.address });
      setAccommodations([...d.accommodation]);
      setCategoryListings(
        [...d.categories].map((c) => ({ ...c, label: c.name, value: c.id }))
      );
      setLogoAttachment({ ...d.logoAttachment });
      setProfileAttachment({ ...d.profileAttachment });

      if (!d.hours) {
        d.hours = {};
      }
      if (!d.hours.status) {
        d.hours.status = {
          value: "Normal",
        };
      }
      if (!d.hours.days) {
        if (!d.hours.days) {
          d.hours.days = [
            {hours: []},
            {hours: []},
            {hours: []},
            {hours: []},
            {hours: []},
            {hours: []},
            {hours: []},
          ];
        }
      }
      setHours({ ...d.hours });

      if (d.location) {
        setLocation({
          longitude: d.location.coordinates[0],
          latitude: d.location.coordinates[1],
        });
      } else {
        setLocation({ longitude: "", latitude: "" });
      }
      if (result.location) {
        setHomeMarker({
          lat: result.location.coordinates[1],
          lon: result.location.coordinates[0],
        });
      } else {
        //Portland Coordinates as place holder
        setHomeMarker({ lat: "45.517294", lon: "-122.601302" });
      }

      setListing(d);
    } catch (e) {
      console.log("error", e);
    }
  };

  const getCategories = async () => {
    try {
      const service = client.service("industry/category");
      const query = {
        $sort: {
          createdAt: -1,
        },
      };

      const result = await service.find({
        query,
      });

      setCategories(
        result.data.map((c) => ({ ...c, label: c.name, value: c.id }))
      );
    } catch (e) {
      console.log("error", e);
    }
  };

  const onFormSubmit = async (values) => {
    const service = client.service("industry/listing");
    const data = {
      ...listing,
      ...values,
      contact,
      hours,
      address,
      logoAttachmentId: logoAttachment.id,
      profileAttachmentId: profileAttachment.id,
      accommodation: accommodations,
      id: lid,
      categories: categoryListings,
    };
    if (data.statusMessage) {
      hours.status.message = data.statusMessage;
    }
    if (hours.status.value !== "Normal") {
      hours.status.message = data.statusMessage;
    }
    if (
      location &&
      parseInt(location.longitude, 10) !== 0 &&
      parseInt(location.latitude, 10) !== 0
    ) {
      data.location = {
        type: "Point",
        coordinates: [location.longitude, location.latitude],
      };
    }
    try {
      const result = await service.update(listing.id, data);
      getListing(listing.id);
      toast.success("Saved!");
    } catch (e) {
      alert("Something went wrong creating the new listing.");
      console.log("error updating listing", e);
      toast.error("Something went wrong creating the new listing.");
    }
  };

  const getAccommodations = async () => {
    try {
      const service = client.service("industry/accommodation");
      const query = {
        $limit: 1000,
        $sort: {
          type: 1,
        },
      };

      const result = await service.find({
        query,
      });
      setAvailability(result.data.filter((a) => a.type === "availability"));
      setSafety(result.data.filter((a) => a.type === "safety"));
      setServices(result.data.filter((a) => a.type === "services"));
      setCertifications(result.data.filter((a) => a.type === "certifications"));
    } catch (e) {
      console.log("error", e);
    }
  };

  // const getAccommodationListings = async (id) => {
  //   try {
  //     const service = client.service('admin/accommodation-listing');
  //     const result = await service.findAll({ where: { listingId: id } });
  //     setAccommodationListings(result.data);
  //   } catch (e) {
  //     console.log('error', e);
  //   }
  // };

  useEffect(() => {
    if (lid) {
      getListing(lid);
      // getAccommodationListings(lid);
    }
  }, [lid]);

  useEffect(() => {
    getCategories();
    getAccommodations();
  }, []);

  const saveToolBar = () => (
    <div className="flex justify-between pt-5">
      <PrimaryButton onClick={() => setPreviewShowing(!previewShowing)}>
        Preview page
      </PrimaryButton>
      <div>
        <Link
          to={`/industry/listings/${listing.id}/`}
          type="button"
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        >
          Cancel
        </Link>
        <PrimaryButton type="submit">Save</PrimaryButton>
      </div>
    </div>
  );

  return (
    <Page>
      <Toaster
        toastOptions={{
          duration: 3000,
        }}
      />
      {listing ? (
        <div>
          <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
            <nav className="flex" aria-label="Breadcrumb">
              <Breadcrumb
                elements={[
                  {
                    name: "Listing Detail",
                    href: `/industry/listings/${listing.id}/`,
                  },
                  { name: "Edit Listing" },
                ]}s
              />
            </nav>
            <div className="mt-3 sm:mt-0 sm:ml-4" />
          </div>
          <Formik
            initialValues={{
              name: listing.name,
              venueId: listing.venueId,
              shortDescription: listing.shortDescription || "",
              status: listing.status,
              statusMessage: hours?.status?.message || "",
            }}
            onSubmit={onFormSubmit}
          >
            <Form>
              <div className="space-y-6">
                {saveToolBar()}
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1 sticky rounded">
                    {previewShowing ? (
                      <iframe
                        src={`${process.env.REACT_APP_CLIENT_URL}/listing/${lid}`}
                        title="listing preview"
                        width="100%"
                        height="87%"
                      />
                    ) : null}
                  </div>
                  <div
                    className={
                      previewShowing
                        ? "mt-5 md:mt-0 md:col-span-2"
                        : "mt-5 md:mt-0 md:col-span-3"
                    }
                  >
                    <div className="bg-white shadow px-4 py-5 sm:rounded-lg my-5">
                      <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                          <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Profile
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            This information will be displayed publicly so be
                            careful what you share.
                          </p>
                        </div>
                        <div className="mt-5 md:mt-0 md:col-span-2">
                          <div className="col-span-6 sm:col-span-4">
                            <label
                              htmlFor="name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Name
                            </label>
                            <Field
                              type="text"
                              required
                              name="name"
                              id="name"
                              autoComplete="false"
                              className="mt-1 focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"
                            />
                          </div>
                          <div>
                          <FeatureDisabled
                              flag={featureLevels.SHORT_DESCRIPTION}
                              level={listing.featureLevel}
                            >
                            <label
                              htmlFor="about"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Short Description
                            </label>
                            <p className="mt-2 text-sm text-gray-500">
                              Brief description for your business. No
                              hyperlinks.
                            </p>
                            <div className="mt-1">
                              <Field
                                as="textarea"
                                id="shortDescription"
                                name="shortDescription"
                                rows="3"
                                className="shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                              />
                            </div>
                            </FeatureDisabled>
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="business_type"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Business Type ({categoryListings.length})
                            </label>
                            <Select
                              defaultValue={categoryListings}
                              name="categories"
                              options={categories}
                              isMulti
                              onChange={onCategoryListingsChange}
                            />
                          </div>

                          <div>
                          <FeatureDisabled
                              flag={featureLevels.LOGO}
                              level={listing.featureLevel}
                            >
                            <label className="block text-sm font-medium text-gray-700">
                              Logo
                            </label>
                            <div className="mt-2 flex items-center space-x-5">
                              {logoAttachment.path ? (
                                <img
                                  src={
                                    process.env.REACT_APP_ATTACHMENT_URL +
                                    `${logoAttachment.path}?h=48&w=48`
                                  }
                                  className="inline-block rounded-full overflow-hidden"
                                ></img>
                              ) : (
                                <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                                  <svg
                                    className="h-full w-full text-gray-300"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                  </svg>
                                </span>
                              )}
                              {logoAttachment.id && (
                                <button
                                  type="button"
                                  className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                  onClick={() => onDeleteLogo()}
                                >
                                  Delete
                                </button>
                              )}
                              {!logoAttachment.id && logoLoading !== true && (
                                <button
                                  type="button"
                                  className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                  onClick={handleImageClick}
                                >
                                  Change
                                </button>
                              )}
                              {!logoAttachment.id && logoLoading === true && (
                                <button
                                  type="button"
                                  className="flex items-center rounded-lg bg-green-700 px-4 py-2 text-white"
                                  disabled
                                >
                                  <svg
                                    className="mr-3 h-5 w-5 animate-spin text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <circle
                                      className="opacity-25"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="currentColor"
                                      strokeWidth="4"
                                    ></circle>
                                    <path
                                      className="opacity-75"
                                      fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                  </svg>
                                  <span className="font-medium">
                                    {" "}
                                    Processing...{" "}
                                  </span>
                                </button>
                              )}

                              <input
                                name="profileId"
                                id="profileId"
                                type="file"
                                onChange={handleProfilePicChange}
                                onClick={(e) => (e.target.value = null)}
                                ref={hiddenLogoFileInput}
                                className="invisible"
                              />
                            </div>
                            </FeatureDisabled>
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Cover Photo
                            </label>
                            <div className="mt-2 flex items-center space-x-5">
                              {/* <div 
                                             
                                                className="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"> */}
                              {profileAttachment.path ? (
                                <img
                                  src={
                                    process.env.REACT_APP_ATTACHMENT_URL +
                                    `${profileAttachment.path}?w=600&f=inside`
                                  }
                                  className="inline-block overflow-hidden w-full max-w-[600px]"
                                ></img>
                              ) : (
                                <div className="inline-block h-50 w-200 bg-gray-100">
                                  <svg
                                    className="mx-auto text-gray-400"
                                    stroke="currentColor"
                                    fill="none"
                                    width="200"
                                    height="60"
                                    viewBox="-15 -15 80 80"
                                    aria-hidden="true"
                                  >
                                    <path
                                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </div>
                              )}
                              {profileAttachment.id && (
                                <button
                                  type="button"
                                  className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                  onClick={() => onDeleteCoverPic()}
                                >
                                  Delete
                                </button>
                              )}
                              {!profileAttachment.id &&
                                coverLoading !== true && (
                                  <button
                                    type="button"
                                    className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                    onClick={handleCoverClick}
                                  >
                                    Change
                                  </button>
                                )}
                              {!profileAttachment.id &&
                                coverLoading === true && (
                                  <button
                                    type="button"
                                    className="flex items-center rounded-lg bg-green-700 px-4 py-2 text-white"
                                    disabled
                                  >
                                    <svg
                                      className="mr-3 h-5 w-5 animate-spin text-white"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                      ></circle>
                                      <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                      ></path>
                                    </svg>
                                    <span className="font-medium">
                                      {" "}
                                      Processing...{" "}
                                    </span>
                                  </button>
                                )}

                              <input
                                name="file-upload"
                                id="file-upload"
                                type="file"
                                onChange={handleCoverPicChange}
                                onClick={(e) => (e.target.value = null)}
                                ref={hiddenCoverFileInput}
                                className="invisible"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white shadow px-4 py-5 sm:rounded-lg my-5">
                      <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                          <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Location Information
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            Use a permanent address where people can visit you.
                          </p>
                          <div className="relative h-96">
                            <MapContainer
                              center={[homeMarker.lat, homeMarker.lon]}
                              zoom={13}
                              scrollWheelZoom={false}
                            >
                              <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                              />
                              <DraggableMarker
                                position={[homeMarker.lat, homeMarker.lon]}
                                onLocationChange={onLocationChange}
                              />
                            </MapContainer>
                          </div>
                        </div>
                        <div className="mt-5 md:mt-0 md:col-span-2">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="country"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Country / Region
                              </label>
                              <select
                                id="country"
                                name="country"
                                autoComplete="country"
                                value={address?.country}
                                onChange={onAddressChange}
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm"
                              >
                                <option>United States</option>
                              </select>
                            </div>

                            <div className="col-span-6">
                              <label
                                htmlFor="street_address"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Street address
                              </label>
                              <input
                                type="text"
                                name="address1"
                                value={address?.address1}
                                onChange={onAddressChange}
                                autoComplete="street-address"
                                className="mt-1 focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>
                            <div className="col-span-6">
                              <label
                                htmlFor="street_address"
                                className="block text-sm font-medium text-gray-700"
                              />
                              <input
                                type="text"
                                name="address2"
                                value={address?.address2}
                                onChange={onAddressChange}
                                id="street_address"
                                autoComplete="street-address"
                                className="mt-1 focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                              <label
                                htmlFor="city"
                                className="block text-sm font-medium text-gray-700"
                              >
                                City
                              </label>
                              <input
                                type="text"
                                name="city"
                                id="city"
                                value={address?.city}
                                onChange={onAddressChange}
                                className="mt-1 focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                              <label
                                htmlFor="state"
                                className="block text-sm font-medium text-gray-700"
                              >
                                State / Province
                              </label>
                              <select
                                name="state"
                                id="state"
                                value={address?.state}
                                onChange={onAddressChange}
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md"
                              >
                                {states.map((c) => (
                                  <option key={`sId-${c}`} value={c}>
                                    {c}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                              <label
                                htmlFor="postal_code"
                                className="block text-sm font-medium text-gray-700"
                              >
                                ZIP / Postal
                              </label>
                              <input
                                type="text"
                                name="zip"
                                id="postal_code"
                                value={address?.zip}
                                onChange={onAddressChange}
                                autoComplete="postal-code"
                                className="mt-1 focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="longitude"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Longitude
                              </label>
                              <input
                                type="text"
                                name="longitude"
                                placeholder="-122.2343234"
                                value={location.longitude}
                                onChange={(e) =>
                                  setLocation({
                                    ...location,
                                    longitude: e.target.value,
                                  })
                                }
                                id="longitude"
                                autoComplete="given-name"
                                className="mt-1 focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="latitude"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Latitude
                              </label>
                              <input
                                type="text"
                                name="latitude"
                                placeholder="45.2342323"
                                id="latitude"
                                value={location.latitude}
                                onChange={(e) =>
                                  setLocation({
                                    ...location,
                                    latitude: e.target.value,
                                  })
                                }
                                autoComplete="family-name"
                                className="mt-1 focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="bg-white shadow px-4 py-5 sm:rounded-lg my-5">
                      <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                          <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Contact
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            Decide which communications you&apos;d like to
                            receive and how.
                          </p>
                        </div>
                        <div className="mt-5 md:mt-0 md:col-span-2">
                          <fieldset>
                            <legend className="text-base font-medium text-gray-900">
                              Traditional
                            </legend>
                            <div className="mt-4 space-y-4">
                              <div className="grid grid-cols-3 gap-6">
                                <div className="col-span-3 sm:col-span-2">
                                  <label
                                    htmlFor="website"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Website
                                  </label>
                                  <div className="mt-1 flex rounded-md shadow-sm">
                                    {/* <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">https://</span> */}
                                    <input
                                      value={contact.website}
                                      onChange={onContactChange}
                                      type="text"
                                      name="website"
                                      id="website"
                                      className="focus:ring-gray-500 focus:border-gray-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                      placeholder="https://www.example.com/"
                                      pattern="https?://.+"
                                    />
                                  </div>
                                </div>
                                <div className="col-span-3 sm:col-span-2">
                                  <label
                                    htmlFor="phone"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Phone
                                  </label>
                                  <input
                                    type="text"
                                    value={contact.phone}
                                    onChange={onContactChange}
                                    name="phone"
                                    id="phone"
                                    autoComplete="false"
                                    placeholder="555-555-5555"
                                    className="mt-1 focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                  />
                                </div>
                                <div className="col-span-3 sm:col-span-2">
                                  <label
                                    htmlFor="email"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Email
                                  </label>
                                  <input
                                    type="text"
                                    value={contact.email}
                                    onChange={onContactChange}
                                    name="email"
                                    id="email"
                                    autoComplete="false"
                                    placeholder="user@domain.com"
                                    className="mt-1 focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                  />
                                </div>
                                <div className="col-span-3 sm:col-span-2">
                                <FeatureDisabled
                                  flag={featureLevels.RESERVATION_LINK}
                                  level={listing.featureLevel}
                                >
                                  <label
                                    htmlFor="email"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Reservation Link
                                  </label>
                              <div className="grid grid-cols-3 gap-6"></div>
                                  <div className="col-span-3 sm:col-span-2">
                                  <div className="mt-1 flex rounded-md shadow-sm">
                                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                                      <FontAwesomeIcon
                                        icon={icon.faCalendarAlt}
                                        className="w-4"
                                      />
                                    </span>
                                  <input
                                    type="text"
                                    value={contact.reservationUrl}
                                    onChange={onContactChange}
                                    name="reservationUrl"
                                    id="reservationUrl"
                                    autoComplete="false"
                                    placeholder="www.exploretock.com/"
                                    className="focus:ring-gray-500 focus:border-gray-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                  />
                                  </div>
                                  </div>
                                  </FeatureDisabled>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          <fieldset>
                            <legend className="text-base font-medium text-gray-900 pt-4">
                              Social
                            </legend>
                            <div className="mt-4 space-y-4">
                              <div className="grid grid-cols-3 gap-6">
                                <div className="col-span-3 sm:col-span-2">
                                  <div className="mt-1 flex rounded-md shadow-sm">
                                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                                      <FontAwesomeIcon
                                        icon={["fab", "facebook"]}
                                        className="w-4"
                                      />
                                    </span>
                                    <input
                                      type="text"
                                      name="facebook"
                                      id="facebook"
                                      value={contact.facebook}
                                      onChange={onContactChange}
                                      className="focus:ring-gray-500 focus:border-gray-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                      placeholder="https://www.facebook.com/"
                                      pattern="https?:\/\/www\.facebook\.com\/.+(?<!\/)$|https?:\/\/facebook\.com\/.+(?<!\/)$"
                                    />
                                  </div>
                                </div>
                                <div className="col-span-3 sm:col-span-2">
                                  <div className="mt-1 flex rounded-md shadow-sm">
                                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                                      <FontAwesomeIcon
                                        icon={["fab", "instagram"]}
                                        className="w-4"
                                      />
                                    </span>
                                    <input
                                      type="text"
                                      name="instagram"
                                      id="instagram"
                                      value={contact.instagram}
                                      onChange={onContactChange}
                                      className="focus:ring-gray-500 focus:border-gray-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                      placeholder="https://www.instagram.com/"
                                      pattern="https?:\/\/www\.instagram\.com\/.+(?<!\/)$|https?:\/\/instagram\.com\/.+(?<!\/)$"
                                    />
                                  </div>
                                </div>
                                <div className="col-span-3 sm:col-span-2">
                                  <div className="mt-1 flex rounded-md shadow-sm">
                                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                                      <FontAwesomeIcon
                                        icon={["fab", "snapchat"]}
                                        className="w-4"
                                      />
                                    </span>
                                    <input
                                      type="text"
                                      name="snapchat"
                                      id="snapchat"
                                      value={contact.snapchat}
                                      onChange={onContactChange}
                                      className="focus:ring-gray-500 focus:border-gray-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                      placeholder="https://www.snapchat.com/"
                                      pattern="https?:\/\/www\.snapchat\.com\/.+(?<!\/)$|https?:\/\/snapchat\.com\/.+(?<!\/)$"
                                    />
                                  </div>
                                </div>
                                <div className="col-span-3 sm:col-span-2">
                                  <div className="mt-1 flex rounded-md shadow-sm">
                                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                                      <FontAwesomeIcon
                                        icon={["fab", "twitter"]}
                                        className="w-4"
                                      />
                                    </span>
                                    <input
                                      type="text"
                                      name="twitter"
                                      id="twitter"
                                      value={contact.twitter}
                                      onChange={onContactChange}
                                      className="focus:ring-gray-500 focus:border-gray-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                      placeholder="https://www.twitter.com/"
                                      pattern="https?:\/\/www\.twitter\.com\/.+(?<!\/)$|https?:\/\/twitter\.com\/.+(?<!\/)$"
                                    />
                                  </div>
                                </div>
                                <div className="col-span-3 sm:col-span-2">
                                  <div className="mt-1 flex rounded-md shadow-sm">
                                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                                      <FontAwesomeIcon
                                        icon={["fab", "tiktok"]}
                                        className="w-4"
                                      />
                                    </span>
                                    <input
                                      type="text"
                                      name="tiktok"
                                      id="tiktok"
                                      value={contact.tiktok}
                                      onChange={onContactChange}
                                      className="focus:ring-gray-500 focus:border-gray-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border border-gray-300"
                                      placeholder="https://www.tiktok.com/"
                                      pattern="https?:\/\/www\.tiktok\.com\/.+(?<!\/)$|https?:\/\/tiktok\.com\/.+(?<!\/)$"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>

                    <div className="bg-white shadow px-4 py-5 sm:rounded-lg my-5">
                      <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                          <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Accommodations
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            What services are you currently offering.
                          </p>
                        </div>
                        <div className="mt-5 md:mt-0 md:col-span-2">
                          <fieldset>
                            <legend className="text-base font-medium text-gray-900">
                              Reservations
                            </legend>
                            <div className="mt-4 space-y-4">
                              {availability.map((s) => (
                                <div className="flex items-start" key={s.id}>
                                  <div className="h-5 flex items-center">
                                    <FontAwesomeIcon
                                      icon={icon[s.iconId]}
                                      className="w-4 fa-fw"
                                    />
                                    &nbsp;
                                    <input
                                      name={s.name}
                                      checked={accommodations.some(
                                        (e) => e.id === s.id
                                      )}
                                      onChange={(e) =>
                                        onAccommodationsChange(s, e)
                                      }
                                      type="checkbox"
                                      className="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded"
                                    />
                                  </div>
                                  <div className="ml-3 text-sm">
                                    <label
                                      htmlFor="comments"
                                      className="font-medium text-gray-700 capitalize"
                                    >
                                      {s.name}
                                    </label>
                                    {/* <p className="text-gray-500">Get notified when someones posts a comment on a posting.</p> */}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </fieldset>
                          <fieldset>
                            <legend className="text-base font-medium text-gray-900 pt-4">
                              Services
                            </legend>
                            <div className="mt-4 space-y-4">
                              {services.map((s) => (
                                <div className="flex items-start" key={s.id}>
                                  <div className="h-5 flex items-center">
                                    <FontAwesomeIcon
                                      icon={icon[s.iconId]}
                                      className="w-4 fa-fw"
                                    />
                                    &nbsp;
                                    <input
                                      name={s.name}
                                      checked={accommodations.some(
                                        (e) => e.id === s.id
                                      )}
                                      onChange={(e) =>
                                        onAccommodationsChange(s, e)
                                      }
                                      type="checkbox"
                                      className="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded"
                                    />
                                  </div>
                                  <div className="ml-3 text-sm">
                                    <label
                                      htmlFor="comments"
                                      className="font-medium text-gray-700 capitalize"
                                    >
                                      {s.name}
                                    </label>
                                    {/* <p className="text-gray-500">Get notified when someones posts a comment on a posting.</p> */}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </fieldset>
                          <fieldset>
                            <div>
                              <legend className="text-base font-medium text-gray-900 pt-4">
                                Health and Safety Measures
                              </legend>
                              <p className="text-sm text-gray-500">
                                What precautions are you taking to ensure the
                                safety of your visitors?
                              </p>
                            </div>
                            <div className="mt-4 space-y-4">
                              {safety.map((s) => (
                                <div className="flex items-start" key={s.id}>
                                  <div className="h-5 flex items-center">
                                    <FontAwesomeIcon
                                      icon={icon[s.iconId]}
                                      className="w-4 fa-fw"
                                    />
                                    &nbsp;
                                    <input
                                      name={s.name}
                                      checked={accommodations.some(
                                        (e) => e.id === s.id
                                      )}
                                      onChange={(e) =>
                                        onAccommodationsChange(s, e)
                                      }
                                      type="checkbox"
                                      className="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded"
                                    />
                                  </div>
                                  <div className="ml-3 text-sm">
                                    <label
                                      htmlFor="comments"
                                      className="font-medium text-gray-700 capitalize"
                                    >
                                      {s.name}
                                    </label>
                                    {/* <p className="text-gray-500">Get notified when someones posts a comment on a posting.</p> */}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </fieldset>

                          <fieldset>
                            <div>
                              <legend className="text-base font-medium text-gray-900 pt-4">
                                Certifications
                              </legend>
                              <p className="text-sm text-gray-500">
                                What certifications does your listing hold?
                              </p>
                            </div>
                            <div className="mt-4 space-y-4">
                              {certifications.map((s) => (
                                <div className="flex items-start" key={s.id}>
                                  <div className="h-5 flex items-center">
                                    <FontAwesomeIcon
                                      icon={icon[s.iconId]}
                                      className="w-4 fa-fw"
                                    />
                                    &nbsp;
                                    <input
                                      name={s.name}
                                      checked={accommodations.some(
                                        (e) => e.id === s.id
                                      )}
                                      onChange={(e) =>
                                        onAccommodationsChange(s, e)
                                      }
                                      type="checkbox"
                                      className="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded"
                                    />
                                  </div>
                                  <div className="ml-3 text-sm">
                                    <label
                                      htmlFor="comments"
                                      className="font-medium text-gray-700 capitalize"
                                    >
                                      {s.name}
                                    </label>
                                    {/* <p className="text-gray-500">Get notified when someones posts a comment on a posting.</p> */}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </fieldset>
                          
                        </div>
                      </div>
                    </div>

                    <div className="bg-white shadow px-4 py-5 sm:rounded-lg my-5">
                      <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                          <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Hours
                          </h3>
                          <p className="mt-1 text-xs pt-2 text-gray-500">
                            Business Availability:
                          </p>
                          <label className="flex items-start">
                            <div className="h-5 flex items-center">
                              <input
                                type="radio"
                                name="availability"
                                value="Normal"
                                checked={hours.status.value === "Normal"}
                                onChange={onSetSelectedStatus}
                                className="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded"
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <span className="font-medium text-gray-700 capitalize">
                                Operating Normally
                              </span>
                              <p className="text-gray-500">
                                Open with regular business hours.
                              </p>
                            </div>
                          </label>
                          <label className="flex items-start">
                            <div className="h-5 flex items-center">
                              <input
                                type="radio"
                                name="availability"
                                value="Temporarily Closed"
                                checked={
                                  hours.status.value === "Temporarily Closed"
                                }
                                onChange={onSetSelectedStatus}
                                className="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded"
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <span className="font-medium text-gray-700 capitalize">
                                Temporarily Closed
                              </span>
                              <p className="text-gray-500">
                                Closed but intends to open again in the future.
                              </p>
                            </div>
                          </label>
                          <label className="flex items-start">
                            <div className="h-5 flex items-center">
                              <input
                                type="radio"
                                name="availability"
                                value="Permanently Closed"
                                checked={
                                  hours.status.value === "Permanently Closed"
                                }
                                onChange={onSetSelectedStatus}
                                className="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded"
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <span className="font-medium text-gray-700 capitalize">
                                Permanently Closed
                              </span>
                              <p className="text-gray-500">
                                Closed and does not intend to have open hours in
                                the future.
                              </p>
                            </div>
                          </label>
                        </div>

                        <div className="mt-5 md:mt-0 md:col-span-2">
                          <div className="text-base font-medium text-gray-900 pt-4">
                            {hours.status.value}
                          </div>
                          {hours.status && hours.status.value !== "Normal" && (
                            <>
                              <p className="text-sm text-gray-500">
                                If you would like to update hours, select
                                Operating Normally.
                              </p>
                              <div className="mt-1">
                                <Field
                                  as="textarea"
                                  id="statusMessage"
                                  name="statusMessage"
                                  className="shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                                />
                              </div>
                              <p className="mt-2 text-sm text-gray-500">
                                Leave a short message for your customers on your
                                closed status. No hyperlinks.
                              </p>
                            </>
                          )}
                          {hours.status && hours.status.value === "Normal" && (
                            <>
                              <div className="gap-x-10 pb-4 border-b-2">
                                <div className="">Set Hours for All Days</div>
                                <div className="lg:flex items-center gap-5 flex-wrap">
                                  <HoursControl
                                    onChange={(d) => setAllHours(d)}
                                    value={allHours}
                                  />
                                  <button
                                    type="button"
                                    className="inline-flex items-center w-36 h-10 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-900"
                                    onClick={() => onSetAllHours()}
                                  >
                                    Set All Hours
                                  </button>
                                </div>
                              </div>
                              <div className="mt-5 md:mt-0 md:col-span-2">
                                <div className="">
                                  Set Hours for Each Day Independently
                                </div>
                                {daysArray.map((dayIndex) => (
                                  <div
                                    key={dayIndex}
                                    className="grid grid-cols-6 gap-x-10 pb-4 border-b-2"
                                  >
                                    <div className="col-span-6">
                                      {dayLabels[dayIndex]}
                                      <button
                                        className="bg-white px-2 pb-1 mx-2 border border-gray-300 rounded-md shadow-sm text-lg font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                        type="button"
                                        onClick={() =>
                                          addNewHoursToDay(dayIndex)
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                    <div className="">
                                      {(!hours ||
                                        !hours.days ||
                                        !hours.days[dayIndex] ||
                                        hours.days[dayIndex].hours.length ===
                                          0) && <div>Closed</div>}
                                      {hours &&
                                        hours.days &&
                                        hours.days[dayIndex] &&
                                        hours.days[dayIndex].hours &&
                                        hours.days[dayIndex].hours.map(
                                          (hour, hourIndex) => (
                                            <div
                                              key={hourIndex}
                                              className="lg:flex items-center gap-5"
                                            >
                                              <HoursControl
                                                onChange={(d) =>
                                                  onHoursChange(
                                                    dayIndex,
                                                    hourIndex,
                                                    d
                                                  )
                                                }
                                                value={
                                                  hours.days[dayIndex].hours[
                                                    hourIndex
                                                  ]
                                                }
                                              />
                                              <button
                                                className="bg-white px-3 pb-1 border border-gray-300 rounded-md shadow-sm text-lg font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                type="button"
                                                onClick={() =>
                                                  removeHoursFromDay(
                                                    dayIndex,
                                                    hourIndex
                                                  )
                                                }
                                              >
                                                -
                                              </button>
                                            </div>
                                          )
                                        )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {saveToolBar()}
              </div>
            </Form>
          </Formik>
        </div>
      ) : (
        <div>Loading Listing</div>
      )}
    </Page>
  );
}
