import React from 'react';
import Page from '../../components/Page';
import { Field, Form, Formik } from 'formik';
import client from "../../lib/feathers";

export default function Notifications() {

  const onFormSubmit = async (values) => {
    const service = client.service('admin/notify');
    const result = await service.create(values);
    console.log('notified', result);
  }
  return (
    <Page title="Notifications">
      <Formik
            initialValues={{
              title: '',
              message: '',
            }}
            onSubmit={onFormSubmit}
          >
            <Form>
                <h1>Send a Message to subscribers</h1>
                <label htmlFor='title'>Subject</label>
                <Field id="title" name="title" className="px-4 py-2 shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border border-gray-300 rounded-md" />
                <label htmlFor='message'>Message</label>
                <Field as="textarea" id="message" name="message" rows="3" className="shadow-sm mt-2 focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border border-gray-300 rounded-md" />
                <button type="submit" className="w-full inline-flex mt-2 justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:col-start-2 sm:text-sm">Create</button>
            </Form>
          </Formik>
    </Page>
  );
}
