import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast, { Toaster } from 'react-hot-toast';
import { formatIcon } from '../../../../utilities/formatters';
import Page from '../../../../components/Page';
import PrimaryButton from '../../../../components/PrimaryButton';
import Breadcrumb from '../../../../components/Breadcrumb';
import client from '../../../../lib/feathers';

export default function AccommodationsForm() {
  const { aid } = useParams();
  const [accommodation, setAccommodation] = useState();
  const [name, setName] = useState({});
  const [type, setType] = useState({});
  const [iconId, setIconId] = useState({});
  const navigate = useNavigate();

  const getAccommodation = async (uuid) => {
    try {
      const service = client.service('admin/accommodation');

      const result = await service.find({
        query: {
          id: {
            $in: [uuid],
          },
        },
      });
      const d = result.data[0];
      setName(d.name);
      setType(d.type);
      setIconId(d.iconId);
      setAccommodation(d);
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    if (aid) {
      getAccommodation(aid);
    }
  }, [aid]);

  const onFormSubmit = async (event) => {
    event.preventDefault();
    const service = client.service('admin/accommodation');
    const formattedIcon = formatIcon(iconId);
    if (!icons[formattedIcon]) {
      toast.error('invalid Icon');
      return;
    }
    const data = {
      ...accommodation, name, type, iconId: formattedIcon,
    };
    try {
      const result = await service.update(accommodation.id, data);
      console.log('result updated', result);
      toast.success('Saved!');
      getAccommodation(aid);
    } catch (e) {
      console.log('error updating accommodation', e);
      toast.error('Something went wrong creating the new listing.');
    }
  };

  const deleteAccommodation = async () => {
    const service = client.service('admin/accommodation');
    try {
      await service.remove(aid);
      toast.success('Deleted');
      navigate('/admin/accommodations');
    } catch (e) {
      console.log('error deleting accommodation', e);
      toast.error('Something went wrong deleting the listing.');
    }
  };

  const saveToolBar = () => (
    <div className="flex justify-between py-4">
      <div>
        <PrimaryButton onClick={() => deleteAccommodation()} type="button">
          Delete
        </PrimaryButton>
      </div>
      <div className="flex justify-end">
        <Link to="/admin/accommodations" type="button" className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
          Cancel
        </Link>
        <PrimaryButton type="submit">
          Save
        </PrimaryButton>
      </div>
    </div>
  );

  return (
    <Page>
      <Toaster
        toastOptions={{
          duration: 3000,
        }}
      />
      <div>
        {accommodation ? (
          <div>
            <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
              <nav className="flex" aria-label="Breadcrumb">
                <Breadcrumb elements={[{ name: 'Accommodations', href: '/admin/accommodations' }, { name: 'Edit Accommodation' }]} />
              </nav>
            </div>
            <form onSubmit={onFormSubmit}>
              {saveToolBar()}
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
              <input type="text" value={name} onChange={(e) => setName(e.target.value)} required name="name" id="name" autoComplete="false" className="mt-1 focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
              <label htmlFor="type" className="block text-sm font-medium text-gray-700">Type</label>
              <select type="text" value={type} onChange={(e) => setType(e.target.value)} required name="type" id="type" className="mt-1 focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                <option disabled>Please Select</option>
                <option value="availability">Availability</option>
                <option value="safety">Safety</option>
                <option value="services">Services</option>
                <option value="certifications">Certifications</option>
              </select>
              <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                Icon &nbsp;
                { icons[formatIcon(iconId)] ? <FontAwesomeIcon icon={icons[formatIcon(iconId)]} /> : 'invalid icon' }
              </label>
              <input type="text" value={iconId} onChange={(e) => setIconId(e.target.value)} required name="icon" id="icon" autoComplete="false" className="mt-1 focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
            </form>
          </div>
        ) : <div>Loading Listing</div> }
      </div>
    </Page>
  );
}
