import React from 'react';

export default function Modal(props) {
  const { children } = props;
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>

        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 my-8 text-left overflow-visible shadow-xl transform transition-all sm:align-middle sm:max-w-md sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          {children}
        </div>
      </div>
    </div>
  );
}
