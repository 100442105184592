import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../../utilities/classNames';

// Stat cards with icons
export function IconCard({ item }) {
  const change = item.currentStat - item.previousStat;
  const changeType = change > 0 ? 'increase' : change === 0 ? 'none' : 'decrease';

  return (
    <div
      className="relative overflow-hidden rounded-lg bg-white px-4 py-5 border-2 sm:px-6 sm:pt-6"
    >
      <dt>
        <div className="absolute rounded-xl bg-gray-100 p-3">
          <item.icon className="h-6 w-6 text-primary" aria-hidden="true" />
        </div>
        <p className="ml-16 truncate text-sm font-medium text-gray-500">
          {item.name}
        </p>
      </dt>
      <dd className={"flex ml-16 items-baseline justify-between md:block lg:flex"}>
        <div className="flex items-baseline text-2xl font-semibold text-black">
          <p className='text-2xl font-semibold text-gray-900'>
            {`${item.currentStat}`.padStart(2, item.currentStat > 0 ? '0' : '')}
          </p>
          <span className="ml-2 text-sm font-medium text-gray-400 italic">
            from {item.previousStat}
          </span>
        </div>
        <div
          className={classNames(
            changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
            'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0'
          )}
        >
          {changeType === 'increase' && (
            <ArrowUpIcon
              className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
              aria-hidden="true"
            />
          )} 
          {changeType === 'decrease' && (
            <ArrowDownIcon
              className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
              aria-hidden="true"
            />
          )}
          <span className="sr-only"> {changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
          {change}
        </div>
      </dd>
    </div>
  );
}

// Shared border stat cards
export function SBCard({ item }) {
  return (
    <div className="flex flex-col justify-between px-4 py-5 sm:p-6">
      <dt className="flex text-base font-medium text-gray-800">
        <item.icon className="mr-2 mt-0.5 h-5 w-5 self-start text-primary flex-shrink-0" aria-hidden="true" />
        Total {item.name}
      </dt>
      <dd className="mt-1 md:block">
        <div className="text-3xl font-semibold text-red-900">
          {`${item.totalStat}`.padStart(2, item.totalStat > 0 ? '0' : '')}
        </div>
      </dd>
    </div>
  );
}
