import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import md5 from 'md5';
import SidebarLink from './SidebarLink';
import client from '../lib/feathers';
import pjson from '../../package.json';

import { setUser } from '../lib/authSlice';

export default function Sidebar(props) {
  const { user } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const gravatarLink = `http://www.gravatar.com/avatar/${md5(user.email)}?s=32`;

  const onLogout = async () => {
    try {
      await client.logout();
      // console.log('logout', auth);
      dispatch(setUser(undefined));
      navigate('/');
    } catch (e) {
      console.log('cant logout', e);
    }
  };

  return (
    <>
      <div className="flex flex-col h-0 flex-1 bg-primary">
        <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
          <div className="items-center flex-shrink-0 px-4 text-white text-2xl">
            <img src="https://assets.vinoseeker.com/site-images/Horizontal-Seeker-Logo-Whilte-Border.png" alt="VinoSeeker Admin" />
            Business Admin

            <div className="text-sm">
              v
              {pjson.version}
            </div>
          </div>
          <nav className="mt-5 flex-1 px-2 bg-primary space-y-1">
            {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}

            {/* <!-- Current: "text-gray-300", Default: "text-gray-400 group-hover:text-gray-300" --> */}

            <SidebarLink href="/admin/">
              {/* <!-- Heroicon name: outline/home --> */}
              <svg className="text-gray-300 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
              </svg>
              Dashboard
            </SidebarLink>

            <SidebarLink href="/admin/listings">

              <svg className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              Listings
            </SidebarLink>

            <SidebarLink href="/admin/users">
              <svg className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
              Users
            </SidebarLink>

            <SidebarLink href="/admin/suggestions">
              {/* <!-- Heroicon name: outline/inbox --> */}
              <svg className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4" />
              </svg>
              Suggestions
            </SidebarLink>

            <SidebarLink href="/admin/reports">
              {/* <!-- Heroicon name: outline/chart-bar --> */}
              <svg className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
              </svg>
              Reports
            </SidebarLink>
            <SidebarLink href="/admin/accommodations">
              {/* <!-- Heroicon name: outline/chart-bar --> */}
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
              </svg>
              Accommodations
            </SidebarLink>
            <SidebarLink href="/admin/notifications">
              {/* <!-- Heroicon name: outline/chart-bar --> */}
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
              </svg>
              Notifications
            </SidebarLink>
            <SidebarLink href="/admin/qrcode">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 3.75 9.375v-4.5ZM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 0 1-1.125-1.125v-4.5ZM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 13.5 9.375v-4.5Z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 6.75h.75v.75h-.75v-.75ZM6.75 16.5h.75v.75h-.75v-.75ZM16.5 6.75h.75v.75h-.75v-.75ZM13.5 13.5h.75v.75h-.75v-.75ZM13.5 19.5h.75v.75h-.75v-.75ZM19.5 13.5h.75v.75h-.75v-.75ZM19.5 19.5h.75v.75h-.75v-.75ZM16.5 16.5h.75v.75h-.75v-.75Z" />
              </svg>

              QR Codes
            </SidebarLink>
          </nav>
        </div>
        <div className="flex-shrink-0 flex bg-gray-700 p-4">
          <a href="#" className="flex-shrink-0 w-full group block">
            <div className="flex items-center">
              <div>
                <img className="inline-block h-9 w-9 rounded-full" src={gravatarLink} alt="User Profile Icon" />
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-white">{user.email}</p>
                <button type="button" onClick={onLogout} className="text-xs font-medium text-gray-300 group-hover:text-gray-200">
                  Log Out
                </button>
              </div>
            </div>
          </a>
        </div>
      </div>
      {/* <div className="fixed z-20 inset-0 bg-black opacity-50 transition-opacity lg:hidden"></div>

        <div className={"fixed z-30 inset-y-0 left-0 w-60 transition duration-300 transform bg-white dark:bg-gray-900 overflow-y-auto lg:translate-x-0 lg:static lg:inset-0"}>
            <div className="flex items-center justify-center mt-8">
                <div className="flex items-center">
                    <span className="text-gray-800 dark:text-white text-2xl font-semibold">Wyno Admin</span>
                </div>
            </div>

            <nav className="flex flex-col mt-10 px-4 text-center">
                <SidebarLink href="/dashboard">Dashboard</SidebarLink>
                <SidebarLink href="/listings">Listings</SidebarLink>
                <SidebarLink href="/venues">Venues</SidebarLink>
                <SidebarLink href="/users">Users</SidebarLink>
                <SidebarLink href="/suggestions">Suggestions</SidebarLink>
            </nav>
        </div> */}
    </>
  );
}
