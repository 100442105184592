import { Formik, Field, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import client from '../lib/feathers';
import Select from 'react-select';
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { setCategories } from '../lib/listingsSlice';

export default function NewListingForm(props) {
  const { onCancelClick, onNewListing } = props;

  const categories = useSelector((state) => state.listings.categories); // list data
  const dispatch = useDispatch();

  const [categoryListings, setCategoryListings] = useState([]); // selected data in form

  const getCategories = async () => {
    try {
      const service = client.service('admin/category');
      const query = {
        $sort: {
          createdAt: -1,
        },
      };

      const result = await service.find({
        query,
      });

      const cats = result.data.map((c) => ({ ...c, label: c.name, value: c.id }))

      dispatch(setCategories(cats));
    } catch (e) {
      console.log('error', e);
    }
  };
  
  const onCategoryListingsChange = (e) => {
    const cl = e.map((option) => {
      if (option.id) {
        return option;
      } else {
        // lookup from categories by id == option.value
        categories.find((c) => c.id == option.value);
        return categories.find((c) => c.id == option.value);
      }
    })
    setCategoryListings(cl);
  };

  const onFormSubmit = async (values) => {
    const service = client.service('admin/listing');
    const data = {
      ...values, categories: categoryListings
    };
    try {
      const result = await service.create(data);
      console.log('newListing', result);
      onNewListing(result);
      toast.success('Created!');
    } catch (e) {
      toast.error('Something went wrong creating the new listing.');
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <div className="mt-3 text-center sm:mt-5">
        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
          Create New Listing
        </h3>
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            Enter the name for this listing.
          </p>
          <Formik
            initialValues={{
              name: '',
              //categories: '',
              venueId: '',
            }}
            onSubmit={onFormSubmit}
          >
            <Form>
              <div className="text-left">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <div className="mt-1">
                  <Field id="name" name="name" type="text" autoComplete="off" required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm" />
                </div>
              </div>

              <div className="text-left">
                {(categories && categories.length > 0)
                  ? (
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="business_type" className="block text-sm font-medium text-gray-700">Business Type {categoryListings.length}</label>
                        <Select
                          defaultValue={categoryListings}
                          name="categories"
                          options={categories}
                          isMulti
                          onChange={onCategoryListingsChange}
                        />
                    </div>
                  )
                  : <div>Loading Categories</div>}
              </div>

              <div className="text-left">
                <label htmlFor="venueId" className="block text-sm font-medium text-gray-700">
                  VenueId (if applicable)
                </label>
                <div className="mt-1">
                  <Field id="venueId" name="venueId" type="text" autoComplete="off" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm" />
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:col-start-2 sm:text-sm">Create</button>
                <button type="button" onClick={onCancelClick} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:col-start-1 sm:text-sm">Cancel</button>
              </div>
            </Form>
          </Formik>

        </div>
      </div>

    </>
  );
}
