import React, { useEffect, useState, useCallback } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup";
import Select from "react-select";

// Markdown Editor
import {
  MDXEditor,
  UndoRedo,
  BoldItalicUnderlineToggles,
  toolbarPlugin,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import client from '../../../lib/feathers';
import Modal from '../../Modal';
import Page from '../../Page';
import PrimaryButton from '../../PrimaryButton';
import ItineraryListingList from './ItineraryListingList';


const schema = yup.object({
  name: yup.string().required("Please enter a name for this itinerary"),
}).required();

export default function ItineraryForm() {
  const { lid, iid } = useParams();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);

  const [currentItinerary, setCurrentItinerary] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [isLoading, setIsLoading] = useState();

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const loadItinerary = async () => {
    setIsLoading(true);
    const service = client.service(`${user.role}/listing-list`);
    const response = await service.get(iid);

    setCurrentItinerary(response);
    setValue('name', response.name);
    setValue('description', response.description);
    setValue('access', response.access);

    setIsLoading(false);
  }

  useEffect(() => {
    if (user) {
      loadItinerary();
    }
  }, [iid, user]);

  const handleUpdateItinerary = async (data) => {
    try {
      // service to update the itinerary itself
      const service = client.service(`${user.role}/listing-list`)
      await service.patch(currentItinerary.id, { ...data });
      navigate(`/${user.role}/listings/${lid}/itineraries`);
    } catch (error) {
    }
  }

  const handleDeleteItinerary = async () => {
    const service = client.service(`${user.role}/listing-list`);
    await service.remove(iid);
    navigate(`/${user.role}/listings/${lid}/itineraries`);
  };

  // Tailwind Styles //
  const errorFocus = 'border-red-400 focus:border-red-500 focus:ring-red-500';
  const nonErrorFocus = 'border-gray-300 focus:ring-gray-500 focus:border-gray-500';

  let accessOptions = [
    { label: "Public", value: "public" },
    { label: "Archived", value: "unlisted" },
  ];

  const SaveToolBar = () => (
    <div className="flex justify-between pt-5">
      <PrimaryButton onClick={() => setShowDeleteModal(true)}>Delete</PrimaryButton>
      <div>
        <Link
          to={`/${user?.role}/listings/${lid}/itineraries`}
          type="button"
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        >
          Cancel
        </Link>
        <PrimaryButton type="submit">Save</PrimaryButton>
      </div>
    </div>
  );

  return (
    <Page>
      {/* BREADCRUMBS */}
      {!isLoading ? (
        <>

          <form onSubmit={handleSubmit(handleUpdateItinerary)}>
            <SaveToolBar />

            {/* CARD 1 */}
            <div className="bg-white shadow px-4 py-5 sm:rounded-lg my-5">
              {/* GRID */}
              <div className="md:grid md:grid-cols-3 md:gap-6">
                {/* COL 1 */}
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900 inline-block align-middle mr-2">
                    Itinerary Information
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">Update the information for {currentItinerary ? ` ${currentItinerary.name}` : ' this itinerary.'}</p>
                  <br />
                  {currentItinerary && (
                    <>
                      <dl className="whitespace-nowrap pb-1 py-1 text-sm font-medium text-gray-900 flex relative items-center">
                        <span className="flex items-center text-sm font-medium relative">
                          {currentItinerary.createdBy !== null && (
                            <span>
                              <span className="text-black font-medium">Added By: </span>
                              <span className="text-sm text-gray-500">
                                {currentItinerary.user.firstName} {currentItinerary.user.lastName}
                                <br />
                                {currentItinerary.createdBy?.email}
                              </span>
                            </span>
                          )}
                        </span>
                      </dl>
                      <dd className="whitespace-nowrap py-1 text-sm text-gray-500">
                        {currentItinerary.createdAt && (
                          <span><span className="text-black font-medium">Added On: </span>{new Date(currentItinerary.createdAt).toDateString()}</span>
                        )}
                      </dd>
                      <dd className="whitespace-nowrap py-5 text-sm text-gray-500" >
                        <label htmlFor="access" className="block text-sm font-medium text-gray-700">
                          Status
                        </label>
                        <Controller
                          name="access"
                          control={control}
                          render={({ field: { onChange, value, ref } }) => (
                            <Select
                              ref={ref}
                              options={accessOptions}
                              value={accessOptions.find((s) => s.value === value)}
                              onChange={(val) => onChange(val.value)}
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  width: '50%',
                                }),
                              }}
                            />
                          )}
                        />
                      </dd>
                    </>
                  )}
                </div>

                {/* COL 2 */}
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6">
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        {...register("name")}
                        className={`mt-1  block w-full shadow-sm sm:text-sm ${errors.name ? errorFocus : nonErrorFocus} rounded-md`}
                      />
                      {errors.name && <span className="text-red-400">{errors.name.message}</span>}
                    </div>
                    {/* MDX toolbar z index changed to avoid overlap with search menu */}
                    <div className="col-span-6 z-0">
                      <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                        Description
                      </label>
                      <Controller
                        name="description"
                        control={control}
                        render={({ field: { onChange, value, ref } }) => (
                          <MDXEditor
                            ref={ref}
                            markdown={value || ''}
                            onChange={onChange}
                            className="border rounded-lg mt-1 h-52 overflow-y-auto [&_li]:ml-4 [&_ul]:list-disc [&_ol]:list-decimal"
                            plugins={
                              [
                                listsPlugin(),
                                markdownShortcutPlugin(),
                                toolbarPlugin({
                                  toolbarContents: () => (
                                    <>
                                      {' '}
                                      <UndoRedo />
                                      <BoldItalicUnderlineToggles />
                                      <ListsToggle />
                                    </>
                                  )
                                })
                              ]}
                          />
                        )}
                      />
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </form>

          {/* CARD 2 - Itinerary Builder*/}
          <ItineraryListingList currentItinerary={currentItinerary} />
        </>
      ) : (
        <p>Loading Itinerary...</p>
      )}

      {showDeleteModal
        && (
          <Modal>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                Confirm Deletion
              </h3>
              <div className="mt-2">
                <p className="text-base text-gray-500">
                  Are you sure you want to delete this itinerary?
                </p>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button type="button" onClick={handleDeleteItinerary} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-icon text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 hover:bg-red-700 sm:col-start-2 sm:text-sm">Confirm</button>
              <button type="button" onClick={() => setShowDeleteModal(false)} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:col-start-1 sm:text-sm">Cancel</button>
            </div>
          </Modal>
        )}
    </Page>
  )
}

