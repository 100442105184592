import React, { useState } from 'react';
import Page from '../../components/Page';
import { Field, Form, Formik } from 'formik';
import client from "../../lib/feathers";

export default function QRCode() {
  const [code, setCode] = useState();

  const onFormSubmit = async (values) => {
    const service = client.service('admin/qr-code');
    setCode();
    const result = await service.create(values);
    setCode(result);
  }
  return (
    <Page title="QR Code Generator">
      <Formik
            initialValues={{
              url: '',
            }}
            onSubmit={onFormSubmit}
          >
            <Form>
                <h1>Create QR Code</h1>
                <label htmlFor='url'>Url</label>
                <Field id="url" name="url" className="px-4 py-2 shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border border-gray-300 rounded-md" />

                <button type="submit" className="w-full inline-flex mt-2 justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:col-start-2 sm:text-sm">Create</button>
            </Form>
          </Formik>
          <div className='m-5 p-5'>
            {code && <img src={code} />}
          </div>
    </Page>
  );
}
