import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import client from '../../../lib/feathers';
import EntitySearchForm from '../../EntitySearchForm';
import Pagination from '../../Pagination';
import PrimaryButton from '../../PrimaryButton';
import NewLibationForm from './NewLibationForm';
import Modal from '../../Modal';

export default function LibationsInfoPanel() {
  const { lid } = useParams();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);

  const [libations, setLibations] = useState();
  const [showCreateModal, setShowCreateModal] = useState(false);

  // Pagination State:
  const LIMIT = 25;
  const [query] = useSearchParams();
  const [page, setPage] = useState(query.get('page') || 0);
  const [total, setTotal] = useState(0);
  const [keywords, setKeywords] = useState(query.keywords || '');

  const loadLibations = async () => {
    const service = client.service(`${user.role}/libation`);

    const _query = {
      listingId: lid,
      $limit: LIMIT,
      $skip: page * LIMIT,
      $sort: {
        createdAt: -1,
      },
    };

    if (keywords && keywords.length) {
      _query.name = { $iLike: `%${keywords}%` };
    }

    const response = await service.find({ query: _query });
    setLibations(response.data);
    setTotal(response.total);
  };

  const onSearch = (e) => {
    e.preventDefault();
    setPage(0);
    loadLibations();
  }; 

  const navigateToPage = (p = 0, kw) => {
    if (p * LIMIT > total || p * LIMIT < 0) return;
    let url = `/${user.role}/listings/${lid}/wines?page=${p}`;
    if (kw) {
      url += `&keywords=${kw}`;
    }
    navigate(url);
    setPage(p);
  };

  useEffect(() => {
    if (lid && user) {
      loadLibations();
    }
  }, [lid, user, page]);

  const StatusChip = ({ status }) => {
    return (
      status === 'active' ?
        <span className={`inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20`}>Active</span>
        : status === 'inactive' ?
          <span className={`inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20`}>Inactive</span>
          : status === 'library' ?
            <span className={`inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-600/20`}>Library</span>
            : status === 'member' ?
              <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">Member</span>
              : null
    )
  };

  return (
    <div className="bg-white shadow overflow-hidden px-5 py-5 sm:rounded-lg sm:p-6">
      {/* WINE LIST */}
      <div className="sm:items-center py-2">
        <div className="sm:flex sm:items-center py-2">
          <div className="sm:flex-auto">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Wine List</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Here's a list of all your wines, including user submitted wines that need review.
            </p>
          </div>
        </div>
        {/* SEARCH / PAGE BAR */}
        <div className="pb-5 border-gray-200 sm:flex sm:items-center sm:justify-between">
          <div className="flex items-center">
            <EntitySearchForm onSearch={onSearch} setKeywords={setKeywords} keywords={keywords} />
            <p className="text-sm font-medium leading-6 text-gray-900 ml-2">
              {`(${total}) Total Wines`}
            </p>
          </div>
          <div className="mt-3 sm:mt-0 sm:ml-4">
            <button className="items-center px-4 py-2 my-1 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-icon hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500" onClick={() => setShowCreateModal(true)}>Create New Wine</button>
          </div>
        </div>
        <div>
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3 lg:table-cell">
                      Name
                    </th>
                    <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                      Vintage
                    </th>
                    <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                      Varietal
                    </th>
                    <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                      Type
                    </th>
                    <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 xl:table-cell">
                      Date Created
                    </th>
                    <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 xl:table-cell">
                      Status
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                      <span className="sr-only">Manage</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {libations?.map((libation, libationIndex) => (
                    <tr key={libationIndex} className={libationIndex % 2 === 0 ? undefined : 'bg-gray-50'}>
                      <td>
                        <dl className="whitespace-nowrap py-1 pl-3 pr-3 text-sm font-medium text-gray-900 flex relative items-center">
                          <span className="flex items-center text-sm font-medium relative">
                            <div className="group">
                              {libation.needsReview && (
                                <>
                                  <span className="mr-1 flex w-2.5 h-2.5 bg-yellow-500 rounded-full me-1.5 flex-shrink-0"></span>
                                  <span className="hidden absolute z-10 -top-6 rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20 group-hover:block">
                                    This wine needs to be reviewed
                                  </span>
                                </>
                              )
                              }
                            </div>
                            <span className="text-base lg:text-sm">{libation.name}</span>
                          </span>
                        </dl>
                        {libation.vintage && (
                          <dd className="whitespace-nowrap px-3 py-1 text-sm text-gray-500 lg:hidden">
                            <span>{libation.vintage} {libation.varietal?.name}</span>
                          </dd>
                        )}
                        {/* {libation.varietal && (
                          <dd className="whitespace-nowrap px-3 py-1 text-sm text-gray-500 lg:hidden">
                            <span><span className="text-black font-medium">Varietal : </span>{libation.varietal.name}</span>
                          </dd>
                        )} */}
                        {libation.libationType && (
                          <dd className="whitespace-nowrap px-3 py-1 text-sm text-gray-500 lg:hidden">
                            <span>({libation.libationType.name})</span>
                          </dd>
                        )}
                        <dd className="whitespace-nowrap px-3 py-1 text-sm font-medium xl:hidden"> Created : <span className="text-gray-500 font-normal">{new Date(libation.createdAt).toDateString()}</span></dd>
                        <dd className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 xl:hidden" >
                          <StatusChip status={libation.status} />
                        </dd>
                      </td>
                      <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                        {libation.vintage}
                      </td>
                      <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                        {libation.varietal?.name}
                      </td>
                      <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                        {libation.libationType?.name}
                      </td>
                      <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 xl:table-cell">{new Date(libation.createdAt).toDateString()}</td>
                      {/* <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">"Status"</td> */}
                      <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 xl:table-cell">
                        <StatusChip status={libation.status} />
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                        <div className="flex-row lg:flex lg:justify-between">
                          <button className="items-center px-4 py-2 my-1 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-icon hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500" onClick={() => navigate(`/${user.role}/listings/${lid}/wines/${libation.id}/edit`)}>Edit</button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

        </div >
      </div >

      <div className="mt-3 sm:mt-0 sm:ml-4">
        <Pagination total={total} limit={LIMIT} page={page} navigateToPage={navigateToPage} />
      </div>

      {showCreateModal
        && (
          <Modal>
            <NewLibationForm onCancelClick={() => setShowCreateModal(false)} />
          </Modal>
        )}
    </div >
  );
}