import React from 'react';
import Page from '../../components/Page';
import Breadcrumb from '../../components/Breadcrumb';
import { faBuilding, faCheck, faClock, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Reports() {

  const actions = [
    {
      title: 'No Result Queries',
      href: './reports/no-results-search',
      icon: faClock,
      iconForeground: 'text-teal-700',
      iconBackground: 'bg-teal-50',
      description: 'User searches that returned no results',
    },
    {
      title: 'Wines Needing Review',
      href: './reports/wines-needing-review',
      icon: faCheck,
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50',
      description: 'Wines entered by users which require review by the winery',
    },
    {
      title: 'Libations w/o Matching Listing',
      href: './reports/no-listing-libations',
      icon: faBuilding,
      iconForeground: 'text-red-900',
      iconBackground: 'bg-red-50',
      description: 'Wines entered by users which do not have a Listing',
    },
    {
      title: 'Pending User Invitations',
      href: '#',
      icon: faUser,
      iconForeground: 'text-red-900',
      iconBackground: 'bg-red-50',
      description: 'Users who have requested access to a Listing',
    },

  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <Page title="Reports">
      <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">

        <nav className="flex" aria-label="Breadcrumb">
          <Breadcrumb elements={[{ name: 'Reports' }]} />
        </nav>

      </div>

      <div className="py-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
        <div className="mt-3 sm:mt-0 sm:ml-4">
          <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
            {actions.map((action, actionIdx) => (
              <div
                key={action.title}
                className={classNames(
                  actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                  actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                  actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                  actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                  'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
                )}
              >
                <div>
                  <span
                    className={classNames(
                      action.iconBackground,
                      action.iconForeground,
                      'inline-flex rounded-lg p-3 ring-4 ring-white'
                    )}
                  >
                    {/* <action.icon className="h-6 w-6" aria-hidden="true" /> */}
                    <FontAwesomeIcon
                      icon={action.icon}
                      className="w-4 fa-fw mr-2"
                    />
                  </span>
                </div>
                <div className="mt-8">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    <a href={action.href} className="focus:outline-none">
                      {/* Extend touch target to entire panel */}
                      <span className="absolute inset-0" aria-hidden="true" />
                      {action.title}
                    </a>
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    {action.description}
                  </p>
                </div>
                <span
                  className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
                  aria-hidden="true"
                >
                  <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                  </svg>
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>


    </Page >
  );
}
