import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

export default function SidebarLink({ children, href }) {
  const location = useLocation();
  // console.log('location', location);

  const nonActiveClasses = 'text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md';
  const activeClasses = 'bg-gray-900 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md';
  return (
    <NavLink to={href}>
      {({ isActive, isPending, isTransitioning }) => (
        <div className={isActive ? activeClasses : nonActiveClasses}>
          &nbsp;
          {children}
        </div>
      )}
    </NavLink>
  );
}
