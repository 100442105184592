import { classNames } from "../utilities/classNames";

export default function FeatureDisabled(props) {
    const { flag, level, className, children } = props;
    // console.log('feature disable', flag, level, flag & level, (flag & level) == flag);
    if ((flag & level) === flag) {
        return children;
    } else {
        return (
            <div className={classNames("feature-disabled relative my-2 py-2 rounded-md overflow-hidden", className)}>
                <div className="blur-sm">
                    {children}
                </div>
                <div className="w-full h-full absolute bg-slate-300/75 white text-center font-bold inset-0 flex flex-col justify-center items-center">
                    <p>This feature is only available for upgraded listings.</p>
                    <p>Please contact <a href="mailto:service@vinoseeker.com" className="underline">service@vinoseeker.com</a> to find out more.</p>
                </div>
            </div>
        )
    }
}