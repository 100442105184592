import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
// Markdown Editor
import {
  MDXEditor,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import PrimaryButton from "../../PrimaryButton";
import FeatureDisabled from '../../FeatureDisabled';
import featureLevels from "../../../lib/featureLevels";

export default function PromotionsForm(props) {
  const {
    listing,
    property,
    formTitle,
    formSubtitle,
    _featureLevel,
    plugins,
    handleUpdate,
  } = props;

  const promotionObj = listing.promotions ?? {};

  const [editForm, setEditForm] = useState(false);
  const openEditForm = () => setEditForm(true);
  const closeEditForm = () => setEditForm(false);

  const {
    handleSubmit,
    control,
    register,
  } = useForm({
    defaultValues: {
      description: promotionObj[property]?.description,
      title: promotionObj[property]?.title,
    }
  });

  const handleUpdatePromotion = async (data) => {
    const newPromos =
    {
      promotions: {
        ...listing.promotions,
        [property]: {
          ...data,
        },
      },
    };
    await handleUpdate(newPromos);
    closeEditForm();
  };

  return (
    <form onSubmit={handleSubmit(handleUpdatePromotion)}>
      {/* MDX toolbar z index changed to avoid overlap with search menus */}
      <div className="grid grid-cols-6 gap-6 z-0">
        <div className="col-span-6">
          <label htmlFor="message" className="block text-sm font-medium text-gray-700">
            {formTitle}
          </label>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            {formSubtitle}
          </p>
          <FeatureDisabled flag={featureLevels[_featureLevel]} level={listing.featureLevel}>
            {!editForm && (
              <>
                <div className="mt-2">
                  <h2 className="pl-1">
                    Title
                  </h2>
                  <p className="mt-1 py-3 px-4 border rounded-lg text-gray-500">
                    {promotionObj[property]?.title || 'Message header'}
                  </p>
                </div>
                <div className="mt-2">
                  <h2 className="pl-1">
                    Description
                  </h2>
                  <div className="mt-1 h-52 text-gray-500 rounded-lg border overflow-y-scroll">
                    <Markdown rehypePlugins={[rehypeRaw]} className="p-4 [&_a]:underline">
                      {promotionObj[property]?.description || '_There is currently no message._'}
                    </Markdown>
                  </div>
                </div>
              </>
            )}
            {editForm && (
              <>
                <div className="mt-2">
                  <label htmlFor="title" className="block pl-1">
                    Title
                  </label>
                  <input
                    id="title"
                    placeholder="Enter message title here."
                    {...register("title")}
                    className="mt-1 w-full py-3 px-4 border rounded-lg"
                  />
                </div>
                <div className="mt-2">
                  <label htmlFor="description" className="block pl-1">
                    Description
                  </label>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <MDXEditor
                        ref={ref}
                        markdown={value || ''}
                        onChange={onChange}
                        placeholder="Enter message text here"
                        className="border rounded-lg mt-1 h-52 overflow-y-auto [&_a]:underline [&_li]:ml-4 [&_ul]:list-disc [&_ol]:list-decimal"
                        plugins={plugins}
                      />
                    )}
                  />
                </div>
              </>
            )}
            <div className="col-span-6 mt-4 flex justify-end">
              {!editForm && (
                <button
                  className="mr-2 px-4 py-2 items-center border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-icon hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  onClick={openEditForm}
                >
                  Edit
                </button>
              )}
              {editForm && (
                <>
                  <button
                    onClick={closeEditForm}
                    type="button"
                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  >
                    Cancel
                  </button>
                  <PrimaryButton type="submit">
                    Save
                  </PrimaryButton>
                </>
              )}
            </div>
          </FeatureDisabled>
        </div>
      </div>
    </form>
  );
}
