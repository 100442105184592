import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import client from '../../../lib/feathers';
import Breadcrumb from '../../../components/Breadcrumb';
import Page from '../../../components/Page';
import PrimaryButton from '../../../components/PrimaryButton';
import LibationsInfoPanel from '../../../components/listingDetail/Wines/LibationsInfoPanel';
import FlightsInfoPanel from '../../../components/listingDetail/Flights/FlightsInfoPanel';
import BusinessInfoPanel from '../../../components/listingDetail/Overview/BusinesInfoPanel';
import LocationInfoPanel from '../../../components/listingDetail/Location/LocationInfoPanel';
import PromotionsPanel from '../../../components/listingDetail/Promotions/PromotionsPanel';
import UploadPhotoPanel from '../../../components/listingDetail/Gallery/UploadPhotoPanel';
import IndustryConnectionRequestPanel from '../../../components/listingDetail/Users/IndustryConnectionRequestPanel';
import { setListing } from '../../../lib/listingsSlice';
import ListingDetailNavigation from '../../../components/listingDetail/ListingDetailNavigation';
import LibationForm from "../../../components/listingDetail/Wines/LibationForm";
import FlightForm from "../../../components/listingDetail/Flights/FlightForm";
import ReportsPanel from '../../../components/listingDetail/Reports/ReportsPanel';
import ItineraryInfoPanel from '../../../components/listingDetail/Itineraries/ItineraryInfoPanel';
import ItineraryForm from '../../../components/listingDetail/Itineraries/ItineraryForm';
// import LibationForm from '../../../components/listingDetail/LibationForm';
// import FlightForm from '../../../components/listingDetail/FlightForm';

export default function ListingDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lid } = useParams();
  const listing = useSelector((s) => s.listings.selected);

  const getListing = async (id) => {
    try {
      const service = client.service(`admin/listing`);
      const result = await service.get(id);
      dispatch(setListing(result));
    } catch (e) {
      console.log('setlisting error', e);
    }
  };

  useEffect(() => {
    if (lid) {
      getListing(lid);
    }
  }, [lid]);

  const tabs = [
    { name: 'Overview', section: '', notifications: null, access: 'all' },
    { name: `Location`, section: 'location', notifications: null, access: 'all' },
    { name: `Promotions`, section: 'promotions', notifications: null, access: 'all' },
    { name: `Gallery`, section: 'gallery', notifications: null, access: 'all' },
    { name: `Users`, section: 'users', notifications: listing?.notifications?.userRequests, access: 'all' },
    { name: `Wines`, section: 'wines', notifications: listing?.notifications?.userLibations, access: 'all' },
    { name: `Flights`, section: 'flights', notifications: null, access: 'all' },
    { name: `Reports`, section: 'reports', notifications: null, access: 'all' },
    { name: `Itineraries`, section: 'itineraries', notifications: null, access: ['Tour Company'] }
  ]

  return (
    <Page title="Listings">
      {listing
        ? (
          <div className="space-y-6">
            <div className="pb-5 border-b border-gray-200">
              <div className="sm:flex sm:items-center sm:justify-between">
                <Breadcrumb elements={[{ name: 'Listings', href: `/admin/listings` }, { name: `${listing?.name || 'Listing Detail'}` }]} />
              </div>
              <ListingDetailNavigation listing={listing} tabs={tabs} basePath={'/admin/listings'} />
            </div>
            <Routes>
              <Route path="/" element={<BusinessInfoPanel />} />
              <Route path="/location" element={<LocationInfoPanel />} />
              <Route path="/gallery" element={<UploadPhotoPanel />} />
              <Route path="/promotions" element={<PromotionsPanel />} />
              <Route path="/users" element={<IndustryConnectionRequestPanel />} />
              <Route path="/wines" element={<LibationsInfoPanel />} />
              <Route path="/wines/:wid/edit" element={<LibationForm />} />
              <Route path="/flights" element={<FlightsInfoPanel />} />
              <Route path="/flights/:fid/edit" element={<FlightForm />} />
              <Route path="/reports" element={<ReportsPanel />} />
              <Route path="/itineraries" element={<ItineraryInfoPanel />} />
              <Route path="/itineraries/:iid/edit" element={<ItineraryForm/>} />
            </Routes>

          </div>
        )
        : <div>Loading Listing</div>}
    </Page>
  );
}
