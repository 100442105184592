import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: undefined,
  authenticated: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.authenticated = action.payload !== undefined;
    },  
  },
});

export const { setUser } = authSlice.actions;

export default authSlice.reducer;