import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export function SortableItem(props) {
  const { children, id, index } = props

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    backgroundColor: 'white',    
  };

  return (

    <tr className={`hover:bg-gray-00 cursor-pointer ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} divide-y divide-gray-200`} ref={setNodeRef}  {...attributes} {...listeners} style={style}>
      {children} 
    </tr>
  );
};