import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setUser } from "../lib/authSlice";

import Dashboard from './admin/dashboard';
import Listings from './admin/listings';
import ListingDetail from './admin/listings/[lid]';
import ListingForm from './admin/listings/[lid]/edit';
// import LibationForm from "../components/listingDetail/Wines/LibationForm";
// import FlightForm from "../components/listingDetail/Flights/FlightForm";
import Users from './admin/users';
import Suggestions from './admin/suggestions';
import Reports from './admin/reports';
import UserDetail from './admin/users/[uid]';
import UserForm from './admin/users/[uid]/edit';
import Accommodations from './admin/accommodations';
import AccommodationsForm from './admin/accommodations/[aid]/edit';
import client from '../lib/feathers';

import Sidebar from '../components/AdminSidebar';

import Notifications from "./admin/notifications";
import NoResultsSearch from "./admin/reports/no-results-search";
import QRCode from "./admin/QRCode";
import NoListingLibations from "./admin/reports/no-listing-libations";
import WinesNeedingReview from "./admin/reports/wines-needing-review";

function Admin() {
  const [showSidebar, setShowSidebar] = useState(false);
  const user = useSelector((state) => state.auth.user);
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const auth = await client.authenticate();
        dispatch(setUser(auth.user));
        if (auth.user.role !== 'admin') {
          navigate(`/${auth.user.role}/`);
        }
      } catch (e) {
        navigate(`/`);
      }
    };
    checkAuth();
  }, []);

  return (
    <div className="App">

      {user
        ? (
          <div className="h-screen flex overflow-hidden bg-gray-100">
            {/* <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. --> */}
            {showSidebar
              && (
                <div className="md:hidden">
                  <div className="fixed inset-0 flex z-40">

                    <div className="fixed inset-0">
                      <div className="absolute inset-0 bg-gray-600 opacity-75" />
                    </div>

                    <div className="relative flex-1 flex flex-col max-w-xs w-full bg-primary" onClick={() => setShowSidebar(false)}>
                      <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button type="button" className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                          <span className="sr-only">Close sidebar</span>
                          {/* <!-- Heroicon name: outline/x --> */}
                          <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                      <Sidebar user={user} />
                    </div>
                    <div className="flex-shrink-0 w-14">
                      {/* <!-- Force sidebar to shrink to fit close icon --> */}
                    </div>
                  </div>
                </div>
              )}

            {/* <!-- Static sidebar for desktop --> */}
            <div className="hidden md:flex md:flex-shrink-0">
              <div className="flex flex-col w-64">
                <Sidebar user={user} />
              </div>
            </div>
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
              <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
                <button type="button" onClick={() => setShowSidebar(true)} className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open sidebar</span>
                  {/* <!-- Heroicon name: outline/menu --> */}
                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </button>
              </div>
              <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none p-2 md:p-5">
                <Routes>
                  <Route path="/accommodations" exact element={<Accommodations />} />
                  <Route path="/accommodations/:aid/edit" element={<AccommodationsForm />} />
                  <Route path="/users" exact element={<Users />} />
                  <Route path="/users/:uid/edit" element={<UserForm />} />
                  <Route path="/users/:uid" element={<UserDetail />} />
                  <Route path="/suggestions" exact element={<Suggestions />} />
                  <Route path="/reports" exact element={<Reports />} />
                  <Route path="/reports/no-results-search" exact element={<NoResultsSearch />} />
                  <Route path="/reports/no-listing-libations" exact element={<NoListingLibations />} />
                  <Route path="/reports/wines-needing-review" exact element={<WinesNeedingReview />} />
                  <Route path="/notifications" exact element={<Notifications />} />
                  <Route path="/listings" exact element={<Listings />} />
                  <Route path="/listings/:lid/edit" element={<ListingForm />} />
                  {/* <Route path="/listings/:lid/wines/:wid/edit" element={<LibationForm />} />
                  <Route path="/listings/:lid/flights/:fid/edit" element={<FlightForm />} /> */}
                  <Route path="/listings/:lid/*" element={<ListingDetail />} />
                  <Route path="/qrcode" element={<QRCode />} />
                  <Route path="/" element={<Dashboard />} />
                </Routes>
              </main>
            </div>
          </div>)
        : <div>Checking Authentication...</div>}

    </div>
  )
}

export default Admin;