import React from 'react';
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup";
import client from '../../../lib/feathers';

const schema = yup.object({
  name: yup.string().required("Please enter a name for this list"),
}).required();

export default function CloneItineraryForm(props) {
  const { onCancelClick, itinerary } = props;
  const { lid } = useParams();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const navigateToItinerary = (itinerary) => {
    navigate(`/${user.role}/listings/${lid}/itineraries/${itinerary.id}/edit`);
  }

  const onSubmit = async (values) => {
    if (user) {
      const service = client.service(`${user.role}/listing-list-clone`);
      const data = {
        ...values,
        access: 'public',
        userId: user.id,
        listingId: lid,
        sourceId: itinerary.id
      };
      try {
        const result = await service.create(data)
        navigateToItinerary(result);
      } catch (e) {
        console.error('Error creating new Itinerary: ', e);
      }
    }
  };

  // Tailwind Styles //
  const errorFocus = 'border-red-400 focus:border-red-500 focus:ring-red-500';
  const nonErrorFocus = 'border-gray-300 focus:ring-gray-500 focus:border-gray-500';

  return (
    <>
      <div className="mt-3 text-center sm:mt-5">
        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
          Clone {itinerary.name ? `'${itinerary.name}'` : 'an itinerary'}
        </h3>
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            Enter the name for this itinerary.
          </p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="text-left">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <div className="mt-1">
                <input
                  id="name"
                  value={`Copy of ${itinerary.name}`}
                  {...register("name")}
                  className={`appearance-none block w-full px-3 py-2 border ${errors.name ? errorFocus : nonErrorFocus} rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm`}
                />
                {errors.name && <span className="text-red-400">{errors.name.message}</span>}

              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-icon text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 hover:bg-red-700 sm:col-start-2 sm:text-sm">Clone</button>
              <button type="button" onClick={onCancelClick} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:col-start-1 sm:text-sm">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
};
