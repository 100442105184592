import React from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Page from '../../../../components/Page';
import client from '../../../../lib/feathers';

export default function UserForm() {
  const navigate = useNavigate();
  const { uid } = useParams();
  // const [user, setUser] = useState();

  // const getUser = async (id) => {
  //   try {
  //     const service = client.service('admin/user');
  //     const result = await service.get(id);
  //     console.log('result', result);
  //     setUser(result);
  //   } catch (e) {
  //     console.log('error', e);
  //   }
  // };

  const onDeleteUserClick = async () => {
    const resp = confirm('Are you sure you want to delete this user?');// eslint-disable-line no-restricted-globals
    if (resp) {
      try {
        const service = client.service('admin/user');
        await service.remove(uid);
        console.log('Successfully deleted user');
        navigate('/admin/users');
      } catch (e) {
        console.log('Failed to delete user', e);
      }
    }
  };

  // useEffect(() => {
  //   if (uid) getUser(uid);
  // }, [uid]);

  return (
    <Page title="User Form">
      <div className="bg-white shadow overflow-hidden px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Edit User
          </h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                <button
                  type="button"
                  className="font-semibold border py-1 px-2 bg-primary text-white"
                  onClick={() => onDeleteUserClick()}
                >
                  Delete
                </button>
              </dt>
            </div>
          </dl>
        </div>
      </div>
    </Page>
  );
}
