import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import NewListingForm from '../../components/NewListingForm';
import Page from '../../components/Page';

import EntitySearchForm from '../../components/EntitySearchForm';
import Pagination from '../../components/Pagination';
import client from '../../lib/feathers';
import PrimaryButton from '../../components/PrimaryButton';
import { useDispatch, useSelector } from 'react-redux';
import { setKeywords, setListings } from '../../lib/listingsSlice';

const getInitials = (string) => {
  const words = string.split(" ");
  const prefixes = ["A","of","&","and","And","the", "The", "at", "At"]
  const filtered = words.filter((w) => !prefixes.includes(w));
  if (filtered.length === 1) {
    return filtered[0].slice(0,2).toUpperCase();
  }
  if (filtered.length > 1) {

    if (filtered[1]){
      return (filtered[0][0] + filtered[1][0]).toUpperCase();
    } else {
      return "dd";
    }
    
  }
}

export default function Listings() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showCreateModal, setShowCreateModal] = useState(false);

  const [query] = useSearchParams();
  const limit = 25;
  const [page, setPage] = useState(query.get('page') || 0);
  const listings = useSelector(state => state.listings.results);
  const [total, setTotal] = useState(0);
  const keywords = useSelector(state => state.listings.keywords);
  // const [keywords, setKeywords] = useState(query.keywords);

  const setOnKeywords = e => {
    dispatch(setKeywords(e));
  };
  const getListings = async () => {
    try {
      const service = client.service('admin/listing');
      const q = {
        $limit: limit,
        $skip: page * limit,
        $sort: {
          createdAt: -1,
        },
      };

      if (keywords && keywords.length) {
        q.name = { $iLike: `%${keywords}%` };
      }

      const result = await service.find({
        query: q,
      });

      dispatch(setListings(result.data));
      setTotal(result.total);
    } catch (e) {
      console.log('error', e);
    }
  };

  const onSearch = e => {
    e.preventDefault();
    setPage(0);
    getListings();
  };
  const navigateToPage = (p = 0, kw) => {
    if (p * limit > total || p * limit < 0) return;
    let url = `/admin/listings?page=${p}`;
    if (kw) {
      url += `&keywords=${kw}`;
    }
    navigate(url);
    setPage(p);
  };

  const onNewListing = listing => {
    navigate(`/admin/listings/${listing.id}/`);
  };


  const displayAddress = metaLoc => {
    let addy = '';
    if (metaLoc && metaLoc.city) {
      addy += metaLoc.city;
    }
    if (metaLoc && metaLoc.state) {
      if (addy.length > 0) {
        addy += ', ';
      }
      addy += metaLoc.state;
    }
    return addy;
  };

  useEffect(() => {
    getListings();
  }, [page]);

  return (
    <Page title="Listings">
      <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
        <nav className="flex" aria-label="Breadcrumb">
          <Breadcrumb elements={[{ name: 'Listings' }]} />
        </nav>
        <div className="mt-3 sm:mt-0 sm:ml-4">
          <PrimaryButton onClick={() => setShowCreateModal(true)}>Create New Listing</PrimaryButton>
        </div>
      </div>
      <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
        <EntitySearchForm onSearch={onSearch} setKeywords={setOnKeywords} keywords={keywords} />
        <h3 className="text-lg leading-6 font-medium text-gray-900">Listings ({total} Total)</h3>
        <div className="mt-3 sm:mt-0 sm:ml-4">
          <Pagination total={total} limit={limit} page={page} navigateToPage={navigateToPage} />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto">
          <div className="py-2 align-middle flex min-w-full">
            <div className="shadow overflow-hidden min-w-full border-b border-gray-200 rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Name
                    </th>
                    <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                      Category
                    </th>
                    <th scope="col" className="pl-3 py-3.5 sm:table-cell">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-300">
                  {listings.map(l => (
                    <tr className="hover:bg-gray-100 cursor-pointer" key={l.id} onClick={() => onNewListing(l)}>
                      <td className="pl-3 py-4 pr-3 flex-col">
                        <div className="flex items-top sm:items-center">
                          <div className="flex-shrink-0 h-10 w-10">
                            <div className="h-10 w-10 rounded-full bg-primary pt-2 text-center text-white">
                              {getInitials(l.name)}
                            </div>
                          </div>
                          <div className="ml-4 sm:flex-col">
                            <div className="py-2 text-sm font-medium text-gray-900">
                              {l.name}
                            </div>
                              <dd className="py-2 text-sm font-medium sm:hidden">
                            <span>
                              {l.categories.map(c => {
                                return (
                                  <span key={c.id} className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-green-800">
                                    {c.name}
                                  </span>
                                );
                              })}
                            </span>
                          </dd>
                          </div>
                        </div>
                      </td>
                      <td className="hidden px-6 py-4 whitespace-nowrap sm:table-cell">
                        {l.categories.map(c => {
                          return (
                            <span key={c.id} className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-green-800">
                              {c.name}
                            </span>
                          );
                        })}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <Link to={`listings/${l.id}/edit`}>
                        <button className="items-center text-center w-16 py-2 my-1 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-icon hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                          Edit
                        </button>
                      </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination total={total} limit={limit} page={page} navigateToPage={navigateToPage} />
            </div>
          </div>
        </div>
      </div>

      {showCreateModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75" />
            </div>

            <div
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <NewListingForm onCancelClick={() => setShowCreateModal(false)} onNewListing={onNewListing} />
            </div>
          </div>
        </div>
      )}
    </Page>
  );
}
