import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
//adding in formik to store user info for future changes to roles
import { Formik, Field, Form } from 'formik';
import client from '../../../lib/feathers';


export default function IndustryConnectionRequestPanel(props) {
  const listing = useSelector((s) => s.listings.selected);
  const loggedInUser = useSelector((s) => s.auth.user);

  const [requests, setRequests] = useState([]);
  const [userListings, setUserListings] = useState();

  //Empty state to store for button toggle
  const [openUserListingForms, setOpenUserListingForms] = useState({})

  //Renders options for dropdown
  const categories = [
    { value: 'manager', label: 'Manager' },
    { value: 'staff', label: 'Staff' }
  ];

  const loadUserListings = async () => {
    const listingUserService = client.service(`/${loggedInUser?.role}/listing-user`);
    const listingUsers = await listingUserService.find({ query: { listingId: listing.id } });
    const users = listingUsers.data.map((lu) => lu);
    sortUserListings(users);
  };

  const sortUserListings = (users) => {
    const sortedUsers = users.sort((a, b) => {
      var nameA = a.user.lastName.toLowerCase(), nameB = b.user.lastName.toLowerCase();
      if (nameA < nameB)
        return -1;
      if (nameA > nameB)
        return 1;
      return 0;
    });
    setUserListings(sortedUsers);

  };

  const getListingUserInvitations = async () => {
    const listingUserInvitationService = client.service(`${loggedInUser?.role}/listing-user-invitation`);
    const result = await listingUserInvitationService.find({
      query: {
        listingId: listing.id,
      }
    })

    setRequests(result.data);
  };

  const accept = async (record) => {

    const listingUserInvitationService = client.service(`${loggedInUser?.role}/listing-user-invitation`);
    const listingIdService = client.service(`${loggedInUser?.role}/listing-user`);

    const listingUser = {
      listingId: record.listing.id,
      userId: record.user.id,
      email: record.user.email,
      access: 'staff'
    };

    await listingUserInvitationService.patch(
      record.id,
      {
        status: "Accepted",
        updatedAt: Date.now(),
      }
    )

    await listingIdService.create(listingUser).then((result) => {
      getListingUserInvitations();
      loadUserListings();
    }).catch((error) => {
      console.error('Error creating listingUser', error);
      alert(error.message);
    });

  };

  const deny = async (record) => {
    const listingUserInvitationService = client.service(`${loggedInUser?.role}/listing-user-invitation`);
    await listingUserInvitationService.patch(
      record.id,
      {
        status: "Denied",
        updatedAt: Date.now()
      }
    )

    getListingUserInvitations();
    loadUserListings();
  };

  const onDeleteConnectionClick = async (id) => {
    const resp = confirm('Are you sure you want to delete this connection?'); // eslint-disable-line no-restricted-globals
    if (resp) {
      const service = client.service(`${loggedInUser?.role}/listing-user`);
      await service.remove(id);
    }
    loadUserListings();
  };


  const onSaveListingUserFormSubmit = async (updatedUser, idx) => {
    const listingIdService = client.service(`${loggedInUser?.role}/listing-user`);
    await listingIdService.patch(updatedUser.id, updatedUser);
    handleCancelClick(updatedUser.id);
    loadUserListings();
  };

  useEffect(() => {
    if (loggedInUser) {
      getListingUserInvitations();
      loadUserListings();
    }
  }, [loggedInUser]);

  const handleEditClick = (ulId) => {
    const newids = { ...openUserListingForms };
    newids[ulId] = true;
    setOpenUserListingForms(newids);
  };

  const handleCancelClick = (ulId) => {
    const newids = { ...openUserListingForms };
    delete newids[ulId];
    setOpenUserListingForms(newids);
  };

  return (
    <div className="bg-white shadow overflow-hidden px-5 py-5 sm:rounded-lg sm:p-6">
      <div className="sm:items-center py-5">
        <div className="sm:flex sm:items-center py-2">
          <div className="sm:flex-auto">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Current Users</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Here's everyone who has access to your listing
            </p>
          </div>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            {userListings && userListings.length > 0 && userListings.map((ul, idx) => {
              return (
                <div key={idx} className="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    {ul.user.firstName} {ul.user.lastName}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1 capitalize">

                    {ul.access}
                  </dd>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                    {ul.email}
                  </dd>
                  <dd className="grid mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                    {!openUserListingForms[ul.id] && (
                      <button
                        type="button"
                        onClick={() => handleEditClick(ul.id)}
                        className="items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-icon hover:bg-red-700 justify-self-end">Edit</button>
                    )}
                  </dd>
                  {openUserListingForms[ul.id] && (

                    <div className="col-span-4">
                      <hr />
                      <Formik
                        initialValues={{
                          access: ul.access,
                          email: ul.email,
                          id: ul.id
                        }}
                        onSubmit={onSaveListingUserFormSubmit}
                      >
                        {({ resetForm }) => {
                          return (
                            <Form>
                              <div className="grid gap-4">
                                <div className="gap-4 col-span-4 md:flex mt-4">
                                  <div className="sm:col-span-1">
                                    <label htmlFor="access" className="block text-sm font-medium text-gray-700">Role</label>
                                    <Field as="select" name="access" id="access" className="mt-1 w-40" >
                                      <option disabled>Please Select</option>
                                      {categories.map((c) => <option key={`cId-${c.value}`} value={c.value}>{c.label}</option>)}
                                    </Field>
                                  </div>
                                  <div className="sm:col-span-2 w-60">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                                    <Field
                                      type="email"
                                      name="email"
                                      id="email"
                                      autoComplete="email"
                                      inputMode="email"
                                      title="Must be a valid email address"
                                      required
                                      className="mt-1 focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md" />
                                  </div>
                                </div>
                                <div className="grid col-span-4">
                                  <div>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                                      <button type="button" onClick={() => onDeleteConnectionClick(ul.id)} className="items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-icon hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">Delete</button>
                                    </dd>
                                  </div>
                                  <div className="flex col-start-4 gap-2 justify-end">
                                    <dd>
                                      <button
                                        type="button"
                                        onClick={() => { resetForm(); handleCancelClick(ul.id) }}
                                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">Cancel</button>
                                    </dd>
                                    <button type='submit' className=" items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-900" >Save</button>
                                  </div>
                                </div>
                                <div className='mt-1'></div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  )}
                </div>
              );
            })}
            {!userListings || userListings.length == 0 && (
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  No Current Users
                </dt>
              </div>
            )}
          </dl>
        </div>
      </div>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex justify-between items-center">
              <h3 className="text-lg text-left h3t-3 leading-6 font-medium text-gray-900">
                Listing Access Requests
                {requests.filter(request => request.status === null).length > 0
                  ? <span className="inline-flex items-center justify-center h-5 w-5 ml-2 rounded-full bg-icon text-white">{requests.filter(request => request.status === null).length}</span>
                  : null}
              </h3>
              <ChevronRightIcon
                className={`${open ? 'rotate-90 transform' : ''
                  } h-7 w-7 text-lg leading-6  font-medium text-gray-900`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="">
              <div className="mt-8 flow-root">
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  A list of all requests submitted for privileged access to this listing
                </p>
                <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                  <div className="min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th scope="col" className="hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3 lg:table-cell">
                            Name
                          </th>
                          <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 xl:table-cell">
                            Approver
                          </th>
                          <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 xl:table-cell">
                            Date Requested
                          </th>
                          <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                            Date Updated
                          </th>
                          <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                            Status
                          </th>
                          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                            <span className="sr-only">Manage</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {requests.map((request, requestIdx) => (
                          <tr key={requestIdx} className={requestIdx % 2 === 0 ? undefined : 'bg-gray-50'}>
                            <td>

                              <dl className="whitespace-nowrap py-4 pl-3 pr-3 text-sm font-medium text-gray-900 ">
                                {request.user.firstName} {request.user.lastName}
                                <br />
                                {request.user.email}

                              </dl>
                              <dd className="whitespace-nowrap px-3 py-1 text-sm text-gray-500 xl:hidden">
                                {request.approver && (
                                  <span><span className="text-black font-medium">Approver : </span>{request.approver.firstName} {request.approver.lastName} ({request.approver.role}) <br />{request.approver.email}<br /></span>
                                )}
                              </dd>
                              <dd className="whitespace-nowrap px-3 py-1 text-sm font-medium xl:hidden">Date Requested : <span className="text-gray-500 font-normal">{new Date(request.createdAt).toDateString()}</span></dd>

                              <dd className="whitespace-nowrap px-3 py-1 text-sm font-medium lg:hidden">Date Updated : <span className="text-gray-500 font-normal">{new Date(request.updatedAt).toDateString()}</span></dd>
                              <dd className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:hidden">
                                <span className="text-black font-medium">Status : </span>
                                {request.status
                                  ? <span className={`inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-${request.status === "Accepted" ? "green" : "red"}-700 ring-1 ring-inset ring-blue-600/20`}>{request.status}</span>
                                  : <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">Pending</span>
                                }
                              </dd>
                            </td>
                            <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 xl:table-cell">
                              {request.approver && (
                                <span>
                                  {request.approver.firstName} {request.approver.lastName}<br />{request.approver.email}<br />{request.approver.role}</span>
                              )}
                            </td>
                            <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 xl:table-cell">{new Date(request.createdAt).toDateString()}</td>
                            <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">{new Date(request.updatedAt).toDateString()}</td>
                            <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                              {request.status
                                ? <span className={`inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-${request.status === "Accepted" ? "green" : "red"}-700 ring-1 ring-inset ring-blue-600/20`}>{request.status}</span>
                                : <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">Pending</span>
                              }
                            </td>
                            {!request.status &&
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                <div className="flex-row lg:flex lg:justify-between">
                                  <button className="items-center px-4 py-2 my-1 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-icon hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500" onClick={() => accept(request)}>Accept</button><br />
                                  <button className="bg-white py-2 px-4 my-1 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500" onClick={() => deny(request)}>Deny</button>
                                </div>
                              </td>
                            }
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  )
}