import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { setUser } from "../lib/authSlice";
import client from '../lib/feathers';

export default function Login() {  
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loaded, setLoaded] = useState(false);
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmitClick = async (e) => {
    e.preventDefault();
    // console.log('submit', username);

    try {
      const auth = await client.authenticate({
        strategy: 'local',
        email: username.toLowerCase(),
        password,
      });
      // console.log('auth', auth);
      if (auth.user.role === 'user') {
        alert('Sorry, your account does not have access to this site.');
        await client.logout();
      }
      dispatch(setUser(auth.user));
      navigate(auth.user.role);
    } catch (err) {
      console.log('auth failed', err);
      alert('Login Failed. Please check your email or password');
    }
  };
  // const getUsers = async () => {
  //   try {
  //   const userService = client.service('venue');
  //   const users = await userService.find({query: {$limit: 200}});
  //   console.log('users', users);
  //   } catch (e) {
  //     console.log('cant get users', e);
  //   }
  // }
  // const logout = async () => {
  //   try {
  //   const auth = await client.logout()
  //   console.log('logout', auth);
  //   } catch (e) {
  //     console.log('cant logout', e);
  //   }
  // }
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const auth = await client.authenticate();
        navigate(`/${auth.user.role}/`);
      } catch (e) {
        // alert('Login Failed')
        setLoaded(true);
      }
    };
    checkAuth();
  }, []);

  return (
    <div>
      <div className="flex items-center flex-col justify-center h-screen bg-primary">
        <div className="w-80">
          <img src="https://assets.vinoseeker.com/site-images/Horizontal-Seeker-Logo-Whilte-Border.png" alt="VinoSeeker Admin" />
        </div>
        <form onSubmit={onSubmitClick} className="w-full sm:w-1/2 px-2">
          <div className="bg-white shadow-md rounded p-4">
            {loaded
              ? (
                <>
                  <div className="mx-auto text-xl font-medium text-black">Business Admin</div>
                  <div className="mb-4">
                    <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="username">
                      Email
                    </label>
                    <input
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                      id="username"
                      type="email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="mb-6">
                    <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="password">
                      Password
                    </label>
                    <input required value={password} onChange={(e) => setPassword(e.target.value)} className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-3" id="password" type="password" />
                    {/* <p className="text-red text-xs italic">Please choose a password.</p> */}
                  </div>
                  <div className="flex items-center justify-between">
                    <button className="hover:bg-blue-dark text-white font-bold py-2 px-4 rounded bg-primary" type="submit">
                      Sign In
                    </button>
                    {/* <a className="inline-block align-baseline font-bold text-sm text-blue hover:text-blue-darker" href="#">
                  Forgot Password?
                </a> */}
                    {/* <button className="bg-blue-900 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded" type="button" onClick={getUsers} >Users</button>
                <button className="bg-blue-900 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded" type="button" onClick={logout} >Log out</button> */}
                  </div>
                </>
              )
              : (
                <div>
                  Checking Authentication...
                </div>
              )}
          </div>
        </form>

      </div>

    </div>
  );
}
