import React, { useEffect, useState } from 'react';
import { TileLayer, MapContainer, Circle, LayerGroup, useMap } from 'react-leaflet';
import Page from '../../components/Page';
import client from '../../lib/feathers';

const CircleGroup = ({ summaries }) => {
  const map = useMap();
  const bounds = [];

  const listMarkers = summaries.map((v,i) => {
    bounds.push([v.lat, v.lng]);
    return (
      <Circle
        key={i}
        center={[v.lat, v.lng]}
        radius={v.radius * 20000}
      />
    );
  });
  map.fitBounds(bounds.slice(0, 5));
  return (
    <LayerGroup>
      {listMarkers}
    </LayerGroup>
  )
}

export default function Dashboard() {
  const [summaries, setSummaries] = useState([]);

  const loadSummaries = async () => {
    try {
      const service = client.service('admin/listing-location-summary');
      const result = await service.find();

      setSummaries(result);

    } catch (e) {
      console.log('Cant get summary data');
    }
  };

  useEffect(() => {
    loadSummaries();
  }, []);

  return (
    <Page title="Dashboard">
      Choose from the items in the menu
      {summaries && summaries.length && (
        <div className="relative h-full">
          <MapContainer
            center={[summaries[0].lat, summaries[0].lng]}
            zoom="8">
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <CircleGroup summaries={summaries} />
          </MapContainer>
        </div>
      )}
    </Page>
  );
}
