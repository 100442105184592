import { Fragment, useState, useEffect } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import { MapContainer, TileLayer, Popup } from 'react-leaflet'
import AssignMarkerIcon from '../../components/ListingStatusIcon'
import { Link } from 'react-router-dom';
import client from '../../lib/feathers';
import { useDispatch, useSelector } from 'react-redux';
import { setListings } from '../../lib/listingsSlice';

function IndustryListings() {
  const dispatch = useDispatch();
  const listings = useSelector((state) => state.listings.results);

  const getListings = async () => {
    try {
      const service = client.service('industry/listing');
      const result = await service.find({});

      dispatch(setListings(result.data));
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    getListings();
  }, []);

  return (
    <>
      <div className="min-h-full">

        <header className="bg-white shadow-sm">
          <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8">
            <h1 className="text-lg font-semibold leading-6 text-gray-900">Available Listings</h1>
          </div>
        </header>
      </div>

      {/* <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight px-15 py-5">
          
        </h2>
      </div> */}

      {/* mini path block */}
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
        {/* <div className='container mx-auto'>
          <nav className="sm:hidden" aria-label="Back">
            <a href="#" className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
              <ChevronLeftIcon className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              Back
            </a>
          </nav>
          <nav className="hidden sm:flex" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-4">
              <li>
                <div className="flex">
                  <a href="#" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                    Jobs
                  </a>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <a href="#" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                    Engineering
                  </a>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <a href="#" aria-current="page" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                    {listings[0]?.name}
                  </a>
                </div>
              </li>
            </ol>
          </nav>
        </div> */}

        {/* start of card */}
        {listings.map((listing, i) => {
          return (
            // card
            <Fragment key={i}>
              <div className="mt-2 md:flex md:items-center md:justify-between">
                <div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
                </div>
              </div>
              {/* Description Lists */}
              <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6 md:grid grid-cols-4 w-full">
                  <div className="col-span-3">
                    <h3 className="text-lg font-semibold leading-6 text-gray-900">{listing.name}</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">{listing.shortDescription}</p>
                  </div>
                  <div className="flex md:justify-end">
                    <Link to={`listings/${listing.id}/`}>
                      <button className="block rounded-md px-3 py-2 text-base font-medium bg-icon text-white hover:bg-icon hover:bg-opacity-75">
                        Details
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="md:grid grid-cols-4 w-full">
                  <div className="border-t border-gray-200 col-span-2">
                    <dl>
                      {listing.address && (
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Address</dt>
                        <dd className="mt-1 text-sm text-gray-900 col-span-2 sm:col-span-2 sm:mt-0">
                          {listing.address.address1}<br/>
                          {listing.address.address2}<br/>
                          {listing.address.city}{listing.address.city && listing.address.state ? ', ' : ' '} {listing.address.state} {listing.address.zip}
                        </dd>
                      </div>
                      )}
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Contact</dt>
                        <dd className="mt-1 text-sm text-gray-900 col-span-2 sm:col-span-2 sm:mt-0">
                          Phone: {listing.contact?.phone && listing.contact["phone"]} < br />
                          {listing.contact && listing.contact["website"] && (
                            <span>Website: <a href={listing.contact["website"]} referral="_blank">{listing.contact.website}</a></span>
                          )}
                          
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Categories</dt>
                        <dd className="mt-1 text-sm text-gray-900 col-span-2 sm:col-span-2 sm:mt-0">{listing.categories.map((category, i) => {
                          if (i === 0) {
                            return category.name;
                          } else {
                            return ", " + category.name;
                          }
                        })}</dd>
                      </div>
                    </dl>
                  </div>
                  <div className="relative h-96 md:gap-6 px-4 py-5 col-span-2">
                    <MapContainer center={[listing.location.coordinates[1], listing.location.coordinates[0]]} zoom={13} scrollWheelZoom={false}>
                      <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      />
                      <AssignMarkerIcon listingStatus={listing.status} position={[listing.location.coordinates[1], listing.location.coordinates[0]]}>
                        <Popup>
                          {listing.name}
                        </Popup>
                      </AssignMarkerIcon>
                    </MapContainer>
                  </div>
                </div>

              </div>
            </Fragment>
          );
        })}
      </div>
    </>
  )
}

export default IndustryListings;
