import React, { useState } from 'react';
import toast from 'react-hot-toast';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import client from '../lib/feathers';
import { formatIcon } from '../utilities/formatters';

export default function newAccommodation(props) {
  const {
    onCancelClick,
    setShowCreateModal,
    onNewAccommodation,
    listLength,
    safety,
    services,
    availability,
  } = props;
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [iconId, setIconId] = useState('');

  const onFormSubmit = async (event) => {
    event.preventDefault();
    const service = client.service('admin/accommodation');
    let orderNum = listLength;
    if (type === 'safety') {
      orderNum = safety.sort((a, b) => (a.order < b.order ? 1 : -1))[0].order;
    } else if (type === 'services') {
      orderNum = services.sort((a, b) => (a.order < b.order ? 1 : -1))[0].order;
    } else if (type === 'availability') {
      orderNum = availability.sort((a, b) => (a.order < b.order ? 1 : -1))[0].order;
    } else if (type === 'certifications') {
      orderNum = certifications.sort((a, b) => (a.order < b.order ? 1 : -1))[0].order;
    }
    const data = {
      name, type, iconId: formatIcon(iconId), order: orderNum + 1,
    };
    if (data.type === '') {
      toast.error('Please select a type.');
      return;
    }
    if (!icons[formatIcon(iconId)]) {
      toast.error('Please select a valid icon');
      return;
    }

    console.log('data', data);
    try {
      const result = await service.create(data);
      setShowCreateModal(false);
      onNewAccommodation(result);
      toast.success(`Added ${name}`);
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong creating the new listing.');
    }
  };

  return (
    <>
      <div className="mt-3 text-center sm:mt-5">
        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
          Create New Accommodation
        </h3>
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            Enter the name for this accommodation.
          </p>
          <form onSubmit={onFormSubmit}>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
            <input type="text" onChange={(e) => setName(e.target.value)} required name="name" id="name" autoComplete="false" className="mt-1 focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
            <div className="flex justify-around">
              <label htmlFor="icon" className="block text-sm font-medium text-gray-700">Type</label>
              <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                Icon
                &nbsp;
                <span>{ icons[formatIcon(iconId)] ? <FontAwesomeIcon icon={icons[formatIcon(iconId)]} /> : null }</span>
              </label>
            </div>
            <div className="flex">
              <select type="text" onChange={(e) => setType(e.target.value)} required name="type" id="type" className="mt-1 focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                <option>Please Select</option>
                <option value="availability">Availability</option>
                <option value="safety">Safety</option>
                <option value="services">Services</option>
                <option value="certifications">Certifications</option>
              </select>

              <input type="text" onChange={(e) => setIconId(e.target.value)} required name="name" id="name" autoComplete="false" className="mt-1 focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:col-start-2 sm:text-sm">Create</button>
              <button type="button" onClick={onCancelClick} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:col-start-1 sm:text-sm">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
