import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Breadcrumb from '../../components/Breadcrumb';
import Page from '../../components/Page';

import EntitySearchForm from '../../components/EntitySearchForm';
import Pagination from '../../components/Pagination';
import client from '../../lib/feathers';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

export default function Users() {
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const limit = 25;
  const [page, setPage] = useState(query.get('page') || 0);
  const [roleFilter, setRoleFilter] = useState();
  const roleList = ['user', 'industry', 'admin'];


  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [keywords, setKeywords] = useState(query.keywords);

  const getUsers = async () => {
    try {
      const service = client.service('admin/user');
      const q = {
        $limit: limit,
        $skip: page * limit,
        $sort: {
          createdAt: -1,
        },
      };

      if (keywords && keywords.length) {
        q.$or = [
          { username: { $iLike: `%${keywords}%` } },
          { firstName: { $iLike: `%${keywords}%` } },
          { lastName: { $iLike: `%${keywords}%` } 
        }];
      }

      if ( roleFilter ) {
        q.role = roleFilter;
      }

      const result = await service.find({
        query: q,
      });

      setUsers(result.data);
      setTotal(result.total);
    } catch (e) {
      console.log('error', e);
      alert('there was an issue loading the users');
    }
  };

  const onSearch = (e) => {
    e.preventDefault();
    setPage(0);
    getUsers();
  };
  const navigateToPage = (p = 0, kw) => {
    if (p * limit > total || p * limit < 0) return;
    let url = `/admin/users?page=${p}`;
    if (kw) {
      url += `&keywords=${kw}`;
    }
    navigate(url);
    setPage(p);
  };

  const onNewUser = (user) => {
    navigate(`/admin/users/${user.id}`);
  };

  useEffect(() => {
    getUsers();
  }, [page]);

  useEffect(() => {
    getUsers();
  }, [roleFilter]);

  return (
    <Page title="Users">

      <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">

        <nav className="flex" aria-label="Breadcrumb">
          <Breadcrumb elements={[{ name: 'Users' }]} />
        </nav>
        <div className="mt-3 sm:mt-0 sm:ml-4">
          {/* <button onClick={() => setShowCreateModal(true)} type="button" className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
            Create New Listing
          </button> */}
        </div>
      </div>
      <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Users (
          {total}
          {' '}
          Total)
        </h3>
        <EntitySearchForm onSearch={onSearch} setKeywords={setKeywords} keywords={keywords} />
        <div className="mt-3 sm:mt-0 sm:ml-4">
          <Pagination total={total} limit={limit} page={page} navigateToPage={navigateToPage} />
        </div>
      </div>
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">Select a tab</label>
          <select onChange={(e) => setRoleFilter(e.target.value)} value={roleFilter} id="tabs" name="tabs" className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md">
            {roleList.map((s) => <option key={s}>{s}</option>)}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {roleList.map((s) => {
                let styles = 'capitalize border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm';
                if (roleFilter === s) {
                  styles = 'capitalize border-gray-500 text-gray-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm';
                }
                return <a href="#" onClick={() => setRoleFilter(s)} className={styles} aria-current="page" key={s}>{s}</a>;
              })}
            </nav>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <b>Name</b>
                      <br />
                      Email
                    </th>
                    {/* <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Title
                                    </th> */}
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Role
                    </th>
                    {/* <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Role
                                    </th> */}
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {users.map((u) => (
                    <tr className="hover:bg-gray-100 cursor-pointer" key={u.id} onClick={() => onNewUser(u)}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10">
                            {/* <img className="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=4&amp;w=256&amp;h=256&amp;q=60" alt="" /> */}
                            <div className="h-10 w-10 rounded-full bg-primary pt-2 text-center text-white">CM</div>
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">
                              {u.firstName}
                              &nbsp;
                              {u.lastName}
                            </div>
                            <div className="text-sm text-gray-500">
                              {u.email}
                            </div>
                          </div>
                        </div>
                      </td>
                      {/* <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm text-gray-900">Regional Paradigm Technician</div>
                                            <div className="text-sm text-gray-500">Optimization</div>
                                        </td> */}
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-green-800">
                          {/* {l.category.name} */}
                          {u.role}
                        </span>
                      </td>
                      {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            Admin
                                        </td> */}
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <Link to={`/admin/users/${u.id}/edit`} className="text-red-600 hover:text-red-900">Edit</Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination total={total} limit={limit} page={page} navigateToPage={navigateToPage} />
            </div>
          </div>
        </div>
      </div>

    </Page>
  );
}
