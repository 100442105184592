import React, { useState, useEffect } from "react";
// import { useHistory } from 'react-router';
import { Toaster } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as icon from "@fortawesome/free-solid-svg-icons";
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  TouchSensor,
  MouseSensor,
} from "@dnd-kit/core";
import Breadcrumb from "../../components/Breadcrumb";
import Page from "../../components/Page";
import PrimaryButton from "../../components/PrimaryButton";
import client from "../../lib/feathers";
import NewAccommodationForm from "../../components/NewAccommodationForm";
import { Link } from "react-router-dom";
import { SortableItem } from "./sortableItem";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
  // verticalListSortingStrategy,
} from "@dnd-kit/sortable";

const SortableAccommodationSection = (props) => {
  const { items, type, strategy } = props;
  return (
    <SortableContext
      id={type}
      strategy={strategy}
      items={items}
    >
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {type}
            </th>
            <th scope="col" className="relative px-6 py-3">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {items.filter((a) => a.type === type).map((a, index) =>
            <SortableItem key={a.id} id={a.id} index={index}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                  <div className="ml-4">
                    <div className="text-sm font-medium text-gray-900">
                      <FontAwesomeIcon
                        icon={icon[a.iconId]}
                        className="w-4 fa-fw mr-2"
                      />
                      {a.name}
                    </div>
                  </div>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <Link
                  to={`/admin/accommodations/${a.id}/edit`}
                  className="text-red-600 hover:text-red-900"
                >
                  Edit
                </Link>
              </td>
            </SortableItem>
          )}
        </tbody>
      </table>
    </SortableContext>
  );
};

export default function Accommodations() {
  const [accommodations, setAccommodations] = useState([]);
  const [availability, setAvailability] = useState([]);
  const [services, setServices] = useState([]);
  const [safety, setSafety] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const getAccommodations = async () => {
    try {
      const service = client.service("admin/accommodation");
      const query = {
        $limit: 1000,
        $sort: {
          order: 1,
        },
      };

      const result = await service.find({
        query,
      });
      setAccommodations(result.data);
      setServices(result.data.filter((accom) => accom.type === "services"));
      setAvailability(
        result.data.filter((accom) => accom.type === "availability")
      );
      setSafety(result.data.filter((accom) => accom.type === "safety"));
    } catch (e) {
      console.log("error", e);
    }
  };

  const onNewAccommodation = (newAccommodation) => {
    setAccommodations([...accommodations, newAccommodation]);
  };

  useEffect(() => {
    getAccommodations();
  }, []);

  const updateOrder = (accoms) => {
    // console.log(accoms.sort((a, b) => (a.type < b.type ? -1 : 1)));
    accoms.forEach(async (item, index) => {
      try {
        const service = client.service("admin/accommodation");
        await service.patch(item.id, { ...item, order: index });
      } catch (e) {
        console.log("error", e);
      }
    });
    // setAccommodations(result.data);
  };

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 2,
    },
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 100,
      tolerance: 5,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const handleOnDragEnd = async (event) => {
    if(!event.over){
      return;
    }
    const { active, over } = event;
    const startingContainer = active.data.current.sortable.containerId;
    const endingContainer = over.data.current.sortable.containerId;
    if (active.id === over.id) {
      return;
    }
    else {
      const accIds = accommodations.map((a) => a.id);
      const activeIndex = accIds.indexOf(active.id);
      const overIndex = accIds.indexOf(over.id);
      const newArray = arrayMove(accommodations, activeIndex, overIndex);
      if (startingContainer !== endingContainer) {
        const [changedItem] = newArray.splice(overIndex, 1);
        changedItem.type = endingContainer;
        newArray.splice(overIndex, 0, changedItem);
      }
      setAccommodations(newArray);
      updateOrder(newArray);
    }
  };
  
  return (
    <DndContext
      onDragEnd={handleOnDragEnd}
      collisionDetection={closestCenter}
      sensors={sensors}
    >
      <Page title="Accommodations">
        <Toaster
          toastOptions={{
            duration: 3000,
          }}
        />
        <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <nav className="flex" aria-label="Breadcrumb">
            <Breadcrumb elements={[{ name: "Accommodations" }]} />
          </nav>
          <PrimaryButton onClick={() => setShowCreateModal(true)}>
            New
          </PrimaryButton>
        </div>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                {/* ACCOMMODATION SECTIONS */}
                {['availability', 'services', 'safety', 'certifications'].map((type) => (
                  <SortableAccommodationSection
                    key={type}
                    type={type}
                    strategy={rectSortingStrategy}
                    items={accommodations}
                  />
                ))}
                {/* <Pagination total={total} limit={limit} page={page} navigateToPage={navigateToPage} /> */}
              </div>
            </div>
          </div>
        </div>
        {showCreateModal && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div
                className="fixed inset-0 transition-opacity"
                aria-hidden="true"
              >
                <div className="absolute inset-0 bg-gray-500 opacity-75" />
              </div>

              <div
                className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <NewAccommodationForm
                  onCancelClick={() => setShowCreateModal(false)}
                  setShowCreateModal={setShowCreateModal}
                  onNewAccommodation={onNewAccommodation}
                  icon={icon}
                  safety={safety}
                  services={services}
                  availability={availability}
                  listLength={accommodations.length}
                />
              </div>
            </div>
          </div>
        )}
      </Page>
    </DndContext>
  );
}
