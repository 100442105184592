import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { useSelector } from "react-redux";
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import client from '../../../lib/feathers';
import NewFlightForm from './NewFlightForm';
import Modal from '../../Modal';

export default function FlightsInfoPanel() {
  const { lid } = useParams();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const [showCreateModal, setShowCreateModal] = useState(false);

  const [flights, setFlights] = useState([]);

  const loadFlights = async () => {
    const service = client.service(`${user.role}/flight`);
    const response = await service.find({ query: { listingId: lid } });
    setFlights(response.data);
  }

  useEffect(() => {
    if (user) {
      loadFlights();
    }
  }, [user])

  const StatusChip = ({ status }) => {
    return (
      status === 'active' ?
        <span className={`inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20`}>Active</span>
        : status === 'draft' ?
          <span className={`inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20`}>Draft</span>
          : status === 'archived' ?
            <span className={`inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20`}>Archived</span>
            : null
    )
  };

  const FlightTable = ({ flights, status }) => {
    return (
      <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
        <div className="min-w-full py-2 align-middle sm:px-6 lg:px-8">
          {flights &&
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3 lg:table-cell">
                    Name
                  </th>
                  <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                    Created By
                  </th>
                  <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                    Date Updated
                  </th>
                  <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 xl:table-cell">
                    Date Created
                  </th>
                  <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 xl:table-cell">
                    Status
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                    <span className="sr-only">Manage</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {flights.filter((f) => status.includes(f.status)).map((flight, flightIndex) => (
                  <tr key={flight.id} className={flightIndex % 2 === 0 ? undefined : 'bg-gray-50'}>
                    <td>
                      <dl className="whitespace-nowrap py-1 pl-3 pr-3 text-sm font-medium text-gray-900 flex relative items-center">
                        <span className="flex items-center text-sm font-medium relative">
                          <span className="text-base lg:text-sm">
                            {flight.name}
                          </span>
                        </span>
                      </dl>
                      <dd className="whitespace-nowrap px-3 py-1 text-sm text-gray-500 xl:hidden">
                        <span>
                          <span className="text-black font-medium">Created By: </span>
                          {flight.createdBy.firstName} {flight.createdBy.lastName} ({flight.createdBy.role})
                          <br />
                          {flight.createdBy.email}
                          <br />
                        </span>
                      </dd>
                      <dd className="whitespace-nowrap px-3 py-1 text-sm font-medium xl:hidden">
                        Created:
                        <span className="text-gray-500 font-normal">
                          {` ${new Date(flight.createdAt).toDateString()}`}
                        </span>
                      </dd>
                      <dd className="whitespace-nowrap px-3 py-1 text-sm font-medium lg:hidden">
                        Updated:
                        <span className="text-gray-500 font-normal">
                          {` ${new Date(flight.updatedAt).toDateString()}`}
                        </span>
                      </dd>
                      <dd className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 xl:hidden">
                        <StatusChip status={flight.status} />
                      </dd>
                    </td>
                    <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                      <span>
                        {flight.createdBy.firstName} {flight.createdBy.lastName}
                        <br />
                        {flight.createdBy.email}
                      </span>
                    </td>
                    <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                      {new Date(flight.updatedAt).toDateString()}
                    </td>
                    <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 xl:table-cell">
                      {new Date(flight.createdAt).toDateString()}
                    </td>
                    <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 xl:table-cell">
                      <StatusChip status={flight.status} />
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                      <div className="flex-row lg:flex lg:justify-between">
                        <button className="items-center px-4 py-2 my-1 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-icon hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500" onClick={() => navigate(`/${user.role}/listings/${lid}/flights/${flight.id}/edit`)}>Edit</button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          }
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white shadow overflow-hidden px-5 py-5 sm:rounded-lg sm:p-6">
      <div className="sm:items-center py-2">
        <div className="sm:flex sm:items-center py-2">
          <div className="sm:flex-auto">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Flight List</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Here's a list of all active flights for this listing.
            </p>
          </div>
          <div className="mt-3 sm:mt-0 sm:ml-4">
            <button className="items-center px-4 py-2 my-1 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-icon hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500" onClick={() => setShowCreateModal(true)}>Create New Flight</button>
          </div>
        </div>

        <div>
          <FlightTable flights={flights} status={['active', 'draft']} />
        </div >
      </div >

      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex justify-between items-center">
              <h3 className="text-lg text-left h3t-3 leading-6 font-medium text-gray-900">
                Archived Flights
                {/* {requests.filter(request => request.status === null).length > 0
                  ? <span className="inline-flex items-center justify-center h-5 w-5 ml-2 rounded-full bg-icon text-white">{requests.filter(request => request.status === null).length}</span>
                  : null} */}
              </h3>
              <ChevronRightIcon
                className={`${open ? 'rotate-90 transform' : ''} h-7 w-7 text-lg leading-6  font-medium text-gray-900`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="">
              <div className="mt-8 flow-root">
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  A list of all archived flights for this listing.
                </p>
                <FlightTable flights={flights} status="archived" />
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      {showCreateModal
        && (
          <Modal>
            <NewFlightForm onCancelClick={() => setShowCreateModal(false)} />
          </Modal>
        )}
    </div>
  )
}
