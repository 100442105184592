import { ClipboardDocumentCheckIcon, ClipboardDocumentIcon } from '@heroicons/react/20/solid';
import React, { useState } from 'react';

export default function ClipboardButton({ text }) {
  const [success, setSuccess] = useState(false)

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(text).then(() => {
        setSuccess(true);
        setTimeout(() => setSuccess(false), 1500)
      });
    } catch (error) {
      console.log(error.message)
    }
  }

  return(
    <button
      className="flex items-center px-2 border border-transparent rounded-md text-sm font-medium text-icon bg-transparent hover:text-red-700 focus:outline-none active:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
      onClick={copyLink}
    >
      <div className={success ? "display:inline" : "hidden"}>
        <ClipboardDocumentCheckIcon className='h-5 w-5' />
      </div>
      <div className={success ? "hidden" : ""}>
        <ClipboardDocumentIcon className='h-5 w-5'/>
      </div>
      <span className={success ? "" : "invisible"}>Copied!</span>
    </button>
  )
}