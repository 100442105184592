import React, { useState, useEffect } from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import Page from '../../components/Page';
import client from '../../lib/feathers';

export default function Suggestions() {
  const [suggestions, setSuggestions] = useState([]);
  const [status, setStatus] = useState('new');
  const statusList = ['new', 'approved', 'rejected'];

  const getSuggestions = async () => {
    try {
      const service = client.service('admin/suggestion');
      const query = {
        $limit: 1000,
        $sort: {
          createdAt: 1,
        },
        status,
      };

      const result = await service.find({
        query,
      });

      setSuggestions(result.data);
    } catch (e) {
      console.log('error', e);
    }
  };

  const updateSuggestion = async (id, st) => {
    try {
      const service = client.service('admin/suggestion');
      const data = { status: st };

      const updated = await service.patch(id, data);
      console.log('update', updated);
      getSuggestions();
    } catch (e) {
      console.log('error updating suggestion', e);
    }
  };

  useEffect(() => {
    getSuggestions();
  }, [status]);
  return (
    <Page title="Suggestions">
      <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">

        <nav className="flex" aria-label="Breadcrumb">
          <Breadcrumb elements={[{ name: 'Suggestions' }]} />
        </nav>
        <div className="mt-3 sm:mt-0 sm:ml-4">
          <button onClick={() => getSuggestions()} type="button" className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
            Refresh
          </button>
        </div>
      </div>
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">Select a tab</label>
          <select onChange={(e) => setStatus(e.target.value)} value={status} id="tabs" name="tabs" className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md">
            {statusList.map((s) => <option key={s}>{s}</option>)}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {statusList.map((s) => {
                let styles = 'capitalize border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm';
                if (status === s) {
                  styles = 'capitalize border-gray-500 text-gray-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm';
                }
                return <a href="#" onClick={() => setStatus(s)} className={styles} aria-current="page" key={s}>{s}</a>;
              })}
            </nav>
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Name
                    </th>
                    {/* <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Title
                                    </th> */}
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      ListingId
                    </th>
                    {/* <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Role
                                    </th> */}
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {suggestions.map((l) => (
                    <tr className="hover:bg-gray-100 cursor-pointer" key={l['venue.name']}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10">
                            {/* <img className="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=4&amp;w=256&amp;h=256&amp;q=60" alt="" /> */}
                            <div className="h-10 w-10 rounded-full bg-primary pt-2 text-center text-white">CM</div>
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">
                              {l['listing.name']}
                            </div>
                            <div className="text-sm text-gray-500">
                              {l.type}
                            </div>
                            <div className="text-sm text-gray-500">
                              {l.suggestion}
                            </div>
                          </div>
                        </div>
                      </td>
                      {/* <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm text-gray-900">Regional Paradigm Technician</div>
                                            <div className="text-sm text-gray-500">Optimization</div>
                                        </td> */}
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-green-800">
                          <a href={`/listings/${l.listingId}`}>{l.listingId}</a>
                        </span>
                        <div className="text-sm text-gray-500">
                          {l.email}
                        </div>
                      </td>
                      {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            Admin
                                        </td> */}
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        {status === 'new' ? (
                          <>
                            <a href="#" onClick={(e) => { e.preventDefault(); updateSuggestion(l.id, 'approved'); }} className="text-red-600 hover:text-red-900">Approve</a>
                            {' '}
                            |
                            <a href="#" onClick={(e) => { e.preventDefault(); updateSuggestion(l.id, 'rejected'); }} className="text-red-600 hover:text-red-900">Reject</a>
                          </>
                        )
                          : <></>}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}
