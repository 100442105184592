import React, { useEffect, useState, useCallback } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from "react-redux";
import AsyncSelect from 'react-select/async';
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup";
import Select from "react-select";
import client from '../../../lib/feathers';
import Modal from '../../Modal';
import Page from '../../Page';
// import Breadcrumb from '../Breadcrumb';
import PrimaryButton from '../../PrimaryButton';
import getVintages from '../../../utilities/getVintages';
import {
  faCircleQuestion,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  MDXEditor,
  UndoRedo,
  BoldItalicUnderlineToggles,
  toolbarPlugin,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import MergeLibationModal from './MergeLibationModal';

const schema = yup.object({
  name: yup.string().required("Please enter a name for this wine"),
  vintage: yup.string().required("Please enter a vintage for this wine"),
  winery: yup.object().required("Please enter a winery for this wine"),
  abv: yup.number()
  .nullable()
  .transform((_, val) => (val !== "" ? Number(val) : null))
  .optional()
  .min(0, "ABV cannot be less than 0")
  .max(100, "ABV cannot be more than 100")
});

const Tooltip = ({ children }) => (
  <div className="group/info">
    <span className="mr-1 flex w-4 h-4 bg-black rounded-full me-1.5 flex-shrink-0"><FontAwesomeIcon icon={faCircleQuestion} className="text-white" /></span>
    <span className="hidden absolute z-10 -top-6 rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20 group-hover/info:block">
      {children}
    </span>
  </div>
);

export default function LibationForm() {
  

  const { lid, wid } = useParams();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);

  const [currentLibation, setCurrentLibation] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showMergeModal, setShowMergeModal] = useState(false);

  // Lists of varietals, types, and vintages for Selectors
  const [varietals, setVarietals] = useState();
  const [types, setTypes] = useState([]);
  const vintageList = getVintages();

  const [isLoading, setIsLoading] = useState();

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const loadLibation = async () => {
    if (user) {
      setIsLoading(true);
      const service = client.service(`${user.role}/libation`);
      const response = await service.get(wid);
      setCurrentLibation(response);
      setValue('name', response.name);
      setValue('winery', { value: response.listing.id, label: response.listing.name });
      setValue('description', response.description);
      setValue('tastingNotes', response.tastingNotes);
      setValue('varietalId', response.varietalId);
      setValue('vintage', response.vintage);
      setValue('abv', response.abv);
      setValue('ava', response.ava);
      setValue('msrp', response.msrp);
      setValue('libationTypeId', response.libationTypeId);
      setValue('status', response.status);

      setIsLoading(false);
    }
  }
  const loadVarietals = async () => {
    const service = client.service(`${user.role}/varietal`);
    const response = await service.find();
    const options = response.data.map((varietal) => ({ ...varietal, label: varietal.name, value: varietal.id }));
    setVarietals(options);
  };

  const loadTypes = async () => {
    const service = client.service(`${user.role}/libation-type`);
    const response = await service.find();
    const options = response.data.map((type) => ({ ...type, label: type.name, value: type.id }));
    setTypes(options);
  };

  const loadSelectors = () => {
    if (user) {
      loadVarietals();
      loadTypes();
    }
  };

  const sortTypes = (options) => {
    const givenOptions = [...options]
    const commonTypes = ['Red', 'White', 'White - Sparkling', 'Rosé', 'Rosé - Sparkling'];
    const common = [];
    commonTypes.forEach((type) => {
      const i = givenOptions.findIndex((element) => element.label === type)
      if (i != -1) {
        common.push(givenOptions[i])
        givenOptions.splice(i, 1)
      }
    })
    return [
      {
        label: 'Common',
        options: common,
      },
      {
        label: 'All',
        options: options,
      }
    ]
  }

  useEffect(() => {
    loadLibation();
    loadSelectors();
  }, [wid, user]);

  const handleUpdateLibation = async (data) => {
    try {
      const service = client.service(`${user.role}/libation`)
      await service.patch(currentLibation.id, {
        ...data,
        needsReview: currentLibation.needsReview && user.role === 'admin',
        listingId: data.winery.value,
      });

      navigate(`/${user.role}/listings/${lid}/wines`);
    } catch (error) {
      console.error("Error updating libation: ", error);
    }
  }

  const handleDeleteLibation = async () => {
    const service = client.service(`${user.role}/libation`);
    await service.remove(wid);
    navigate(`/${user.role}/listings/${lid}/wines`);
  };

  // Listing search for admin updating wine's winery
  const loadListings = async (input, callback) => {
    const service = client.service('admin/listing');

    const q = {
      $sort: {
        name: -1,
      },
      name: { $iLike: `%${input}%` },
    };

    const result = await service.find({
      query: q,
    });

    callback(result.data.map((l) => ({
      value: l.id,
      label: l.name,
    })));
  };

  const hasAssociatedData = (currentLibation?.counts?.userSips > 0 || currentLibation?.counts?.flights > 0);
  const contactMessage = <p>Please contact <a href="mailto:service@vinoseeker.com" className="underline">service@vinoseeker.com</a> to request any merges or deletions.</p>;

  const SaveToolBar = () => (
    <div className="flex justify-between pt-5 relative">
      <div className="flex">
        <PrimaryButton
          className="disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-primary"
          onClick={() => setShowDeleteModal(true)}
        >
          Delete
        </PrimaryButton>
        {user.role === "admin" && (
          <div className="group/merge">
            <button
              disabled={!hasAssociatedData}
              className="disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-white disabled:hover:text-primary inline-flex mx-2 items-center px-4 py-2 border border-primary rounded-md shadow-sm text-sm font-medium text-primary bg-white hover:bg-red-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-900"
              onClick={() => setShowMergeModal(true)}
            >
              Merge
            </button>
            {!hasAssociatedData
              && (
                <span className="hidden absolute z-10 -top-3 rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20 group-hover/merge:block">
                  This wine does not have any associated data to merge.
                </span>
              )}
          </div>
        )}
      </div>
      <div>
        <Link
          to={`/${user?.role}/listings/${lid}/wines`}
          type="button"
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        >
          Cancel
        </Link>
        <PrimaryButton type="submit">Save</PrimaryButton>
      </div>
    </div>
  );

  // Tailwind Styles //
  const errorFocus = 'border-red-400 focus:border-red-500 focus:ring-red-500';
  const nonErrorFocus = 'border-gray-300 focus:ring-gray-500 focus:border-gray-500';

  let statusOptions = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
    { label: "Library", value: "library" },
    { label: "Member", value: "member" }
  ];

  return (
    <Page>
      {!isLoading ? (
        <>
          <form onSubmit={handleSubmit(handleUpdateLibation)}>
            <SaveToolBar />

            {/* CARD */}
            <div className="bg-white shadow px-4 py-5 sm:rounded-lg my-5">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900 inline-block align-middle mr-2">
                    Wine Information
                  </h3>
                  {!currentLibation?.needsReview
                    ? <span className="inline-flex items-center rounded-md bg-green-50 p-1 text-xs text-green-700 ring-1 ring-inset ring-green-600/20">Approved</span>
                    : <span className="inline-flex items-center rounded-md bg-yellow-50 p-1 text-xs text-yellow-700 ring-1 ring-inset ring-yellow-600/20">Pending</span>
                  }
                  <br />
                  <p className="mt-1 text-sm text-gray-500">{currentLibation?.needsReview ? 'Review ' : 'Update '} the information for {currentLibation ? ` ${currentLibation.name}` : ' this wine.'}</p>
                  <br />
                  {currentLibation && (
                    <>
                      <dl className="whitespace-nowrap pb-1 py-1 text-sm font-medium text-gray-900 flex relative items-center">
                        <span className="flex items-center text-sm font-medium relative">
                          {currentLibation.createdBy !== null && (
                            <span>
                              <span className="text-black font-medium">Added By: </span>
                              <span className="text-sm text-gray-500">
                                <span className="capitalize">
                                  {currentLibation.createdBy?.firstName} {currentLibation.createdBy?.lastName} - {currentLibation.createdBy?.role}
                                </span>
                                <br />
                                {user?.role === 'admin' ? currentLibation.createdBy.email : ''}
                              </span>
                            </span>
                          )}
                        </span>
                      </dl>
                      <dd className="whitespace-nowrap py-1 text-sm text-gray-500">
                        {currentLibation.createdAt && (
                          <span><span className="text-black font-medium">Added On: </span>{new Date(currentLibation.createdAt).toDateString()}</span>
                        )}
                      </dd>
                      <dd className="whitespace-nowrap py-5 text-sm text-gray-500" >
                        <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                          Status
                        </label>
                        <Controller
                          name="status"
                          control={control}
                          render={({ field: { onChange, value, ref } }) => (
                            <Select
                              ref={ref}
                              options={statusOptions}
                              value={statusOptions.find((s) => s.value === value)}
                              onChange={(val) => onChange(val.value)}
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  width: '50%',
                                }),
                              }}
                            />
                          )}
                        />
                      </dd>
                    </>
                  )}
                </div>

                <div className="mt-5 md:mt-0 md:col-span-2">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6">
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        {...register("name")}
                        className={`mt-1  block w-full shadow-sm sm:text-sm ${errors.name ? errorFocus : nonErrorFocus} rounded-md`}
                      />
                      {errors.name && <span className="text-red-400">{errors.name.message}</span>}
                    </div>

                    <div className="col-span-6">
                      <div className="flex gap-1 items-center relative">
                        <label htmlFor="winery" className="block text-sm font-medium text-gray-700">
                          Winery
                        </label>
                        {/* Admin change listing message */}
                        {user && user.role === 'admin' && dirtyFields.winery && (
                          <span className="w-auto relative -top-1 inline-flex items-center rounded-md bg-yellow-50 px-1 text-sm text-yellow-700 ring-1 ring-inset ring-yellow-600/20">
                            Updating a wine&apos;s listing will move the wine to that listing&apos;s page
                          </span>
                        )}
                        {/* Industry change listing message */}
                        {user && user.role === 'industry' && (
                          <Tooltip>
                            To update this wine&apos;s listing, please contact support at service@vinoseeker.com
                          </Tooltip>
                        )}
                      </div>
                      {/* ADMIN VIEW OF WINERY */}
                      {user?.role === "admin" ? (
                        <>
                          <Controller
                            name="winery"
                            control={control}
                            render={({ field: { onChange, value, ref } }) => (
                              <AsyncSelect
                                ref={ref}
                                value={value}
                                isClearable
                                loadOptions={loadListings}
                                onChange={onChange}
                                className="w-full"
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.winery ? 'red' : '#CCCCCC',
                                  }),
                                }}
                              />
                            )}
                          />
                          {errors.winery && <span className="text-red-400">{errors.winery.message}</span>}
                        </>
                      ) : (
                        // LISTING VIEW OF WINERY
                        <input
                          type="text"
                          // id="winery"
                          disabled={true}
                          placeholder={currentLibation?.listing?.name}
                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 disabled:bg-gray-100 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md"
                        >
                        </input>
                      )}
                    </div>

                    <div className="col-span-6">
                      <div className="flex gap-1 items-center relative">
                        <label htmlFor="varietalId" className="block text-sm font-medium text-gray-700">
                          AVA
                        </label>
                        <Tooltip>
                          American Viticultural Area
                        </Tooltip>
                      </div>
                      <input
                        type="text"
                        name="ava"
                        placeholder="AVA"
                        {...register("ava")}
                        className={`block w-full shadow-sm sm:text-sm rounded-md ${nonErrorFocus}`}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label htmlFor="libationTypeId" className="block text-sm font-medium text-gray-700">
                        Type
                      </label>
                      {types && (
                        <Controller
                          name="libationTypeId"
                          control={control}
                          render={({ field: { onChange, value, ref } }) => (
                            <Select
                              ref={ref}
                              options={sortTypes(types)}
                              value={types.find((v) => v.id == value)}
                              onChange={(val) => onChange(val.value)}
                            />
                          )}
                        />
                      )}
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label htmlFor="varietalId" className="block text-sm font-medium text-gray-700">
                        Varietal
                      </label>
                      {varietals && (
                        <Controller
                          name="varietalId"
                          control={control}
                          render={({ field: { onChange, value, ref } }) => (
                            <Select
                              ref={ref}
                              options={varietals}
                              value={varietals.find((v) => v.id == value)}
                              onChange={(val) => onChange(val.value)}
                            />
                          )}
                        />
                      )}
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label htmlFor="vintage" className="block text-sm font-medium text-gray-700">
                        Vintage
                      </label>
                      {vintageList && (
                        <Controller
                          name="vintage"
                          control={control}
                          render={({ field: { onChange, value, ref } }) => (
                            <Select
                              ref={ref}
                              options={vintageList}
                              value={vintageList.find((v) => v.value === value)}
                              onChange={(val) => onChange(val.value)}
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.varietalId ? 'red' : '#CCCCCC',
                                }),
                              }}
                            />
                          )}
                        />
                      )}
                      {errors.vintage && <span className="text-red-400">{errors.vintage.message}</span>}
                    </div>
                    {/* MDX toolbar z index changed to avoid overlap with search menu */}
                    <div className="col-span-6 z-0">
                      <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                        Description
                      </label>
                      <Controller
                        name="description"
                        control={control}
                        render={({ field: { onChange, value, ref } }) => (
                          <MDXEditor
                            ref={ref}
                            markdown={value || ''}
                            onChange={onChange}
                            placeholder={"Enter a detailed description and any additional information."}
                            className="border rounded-lg mt-1 h-52 overflow-y-auto [&_li]:ml-4 [&_ul]:list-disc [&_ol]:list-decimal"
                            plugins={
                              [
                                listsPlugin(),
                                markdownShortcutPlugin(),
                                toolbarPlugin({
                                  toolbarContents: () => (
                                    <>
                                      {' '}
                                      <UndoRedo />
                                      <BoldItalicUnderlineToggles />
                                      <ListsToggle />
                                    </>
                                  )
                                })
                              ]}
                          />
                        )}
                      />
                    </div>

                    {/* MDX toolbar z index changed to avoid overlap with search menu */}
                    <div className="col-span-6 z-0">
                      <label htmlFor="tastingNotes" className="block text-sm font-medium text-gray-700">
                        Tasting Notes
                      </label>
                      <Controller
                        name="tastingNotes"
                        control={control}
                        render={({ field: { onChange, value, ref } }) => (
                          <MDXEditor
                            ref={ref}
                            markdown={value || ''}
                            onChange={onChange}
                            placeholder={"Enter tasting notes of 1-2 sentences for this wine."}
                            className="border rounded-lg mt-1 h-52 overflow-y-auto [&_li]:ml-4 [&_ul]:list-disc [&_ol]:list-decimal"
                            plugins={
                              [
                                markdownShortcutPlugin(),
                                toolbarPlugin({
                                  toolbarContents: () => (
                                    <>
                                      {' '}
                                      <BoldItalicUnderlineToggles />
                                    </>
                                  )
                                })
                              ]}
                          />
                        )}
                      />
                    </div>

                    <div className="relative col-span-6 sm:col-span-3 lg:col-span-3">
                      <div className="flex gap-1 items-center relative">
                        <label htmlFor="varietalId" className="block text-sm font-medium text-gray-700">
                          ABV
                        </label>
                        <Tooltip>
                          Alcohol by Volume
                        </Tooltip>
                      </div>
                      <div className="relative">
                        <span className="absolute flex inset-y-4 left-2 items-center text-sm">%</span>
                        <input
                          name="abv"
                          type="number" 
                          step={0.1}
                          placeholder={'0.00'}
                          {...register("abv")}
                          className={`block w-full pl-7 shadow-sm sm:text-sm ${errors.abv ? errorFocus : nonErrorFocus} rounded-md`}
                        />
                      </div>
                      {errors.abv && <span className="text-red-400">{errors.abv.message}</span>}
                    </div>

                    <div className="relative col-span-6 sm:col-span-3 lg:col-span-3">
                      <div className="flex gap-1 items-center relative">
                        <label htmlFor="varietalId" className="block text-sm font-medium text-gray-700">
                          MSRP
                        </label>
                        <Tooltip>
                          Manufacturer&apos;s Suggested Retail Price
                        </Tooltip>
                      </div>
                      <div className="relative">
                        <span className="absolute flex inset-y-4 left-2 items-center text-sm">$</span>
                        <input
                          name="msrp"
                          type="number" 
                          min={0.00} 
                          step={0.01}
                          placeholder={'0.00'}
                          {...register("msrp")}
                          className={`block w-full pl-5 shadow-sm sm:text-sm rounded-md ${nonErrorFocus}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </>
      ) : (
        <p>Loading Wine...</p>
      )
      }

      {
        showDeleteModal
        && (
          <Modal>
            <div className="mt-3 text-center sm:mt-5">
              {!hasAssociatedData && (
                <>
                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                  Confirm Deletion
                </h3>
                <div className="mt-2">
                  <p className="text-base text-gray-500">
                    Are you sure you would like to delete this wine?
                  </p>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    <button type="button" onClick={handleDeleteLibation} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-icon text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 hover:bg-red-700 sm:col-start-2 sm:text-sm">Confirm</button>
                    <button type="button" onClick={() => setShowDeleteModal(false)} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:col-start-1 sm:text-sm">Cancel</button>
                  </div>
                </div>
                </>
              )}
              {hasAssociatedData && (
                <>
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                    Delete Unavailable
                  </h3>
                  <div className="mt-2">
                    <p className="text-base text-gray-500">
                      This wine has associated data and cannot be deleted.
                      {user.role !== 'admin' ? contactMessage : ' It may be merged with another wine instead.'}
                    </p>
                    <div className="mt-5 sm:mt-6 ">
                      <button type="button" onClick={() => setShowDeleteModal(false)} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:col-start-1 sm:text-sm">OK</button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Modal>
        )
      }

      {showMergeModal
        && (
          <Modal>
            <MergeLibationModal
              onCancelClick={() => setShowMergeModal(false)}
              libation={currentLibation}
            />
          </Modal>
        )
      }
    </Page >
  )
}
