export const formatIcon = (iconName) => {
  if (iconName[0] === 'f' && iconName[1] === 'a') return iconName;
  const newIcon = `fa-${iconName}`;
  const camelCased = newIcon.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
  return camelCased;
};

export const anotherFunction = (something) => {
  if (something) {
    return something;
  }
  return something;
};
