import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { useSelector } from 'react-redux';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import client from '../../../lib/feathers';
import NewItineraryForm from './NewItineraryForm';
import CloneItineraryForm from './CloneItineraryForm';
import Modal from '../../Modal';
import ClipboardButton from '../../ClipboardButton'

export default function ItineraryInfoPanel() {
  const { lid } = useParams();
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.user);

  const [showCreateModal, setShowCreateModal] = useState(false);

  const [showCloneModal, setShowCloneModal] = useState(false);
  const [listToClone, setListToClone] = useState();

  const [itineraries, setItineraries] = useState([]);
  
  const loadItineraries = async () => {
    const service = client.service(`${user.role}/listing-list`);
    const response = await service.find({ query: { listingId: lid } });
    setItineraries(response.data);
  };
  useEffect(() => {
    if (user) {
      loadItineraries();
    }
  }, [user]);

  const AccessChip = ({ access }) => {
    return access === 'public' ? (
      <span className={`inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20`}>Public</span>
    ) : (
      <span className={`inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-red-600/20`}>Archived</span>
    );
  };


  const ItineraryTable = ({ itineraries, access }) => {
    return (
      <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
        <div className="min-w-full py-2 align-middle sm:px-6 lg:px-8">
          {itineraries && (
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3 lg:table-cell">
                    Name
                  </th>
                  <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                    Created By
                  </th>
                  <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                    Date Updated
                  </th>
                  <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 xl:table-cell">
                    Date Created
                  </th>
                  <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 xl:table-cell">
                    Stops
                  </th>
                  <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 xl:table-cell">
                    Status
                  </th>
                  <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 xl:table-cell">
                    Public Link
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                    <span className="sr-only">Manage</span>
                  </th>

                </tr>
              </thead>
              <tbody className="bg-white">
                {itineraries.filter((i) => access.includes(i.access)).map((itinerary, itineraryIndex) => (
                  <tr key={itinerary.id} className={itineraryIndex % 2 === 0 ? undefined : 'bg-gray-50'}>
                    <td>
                      <dl className="whitespace-normal py-1 pl-3 pr-3 text-sm font-medium text-gray-900 flex relative items-center">
                        <span className="flex items-center text-sm font-medium relative">
                          <span className="text-base lg:text-sm">{itinerary.name}</span>
                        </span>
                      </dl>
                      <dd className="whitespace-nowrap px-3 py-1 text-sm text-gray-500 xl:hidden">
                        <span>
                          <span className="text-black font-medium">Created By: </span>
                          {itinerary.user.firstName} {itinerary.user.lastName}
                        </span>
                      </dd>
                      <dd className="whitespace-nowrap px-3 py-1 text-sm font-medium xl:hidden">
                        Created:
                        <span className="text-gray-500 font-normal">{` ${new Date(itinerary.createdAt).toDateString()}`}</span>
                      </dd>
                      <dd className="whitespace-nowrap px-3 py-1 text-sm font-medium lg:hidden">
                        Updated:
                        <span className="text-gray-500 font-normal">{` ${new Date(itinerary.updatedAt).toDateString()}`}</span>
                      </dd>
                      <dd className="whitespace-nowrap px-3 py-1 text-sm font-medium xl:hidden">
                        Stops:&nbsp;
                        <span className="text-gray-500 font-normal">{itinerary.listingListListings.length}</span>
                      </dd>
                      <dd className="whitespace-nowrap px-3 py-1 text-sm font-medium lg:hidden">
                        Copy Public Link: 
                        <ClipboardButton text={`${process.env.REACT_APP_CLIENT_URL}/lists/${itinerary.id}`} />
                      </dd>
                      <dd className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 xl:hidden">
                        <AccessChip access={itinerary.access} />
                      </dd>
                      
                    </td>
                    <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                      <span>
                        {itinerary.user.firstName} {itinerary.user.lastName}
                      </span>
                    </td>
                    <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">{new Date(itinerary.updatedAt).toDateString()}</td>
                    <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 xl:table-cell">{new Date(itinerary.createdAt).toDateString()}</td>
                    <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 xl:table-cell">{itinerary.listingListListings.length}</td>
                    <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 xl:table-cell">
                      <AccessChip access={itinerary.access} />
                    </td>
                    <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                      <ClipboardButton text={`${process.env.REACT_APP_CLIENT_URL}/lists/${itinerary.id}`} />
                    </td>
                    <td className="lg:flex">                      
                      <div className="relative whitespace-nowrap py-4 text-right text-sm font-medium sm:pr-3">
                        <div className="flex-row lg:flex lg:justify-center gap-x-4">
                          <button
                            className="items-center w-16 py-2 my-1 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-icon hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                            onClick={() => navigate(`/${user.role}/listings/${lid}/itineraries/${itinerary.id}/edit`)}
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                      <div className="relative whitespace-nowrap py-4 text-right text-sm font-medium sm:pr-3">
                        <div className="flex-row lg:flex lg:justify-center">
                          <button
                            className="items-center text-center w-16 py-2 my-1 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-icon hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                            onClick={() => {
                              setListToClone(itinerary)
                              setShowCloneModal(true) }}
                          >
                            Clone
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white shadow overflow-hidden px-5 py-5 sm:rounded-lg sm:p-6">
      <div className="sm:items-center py-2">
        <div className="sm:flex sm:items-center py-2">
          <div className="sm:flex-auto">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Itinerary List</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Here's a list of all active itineraries for this industry listing.</p>
          </div>
          <div className="mt-3 sm:mt-0 sm:ml-4">
            <button
              className="items-center px-4 py-2 my-1 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-icon hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              onClick={() => setShowCreateModal(true)}
            >
              Create New Itinerary
            </button>
          </div>
        </div>
        <div>
          <ItineraryTable itineraries={itineraries} access={['public']} />
        </div>
      </div>

      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex justify-between items-center">
              <h3 className="text-lg text-left h3t-3 leading-6 font-medium text-gray-900">
                Archived Itineraries
              </h3>
              <ChevronRightIcon className={`${open ? 'rotate-90 transform' : ''} h-7 w-7 text-lg leading-6  font-medium text-gray-900`} />
            </Disclosure.Button>
            <Disclosure.Panel className="">
              <div className="mt-8 flow-root">
                <p className="mt-1 max-w-2xl text-sm text-gray-500">A list of all archived itineraries for this listing.</p>
                <ItineraryTable itineraries={itineraries} access={['private', 'unlisted', 'protected']} />
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      {showCreateModal && (
        <Modal>
          <NewItineraryForm onCancelClick={() => setShowCreateModal(false)} />
        </Modal>
      )}
      {showCloneModal && (
        <Modal>
          <CloneItineraryForm itinerary={listToClone} onCancelClick={() => setShowCloneModal(false)} />
        </Modal>
      )}
    </div>
  );
}
