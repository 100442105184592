import { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Popup } from 'react-leaflet';
import AssignMarkerIcon from '../../ListingStatusIcon';
import { useSelector } from 'react-redux';

export default function LocationInfoPanel(props) {
  const listing = useSelector((s) => s.listings.selected);

  const [homeMarker, setHomeMarker] = useState();
  useEffect(() => {
    if ( listing.location ){
      setHomeMarker({
        lat: listing.location.coordinates[1],
        lon: listing.location.coordinates[0],
      });
    }
  }, [])


  
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg px-4 py-5 sm:p-6">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Location Information
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
        </p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Lat/Lon
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {listing.location?.coordinates[1]}
              ,
              <span>&nbsp;</span>
              {listing.location?.coordinates[0]}
            </dd>
          </div>
        </dl>
      </div>
      <div className="relative h-96">
        {homeMarker &&
        <MapContainer center={[homeMarker.lat, homeMarker.lon]} zoom={13} scrollWheelZoom={false}>
          <TileLayer
            // url="https://tile.thunderforest.com/atlas/{z}/{x}/{y}.png?apikey=c35a9a5391864187a941331cbb24be36"
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <AssignMarkerIcon listingStatus={listing.status} position={[homeMarker.lat, homeMarker.lon]}>
            <Popup>
            {listing.name}
            </Popup>
          </AssignMarkerIcon>
        </MapContainer>
        }
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Address
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {listing.address ? (
                <div>
                  {listing.address.address1}
                  <br />
                  {listing.address.address2}
                  <br />
                  {listing.address.city}
                  ,
                  <span>&nbsp;</span>
                  {listing.address.state}
                  <span>&nbsp;</span>
                  {listing.address.zip}
                </div>
              ) : (
                <div>Not Entered</div>
              )}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  )
}