import { useDropzone } from "react-dropzone";
import { useState, useEffect, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import client from "../../../lib/feathers";
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableItem } from "../SortableItem";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import FeatureDisabled from '../../FeatureDisabled'
import featureLevels from "../../../lib/featureLevels";
import { useDispatch, useSelector } from "react-redux";
import { setListing } from "../../../lib/listingsSlice";

export default function UploadPhotoPanel(props) {
  const dispatch = useDispatch();
  const listing = useSelector((s) => s.listings.selected);
  const user = useSelector((s) => s.auth.user);

  const onAttachmentsChange = (attachments) => {
    const l = { ...listing, attachments };
    dispatch(setListing(l));
  }
  const onDrop = useCallback((acceptedFiles) => {
    console.log("dropped", acceptedFiles);
    uploadFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const [filesToUpload, setFilesToUpload] = useState(); // form field data for uploads
  const [uploadingFiles, setUploadingFiles] = useState({}); // display file upload progress

  const updateUploadingFiles = (file) => {
    const uf = { ...uploadingFiles };
    uf[file.path] = file;
    setUploadingFiles(uf);
  };
  const removeUploadingFile = (file) => {
    const uf = { ...uploadingFiles };
    delete uf[file.path];
    setUploadingFiles(uf);
  };
  const onDeleteAttachmentClick = async (attachment) => {
    const proceed = confirm("Are you sure you want to delete this file?"); // eslint-disable-line no-restricted-globals
    // console.log('at', attachment);
    if (proceed) {
      const attachmentService = client.service(`${user.role}/attachment`);
      const rattachment = await attachmentService.remove(attachment.id);
      const attachments = [];

      listing.attachments.forEach((at) => {
        if (at.id !== rattachment.id) {
          attachments.push(at);
        }
      });

      if (onAttachmentsChange) {
        onAttachmentsChange(attachments);
      }
      toast.success("Deleted your attachment");
    }
  };

  const uploadFiles = (files) => {
    setFilesToUpload(files);
  };

  useEffect(() => {
    if (filesToUpload) {
      const uploads = filesToUpload.map(
        async (aFile) =>
          new Promise((resolve) => {
            const uploadFile = async (file) => {
              updateUploadingFiles(file);

              const id = uuidv4();
              const uploadService = client.service(
                `${user.role}/attachment-upload`
              );
              const data = {
                "file-name": `listing-images/${id}`,
                "file-type": file.type,
                id: listing.id,
              };
              const urls = await uploadService.create(data);
              const signedUrl = urls.signedRequest;

              const options = {
                headers: {
                  "Content-Type": file.type,
                },
                onUploadProgress: function (progressEvent) {
                  var percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  file.progress = percentCompleted;
                  updateUploadingFiles(file);
                },
              };

              await axios.put(signedUrl, file, options);

              const atd = {
                id,
                path: urls.path,
                type: "photo",
                mime: file.type,
                listingId: listing.id,
              };
              const attachmentService = client.service(
                `${user.role}/attachment`
              );
              const attachment = await attachmentService.create(atd);

              const attachmentListings = {
                attachmentId: id,
                listingId: listing.id,
                userId: user.id,
              };
              const attachmentListingService = client.service(
                `${user.role}/attachment-listing`
              );
              await attachmentListingService.create(attachmentListings);
              removeUploadingFile(file);
              resolve(attachment);
            };
            uploadFile(aFile);
          })
      );

      Promise.all(uploads).then((attachments) => {
        // const newListing = {
        //   ...listing,
        //   attachments: [...listing.attachments, ...attachments],
        // };
        if (onAttachmentsChange) {
          onAttachmentsChange([...listing.attachments, ...attachments]);
        }
        // listing.attachments = [...listing.attachments, ...attachments];
        setFilesToUpload(undefined);
      });
      // console.log(uploadFile);
    }
  }, [filesToUpload]);

  const handleOnDragEnd = async (event) => {
    if (!event.over) {
      return;
    }
    const { active, over } = event;
    const photoArray = listing.attachments;
    if (active.id !== over.id) {
      const photoIds = photoArray.map((p) => p.id);
      const activeIndex = photoIds.indexOf(active.id);
      const overIndex = photoIds.indexOf(over.id);
      const newArray = arrayMove(photoArray, activeIndex, overIndex);
      const reorderedIds = newArray.map((p) => p.id);
      const reorderService = client.service(
        `${user.role}/listing-attachment-reorder`
      );
      onAttachmentsChange(newArray);
      await reorderService.update(listing.id, reorderedIds);
    }
  };

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 2,
    },
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 100,
      tolerance: 5,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  return (
    <>
    <FeatureDisabled
        flag={featureLevels.UPLOAD_GALLERY_PHOTOS}
        level={listing.featureLevel}
      >
      <DndContext
        onDragEnd={handleOnDragEnd}
        collisionDetection={closestCenter}
        sensors={sensors}
      >
        <Toaster position="top-center" reverseOrder={false} />
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Photos ({listing.attachments.length})
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Business Venue and Product images
            </p>
          </div>
          {!filesToUpload ? (
            <div className="col-span-3">
              <div
                {...getRootProps()}
                className="mt-1 border-2 border-gray-300 border-dashed rounded-md p-6 mx-5  flex justify-center"
              >
                <div className="space-y-1 text-center ">
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="flex text-sm text-gray-600">
                    {/* <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"> */}
                    {isDragActive ? (
                      <span>Drop Files Here</span>
                    ) : (
                      <span>Click to Upload a file or drag and drop</span>
                    )}

                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                      accept="image/*"
                      multiple
                      {...getInputProps()}
                    />
                    {/* </label> */}
                  </div>
                  <p className="text-xs text-gray-500">
                    PNG, JPG, GIF up to 10MB
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div>Uploading Files</div>
          )}
          <SortableContext
            items={listing.attachments}
            strategy={rectSortingStrategy}
          >
            <div className="grid justify-center md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 pt-2">
              {listing.attachments.map((attachment, index) => (
                <SortableItem
                  key={attachment.id}
                  id={attachment.id}
                  index={index}
                  className="touch-manipulation"
                >
                  <div className="attachementWrapper relative hover:border-gray-200 m-2">
                    <span
                      className="absolute top-0 right-0 p-1 delete"
                      onClick={() => {
                        onDeleteAttachmentClick(attachment);
                      }}
                    >
                      <svg
                        className="h-12 w-12 fill-current text-grey hover:text-grey-darkest bg-white"
                        role="button"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <title>Close</title>
                        <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                      </svg>
                    </span>
                    <img
                      alt="gallery item"
                      src={`${process.env.REACT_APP_ATTACHMENT_URL}/${attachment.path}?width=300`}
                      width="300"
                    />
                  </div>
                </SortableItem>
              ))}
              {Object.keys(uploadingFiles).length > 0 &&
                filesToUpload.map((uploadingFile) => (
                  <div
                    className="attachementWrapper relative hover:border-gray-200 m-2"
                    key={uploadingFile.path}
                  >
                    <span className="absolute top-0 right-0 p-1 bg-black text-white font-bold">
                      Uploading{" "}
                      {uploadingFile.progress && (
                        <span>{uploadingFile.progress}%</span>
                      )}
                    </span>
                    <img
                      alt="gallery item"
                      src={URL.createObjectURL(uploadingFile)}
                      width="300"
                    />
                  </div>
                ))}
            </div>
          </SortableContext>
        </div>
      </DndContext>
      </FeatureDisabled>
    </>
  );
}
