import React, { useRef, useMemo } from 'react';
import L from 'leaflet';
import 'leaflet.awesome-markers'
import { Marker } from 'react-leaflet';

function DraggableMarker(props) {
  const { position, onLocationChange } = props; 

  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          const {lat:latitude, lng:longitude} = marker.getLatLng();
          onLocationChange({latitude, longitude});
        }
      },
    }),
    [],
  );

  // featured marker
  const customMarkerIcon = new L.icon({
    iconUrl: '/assets/favicon.png',
    shadowUrl: '/assets/shadow.png',
    iconSize: [32, 35],
    iconAnchor: [16, 35],
    shadowSize: [35, 35],
    shadowAnchor: [8, 37],
    className: 'awesome-marker-icon',
  });

  return (
      <Marker
        draggable={true}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}  
        icon={customMarkerIcon}   
      >
      </Marker>
  );
}

export default DraggableMarker;
