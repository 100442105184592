import React from 'react';
import L from 'leaflet';
import 'leaflet.awesome-markers'
import { Marker } from 'react-leaflet';

function AssignMarkerIcon(props) {
  const { listingStatus, position } = props; 

  // featured marker
  const featuredMarkerIcon = new L.icon({
  iconUrl: '/assets/favicon.png',
  shadowUrl: '/assets/shadow.png',
  iconSize: [40 ,40],
  iconAnchor: [20, 40],
  shadowSize: [35, 35],
  shadowAnchor: [8, 37],
  className: 'awesome-marker-icon',
  });

  // claimed marker
  const claimedMarkerIcon = new L.icon({
  iconUrl: '/assets/claimedMarker.png',
  shadowUrl: '/assets/shadow.png',
  iconSize: [30, 40],
  iconAnchor: [15, 40],
  shadowSize: [32, 35],
  shadowAnchor: [8, 37],
  className: 'awesome-marker-icon',
  });

  // unverified marker
  const unverifiedMarkerIcon = new L.icon({
  iconUrl: '/assets/unverifiedMarker.png',
  shadowUrl: '/assets/shadow.png',
  iconSize: [32, 35],
  iconAnchor: [16, 35],
  shadowSize: [32, 35],
  shadowAnchor: [8, 37],
  className: 'awesome-marker-icon',
  });

  // verified marker
  const verifiedMarkerIcon = new L.icon({
  iconUrl: '/assets/verifiedMarker.png',
  shadowUrl: '/assets/shadow.png',
  iconSize: [32, 35],
  iconAnchor: [16, 35],
  shadowSize: [35, 35],
  shadowAnchor: [8, 37],
  className: 'awesome-marker-icon',
  });

  return (
        (() => {
        switch(listingStatus) {
          case 'featured':
              return <Marker position={position} icon={featuredMarkerIcon}/> 
          case 'verified':
              return <Marker position={position} icon={verifiedMarkerIcon}/>
          case 'unverified':
            return <Marker position={position} icon={unverifiedMarkerIcon}/>
          default:
              return <Marker position={position} icon={claimedMarkerIcon}/>
        }
      })()
  );
}

export default AssignMarkerIcon;
