import { NavLink, useNavigate } from "react-router-dom";

export default function ListingDetailNavigation(props) {
  const navigate = useNavigate();
  const { basePath, tabs, listing } = props;

  const buildSectionLink = (section) => {
    const link = `${basePath}/${listing?.id}/${section}`;
    return link;
  }
  
  const getCurrentTab = () => {
    const urlParts = window.location.href.split("?");
    const pathParts = urlParts[0].split("/");
    const lastPath = pathParts.slice(-1)[0];
    // const tab = tabs.find((tab) => pathParts.includes(tab.section));

    // name of the tabbed location is always at [6]
    const tab = tabs.find((tab) => pathParts[6] === tab.section);
    return tab;
  }

  const onSelectChange = (e) => {
    const lastPath = e.target.value;
    const tab = tabs.find((tab) => tab.section === lastPath)
    const link = buildSectionLink(tab.section);
    // console.log('parts', link);
    navigate(link);
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const hasAccess = (tab) => (
    tab.access === 'all' || (listing?.categories.some((cat) => tab.access.includes(cat.name)))
  );
  
  return (
    <div className="mt-4">
      <div className="md:hidden">
        <label htmlFor="current-tab" className="sr-only">
          Select a tab
        </label>
        <select
          id="current-tab"
          name="current-tab"
          onChange={onSelectChange}
          className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary"
          value={getCurrentTab().section}
        >
          {tabs.filter(hasAccess).map((tab) => (
            <option key={tab.name} value={tab.section}>
            {tab.name}
            {tab.notifications !== null && tab.notifications > 0 && (
              ` (${tab.notifications})`
            )}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden md:block">
        <nav className="-mb-px flex space-x-1 lg:space-x-6 xl:space-x-8">
          {tabs.filter(hasAccess).map((tab) => (
            <NavLink
              key={tab.name}
              to={buildSectionLink(tab.section)}>
              {({ isActive }) => (
                <div
                  className={classNames(
                    isActive
                      ? 'border-red-900 text-red-900'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium relative'
                  )}
                // aria-current={tab.current ? 'page' : undefined}
                >
                  {tab.name}
                  {tab.notifications !== null && tab.notifications > 0 && (
                    <>
                      <span className="sr-only">Notifications</span>
                      <div className="absolute inline-flex items-center justify-center w-5 h-5 ml-0.5 text-xs font-bold text-white bg-red-900 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">{tab.notifications}</div>
                    </>
                  )}

                </div>
              )}
            </NavLink>
          ))}
        </nav>
      </div>
    </div >);
}