import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Markdown Editor
import {
  UndoRedo,
  BoldItalicUnderlineToggles,
  CreateLink,
  toolbarPlugin,
  markdownShortcutPlugin,
  listsPlugin,
  ListsToggle,
  linkPlugin,
  linkDialogPlugin,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';

import client from '../../../lib/feathers';
import { setListing } from "../../../lib/listingsSlice";
import Page from '../../Page';
// import QRMessage from './QRMessage';
// import ClubPromo from './ClubPromo';
import PromotionsForm from './PromotionsForm';

export default function PromotionsPanel() {
  const listing = useSelector((s) => s.listings.selected);
  const user = useSelector((s) => s.auth.user);
  const dispatch = useDispatch();

  const handleUpdateListing = async (data) => {
    try {
      const service = client.service(`${user.role}/listing`);
      const updatedListing = { ...listing, ...data };
      await service.patch(listing.id, updatedListing);
      dispatch(setListing(updatedListing));
    } catch (err) {
      console.log('Error updating listing:', err);
    }
  };

  return (
    <Page>

      {/* QR WELCOME MESSAGE */}
      <div className="bg-white shadow p-5 sm:rounded-lg my-5">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="flex flex-col">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900 inline-block align-middle md:ml-4 mb-6">
                QR Code & Greeting
              </h3>
            </div>
            <p className="md:ml-4 md:max-w-[200px] text-sm text-gray-500 text-center md:text-left">
              Welcome tasters to your winery.
            </p>
            <div className="max-w-[230px] flex flex-col items-center self-center md:self-start md:block md:justify-start">
              <img src={listing.qrcode} />
              <div className="w-full flex align-middle justify-center">
                <a
                  className="bg-white py-2 px-3 border border-gray-300 md:max-w-[200px] rounded-md shadow-sm text-center text-xs font-medium text-primary hover:bg-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  href={listing.qrcode}
                  download={`${listing.name.replaceAll(' ', '_')}_qrcode.png`}
                >
                  <span className="mr-2"><FontAwesomeIcon icon={faDownload} /></span>
                  Download QR Code
                </a>
              </div>
            </div>
            <div className="max-w-[230px] pt-5 flex flex-col items-center self-center md:self-start md:block md:justify-start">
              <img src="/assets/deliverables/SCAN-VS-5x7.png" width={230} />
              <div className='text-center'>5 x 7</div>
              <div className="w-full flex align-middle justify-center text-center pt-2">
                <a
                  className="bg-white py-2 px-3 border border-gray-300 md:max-w-[200px] rounded-md shadow-sm text-center text-xs font-medium text-primary hover:bg-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  href="/assets/deliverables/SCAN-VS-5x7.png"
                  download="SCAN-VinoSeeker-5x7.png"
                >
                  <span className="mr-2"><FontAwesomeIcon icon={faDownload} /></span>
                  Download Table Top Promo
                </a>
              </div>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <PromotionsForm
              listing={listing}
              property="welcomeMessage"
              formTitle="Welcome Message"
              formSubtitle="This message will be displayed to users upon scanning your QR Code and navigating to your listing's page."
              _featureLevel="WELCOME_MESSAGE"
              plugins={[
                listsPlugin(),
                markdownShortcutPlugin(),
                toolbarPlugin({
                  toolbarContents: () => (
                    <>
                      {' '}
                      <UndoRedo />
                      <BoldItalicUnderlineToggles />
                    </>
                  )
                })
              ]}
              handleUpdate={handleUpdateListing}
            />
          </div>
        </div>
      </div>

      {/* WINE CLUB */}
      <div className="bg-white shadow p-5 sm:rounded-lg my-5">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="flex flex-col">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900 inline-block align-middle md:ml-4 mb-6">
                Wine Club Information
              </h3>
            </div>
            <br />
          </div>

          <div className="mt-5 md:mt-0 md:col-span-2">
            <PromotionsForm
              listing={listing}
              property="currentPromo"
              formTitle="Wine Club Promotion"
              formSubtitle="Write a message for your promotions."
              _featureLevel="WINE_CLUB_PROMOTION"
              plugins={[
                listsPlugin(),
                linkPlugin(),
                linkDialogPlugin(),
                markdownShortcutPlugin(),
                toolbarPlugin({
                  toolbarContents: () => (
                    <>
                      {' '}
                      <UndoRedo />
                      <BoldItalicUnderlineToggles />
                      <ListsToggle />
                      <CreateLink />
                    </>
                  )
                })
              ]}
              handleUpdate={handleUpdateListing}
            />
          </div>
        </div>
      </div>

    </Page>
  );
}
