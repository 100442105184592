import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  MapIcon,
  // QrCodeIcon,
  Square2StackIcon,
  MagnifyingGlassIcon,
  PhoneArrowUpRightIcon,
  ListBulletIcon,
  UsersIcon,
  EyeIcon,
  HeartIcon,
  GlobeAltIcon,
  UserCircleIcon,
  BookmarkIcon,
  ClockIcon,
} from '@heroicons/react/24/outline';
import { IconCard, SBCard } from './StatCard';
import client from '../../../lib/feathers';
import Page from '../../Page';

const statLegend = [
  { name: 'Views', key: 'load', icon: EyeIcon },
  { name: 'Searches', key: 'search', icon: MagnifyingGlassIcon },
  { name: 'Nearby Searches', key: 'nearby', icon: Square2StackIcon },
  { name: 'Check-Ins', key: 'checkin', icon: UsersIcon },
  { name: 'Sips', key: 'sip', icon: HeartIcon },
  { name: 'Lists Added To', key: 'list', icon: ListBulletIcon },
  { name: 'Directions', key: 'directions', icon: MapIcon },
  { name: 'Phone Calls', key: 'call', icon: PhoneArrowUpRightIcon },
  { name: 'Hours', key: 'hours', icon: ClockIcon },
  { name: 'Listing Saves', key: 'save', icon: BookmarkIcon },
  { name: 'Website Clicks', key: 'website', icon: GlobeAltIcon },
  { name: 'Social Media Clicks', key: 'social', icon: UserCircleIcon },
  // { name: 'QR Code Scans', key: 'qrcode', icon: QrCodeIcon },
];

export default function ReportsPanel() {
  const { lid } = useParams();
  const user = useSelector((s) => s.auth.user);

  const [stats, setStats] = useState([]);

  useEffect(() => {
    const loadStats = async () => {
      const service = client.service(`${user.role}/listing-basic-stats`);
      const response = await service.get(lid);
      const _stats = statLegend.map((stat) => {
        return {
          name: stat.name,
          icon: stat.icon,
          key: stat.key,
          currentStat: response.current.find((data) => data.action === stat.key)?.count || 0,
          previousStat: response.previous.find((data) => data.action === stat.key)?.count || 0,
          totalStat: response.totals.find((data) => data.action === stat.key)?.count || 0,
        }
      });
      setStats(_stats);
    }
    loadStats();
  }, [lid]);

  return (
    <Page>
      <div className="bg-white shadow overflow-hidden px-5 py-5 sm:rounded-lg sm:p-6">
        {/* Header */}
        <div className="sm:flex sm:items-center py-2">
          <div className="sm:flex-auto py-2">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Reports</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Analytics, overall impressions, and weekly trends for your listing.
            </p>
          </div>
        </div>
        {/* Analytics */}
        <div>
          <dl className="mt-5 mb-10 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white border-2 sm:grid-cols-2 lg:grid-cols-4 md:divide-x md:divide-y-0">
            {stats.filter((s) => ['sip', 'checkin', 'list', 'load'].includes(s.key)).map((item) => (
              <SBCard key={item.name} item={item} />
            ))}
          </dl>
        </div>
        <div>
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Trends
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Summary of user interaction with your listing in the past week, with trends from the week prior.
          </p>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {stats.map((item) => (
              <IconCard key={item.name} item={item} />
            ))}
          </dl>
        </div>
      </div>
    </Page>
  );
}
