import './styles/global.scss';
import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab, faTwitter } from '@fortawesome/free-brands-svg-icons';
import {
  Route,
  Routes,
} from 'react-router-dom';
import Login from './pages/login';
import Admin from './pages/admin';
import Industry from './pages/industry';


function App() {
  library.add(fab, faTwitter);

  return (

    <div className="App">
      <Routes>
        <Route path="/industry/*" element={<Industry />} />
        <Route path="/admin/*" element={<Admin />} />
        {/* Commented out to work on Industry, Comment in when finished <Route path="/*" element={<Login />} /> */}
        <Route path="/*" element={<Login />} />

      </Routes>
    </div>
  );
}
export default App;
